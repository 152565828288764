import { useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { TiChevronRight } from "react-icons/ti";
const FAQSection = ({ faqs }) => {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? index : index);
  };



  return (
    <section className="finantial_faq">
      <div className="container container__fnedu2">
        <div className="row finantial_faq__row">
          {/* Left Side - Heading */}
          <div className="col-lg-5">
            <div className="finantial_faq__heading">
              <p className="finantial_faq__tag">FAQs</p>
              <h2 className="finantial_faq__title">
                Frequently <br /> asked questions
                <svg width="230" height="22" viewBox="0 0 240 22" fill="none" class="reviews__title__line reviews__title__line--faq"><path class="wow" fill-rule="evenodd" clip-rule="evenodd" d="M0.440833 21.1152C78.9497 6.16225 158.05 4.53412 235.949 13.8239C237.497 14.0088 239.796 12.4065 239.988 9.93474C240.181 7.4176 238.026 5.44088 236.474 5.2332C157.99 -5.31675 79.1936 0.359501 0.316568 19.7785C-0.184784 19.9023 -0.0511379 21.2092 0.440833 21.1152Z" fill="#ffc062"></path></svg>
              </h2>
              <a href="#fnedu_heroSection" class="why-evergreen__button why-evergreen__button--faq">
                Get Instant Access
                <TiChevronRight className="why-evergreen__button_icone" />
              </a>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="finantial_faq__content">
              {faqs.map((faq, index) => (
                <div key={index} className="finantial_faq__item">
                  <div className="finantial_faq__question" onClick={() => toggleFAQ(index)}>
                    <span className="finantial_faq__question-holder">{faq.question}</span>
                    <span className="finantial_faq__icon">{openIndex === index ? <></> : <IoChevronDown className="faq__icons" />}</span>
                  </div>
                  {openIndex === index && faq.answer && (
                    <p className="finantial_faq__answer">{faq.answer}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
