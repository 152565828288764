import React from 'react'
import Header from '../../layouts/header'
import Footer from '../../layouts/footer'
import medal from "../../assets/images/medal.png"
import { Link } from 'react-router-dom'
import CarpFAQsSec from '../carpFaqs/CarpFAQsSec'

const Crpfaqs = () => {
  return (
    <div>
      <Header />
      <div className='space_for_website_top'></div>
      <section className="carpHero">
        {/* <div className='carpHero__design'></div> */}
        <div className="container container__fnedu2 container__fnedu2--crpfaqs">
          <img src={medal} alt='medal' className='carpHero__medal' />
          <h1 className='carpHero__heading'>Congratulations </h1>
          <h2 className='carpHero__sub-heading'>on joining the Credit Rebuilder Program!</h2>
          <p className='carpHero__desc'>
            Please bookmark this page for reference—it contains your next steps, answers to frequently asked questions, and details on accessing the 7 Steps to a 720 Credit Score credit education program.
          </p>
          <a href="#cardRow" className='carpHero__btn flex-a'>Next Steps</a>
          <div className="cardRow" id='cardRow'>
            <div className='carpHeroCard-holder'>
              <div className="count flex-a">1</div>
              <div className="carpHeroCard">
                <div className="carpHeroCard__inner">
                  <h1 className='carpHeroCard__heading'>Schedule Your Platinum Credit Review</h1>
                  <p className="carpHeroCard__desc">
                    During your Platinum Credit Review, a legal professional will analyze your credit report for errors and determine if any of your legal rights have been violated.
                  </p>
                  <Link to={"https://calendly.com/fcra/free-review-of-your-credit-report-credit-rebuilder-clients"} className='carpHeroCard__btn flex-a' target='_blank'>Schedule a Platinum Credit Review </Link>
                </div>
              </div>
            </div>
            <div className='carpHeroCard-holder'>
              <div className="count flex-a">2</div>
              <div className="carpHeroCard">
                <div className="carpHeroCard__inner">
                  <h1 className='carpHeroCard__heading'>Access Your Free Credit Education Course</h1>
                  <p className="carpHeroCard__desc">
                    Login to our portal to begin 7 Steps to a 720 Credit Score. Start by watching the Welcome video and Lesson #1.
                  </p>
                  <Link to={"https://jh49nhldih3tgwksyhzn.app.clientclub.net/login"} className='carpHeroCard__btn flex-a' target="_blank">Login</Link>
                </div>
              </div>
            </div>
          </div>
          <div className='instrBox__holder'>
            <div className='instrBox'>
              Your credit score will naturally improve as you make your monthly payments on time. However, completing the steps in the course can accelerate the process. You can move at your own pace, but the faster you complete the action items, the sooner you&apos;ll see results.
            </div>

          </div>
        </div>
      </section>
      <CarpFAQsSec />
      <Footer />
    </div>
  )
}

export default Crpfaqs