import { IoIosCloseCircleOutline, IoMdCheckmarkCircleOutline } from "react-icons/io";
import { TiChevronRight } from "react-icons/ti";
import { Link } from "react-router-dom";
const GoogleAddPopup = ({ view, setView }) => {
  return (
    <div className={`googleAddPopup ${view ? "d-flex" : "hidden"}`}>
      <div className="closrPopup__btn flex-a" onClick={() => setView(false)}>
        <IoIosCloseCircleOutline className="closrPopup__btn__icone" />
      </div>
      <IoMdCheckmarkCircleOutline className="googleAddPopup__icone" />
      <h1 className="googleAddPopup__heading">
        Congratulations on enrolling in the financial literacy class. Check your email for login details to start your free financial literacy class. If you don’t see the email, kindly check your junk or spam folder.
      </h1>
      <Link to="/login" className="why-evergreen__button why-evergreen__button--reviews">
        login now
        <TiChevronRight className="why-evergreen__button_icone" />
      </Link>
    </div>
  )
}

export default GoogleAddPopup