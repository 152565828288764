import React, { useEffect, useState } from "react";

import cross from "../../assets/images/SiteImg/cross.png";
import { ThreeDots } from "react-loader-spinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getAttCode, getCounty, getDistrict, getStates, UpdateUserInfor, userinfo, VerifyUser } from "../../api/server";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/userSlice";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DisclosureModal from "./DisclosureModal";

const CollectInfoModal = ({
    userData

}) => {
    const [noCode, setNoCode] = useState(false);
    const [userAttorney, setUserAttorney] = useState();
    const [readOnly, setReadOnly] = useState(false);
    const [atternyName, setAtternyName] = useState("");
    const [atternyEmail, setAtternyEmail] = useState("");
    const [atternyNumber, setAtternyNumber] = useState("");
    const [attCodeValidation, setattCodeValidation] = useState(false);
    // const userData = useSelector((state) => state.user);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseValue = queryParams.get("course");
    const retake = Boolean(
        queryParams.get("retake") !== null &&
        queryParams.get("retake") != "null" &&
        queryParams.get("retake") != "false" &&
        queryParams.get("retake") !== false
    );
    const [loading, setLoading] = useState(false)
    const [verifyCodeloading, setVerifyCodeloading] = useState(false)
    const dispatch = useDispatch();
    const [selectedCounty, setSelectedCounty] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [countiesData, setCountiesData] = useState([]);
    const [districtsData, setDistrictsData] = useState([]);
    const [statesData, setStatesData] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [showDisclosureModal, setShowDisclosureModal] = useState(false);
    const [showAttymPass, setShowAttymPass] = useState(false);
    const [showJointFiler, setShowJointFiler] = useState(false);
    console.log("userDatauserData", userData, selectedState, selectedCounty, userData
    )
    console.log("userDatauserDataasd",courseValue,courseValue == "first" ,userData?.judicialdistrict_state_second
    )
    const handleCheckboxChange = (e) => {
        setShowJointFiler(e.target.checked);
      };

      
      
    const initialValues = {
        agreeToBankruptcy: false,
        ssnLastFour: userData?.ssn || "",
        attorneyEmail: userData?.attyemail || "",
        attorneyName: userData?.attorneyname || "",
        attorneyPhone: userData?.attyphone || "",
        filingState: courseValue == "first" ?  userData?.judicialdistrict_state_first :
                                               userData?.judicialdistrict_state_second ,
        filingCounty: courseValue == "first" ? userData?.judicialdistrict_county_first : 
                                               userData?.judicialdistrict_county_second,
        filingDistrict: courseValue == "first" ? userData?.judicialdistrict_first :
                                                 userData?.judicialdistrict_second,
        phone: userData?.phone || "",
        firstname: userData?.firstname || "",
        middleinitial: userData?.middleinitial || "",
        lastname: userData?.lastname || "",
        address1: userData?.addr1 || "",
        address2: userData?.addr2 || "",
        city: userData?.city || "",
        state: userData?.state || "",
        zip: userData?.zip || "",
        attycode:userData?.couponcode_first,
        isJointFiler: userData?.joint_firstname ? true :false,
        jointFiler_firstName: userData?.joint_firstname,
      jointFiler_middleInitial: userData?.joint_middleinitial,
      jointFiler_lastName: userData?.joint_lastname,
      jointFiler_ssn: userData?.joint_ssn,
      joint_dlnum: userData?.joint_dlnum,
      jointFiler_username: userData?.joint_username,
      jointFiler_password: userData?.joint_password,
    };
    useEffect(() => {
        if (userData) {
            setAtternyEmail(userData?.attyemail)
            setAtternyNumber(userData?.attyphone)
            setAtternyName(userData?.attorneyname)
            setSelectedCounty(courseValue == "first" ? userData?.judicialdistrict_county_first : 
                userData?.judicialdistrict_county_second)
            setSelectedState(courseValue == "first" ?  userData?.judicialdistrict_state_first :
                userData?.judicialdistrict_state_second)
            setSelectedDistrict(courseValue == "first" ? userData?.judicialdistrict_first :
                userData?.judicialdistrict_second)
            if(userData?.couponcode_first){
                setUserAttorney(userData?.couponcode_first)
                setReadOnly(true)
            }
        }
        if(userData?.joint_username){
            setShowJointFiler(true)
        }
    }, [userData])
    const ValidationSchema = Yup.object().shape({
        ssnLastFour: Yup.string().required("Last Four Digits of SSN is required").matches(/^\d{4}$/, "SSN must be exactly 4 digits"),
        attorneyEmail: Yup.string().email("Invalid email"),
        attorneyName: Yup.string(),
        attorneyPhone: Yup.string(),
        filingState: Yup.string().required("State is required"),
        filingCounty: Yup.string().required("Country is required"),
        filingDistrict: Yup.string().required("District is required"),
        phone: Yup.string().required("Phone number is required"),
        firstname: Yup.string().required("First Name is required"),
        middleinitial: Yup.string(),
        lastname: Yup.string().required("Last Name is required"),
        address1: Yup.string().required("Address Line 1 is required"),
        address2: Yup.string(),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        zip: Yup.number().required("Zip Code is required"),
        jointFiler_firstName: showJointFiler
        ? Yup.string().required("First name is required")
        : Yup.string(),
      jointFiler_lastName: showJointFiler
        ? Yup.string().required("Last name is required")
        : Yup.string(),
      jointFiler_ssn: showJointFiler
        ? Yup.string().required("SSN is required").matches(/^\d{4}$/, "SSN must be exactly 4 digits")
        : Yup.string(),
      joint_dlnum: showJointFiler
        ? Yup.string().required("Phone Number is required")
        : Yup.string(),
         jointFiler_username: showJointFiler
              ? Yup.string().required("Username is required")
              : Yup.string(),
            jointFiler_password: showJointFiler
              ? Yup.string()
                  .required("Password is required")
                  .min(8, "Password must be at least 8 characters")
                  .matches(
                    /[A-Z]/,
                    "Password must contain at least one uppercase character"
                  )
              : Yup.string()
                  .min(8, "Password must be at least 8 characters")
                  .matches(
                    /[A-Z]/,
                    "Password must contain at least one uppercase character"
                  ),
    });

    console.log("userDatauserData", userData)
    function valuesSetter(data) {
        setAtternyEmail(data.email);
        setAtternyName(data?.firstname);
        setAtternyNumber(data?.phone);
    }

    const handleVerifyAttorney = async (e) => {
        setattCodeValidation(false);
        // e.preventDefault();
        const body = {
            code: userAttorney ? userAttorney : userData?.couponcode_first,
        };
        try {
            setVerifyCodeloading(true);
            const response = await VerifyUser(body);
            if (response.status === 200) {
                setReadOnly(true);
                const data = response.data;
                valuesSetter(response?.data?.data);
                toast.success("Attorney information verified successfully!");
                setVerifyCodeloading(false);
            } else {
                setattCodeValidation(true);
            }
        } catch (error) {
            setattCodeValidation(true);
            console.error("Error fetching states data:", error);
            setVerifyCodeloading(false);
        }
    };
    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        let payload = { ...values };
        //   if (readOnly) {
        //     payload.attycode = userAttorney;
        //   }
        if(!userData?.couponcode_first){
            payload.attycode = userAttorney
            payload.attorneyPhone = atternyNumber;
            payload.attorneyEmail = atternyEmail;
            payload.attorneyName = atternyName;
        }
        else{
            payload.attycode = ""
            payload.attorneyPhone = "";
            payload.attorneyEmail = "";
            payload.attorneyName = "";
        }
        if(courseValue === "first"){
            payload.course = "first_course"
        }
        if(courseValue === "second"){
            payload.course = "second_course"
        }
        console.log("Form Submitted:", payload,values);
        await UpdateUserInfor(payload, userData.id).then((res) => {
            console.log("kajsfkjsadkjsad", res)
            if (res?.status == "200") {
                dispatch(login(res.data));
                toast.success("submitted successfully");
                // closeModal()
                // setShowModal(false)
                setLoading(false);
                if(courseValue == "first"){
                    navigate(`/contactwithcounselor?completed=${courseValue}&retake=${retake}`)
                }
                else{
                    navigate(`/congratulationmessage`)   
                }
                
            }

        })
            .catch((err) => {
                toast.success("There is some error");

                setLoading(false);
            });

    };

    useEffect(() => {
        const fetchStatesData = async () => {
            try {
                const response = await getStates();
                const data = response.data;
                setStatesData(data);
            } catch (error) {
                console.error("Error fetching states data:", error);
            }
        };
        fetchStatesData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setCountiesData([]);
            try {
                if (selectedState) {
                    /////////////////////// get data of county////////////////
                    const response = await getCounty({ state: selectedState });
                    const countiesData = response.data;
                    setCountiesData(countiesData);
                }
            } catch (error) { }
        };

        fetchData();
    }, [selectedState]);

    useEffect(() => {
        const fetchData = async () => {
            setDistrictsData([]);
            try {
                if (selectedCounty) {
                    /////////////////////// get data of District////////////////
                    const districtResponse = await getDistrict({
                        state: selectedState,
                        county: selectedCounty,
                    });
                    const distData = districtResponse.data;

                    setDistrictsData(distData);
                }
            } catch (error) { }
        };

        fetchData();
    }, [selectedCounty]);


    const handleClose = () => {
        console.log("testing")
        // setShowModal(false)
      }
    
    return (
        <div >
            {/* <Modal size="xl" show={showModal} onHide={() => setShowModal(false)} className="collect-info-modal-container"> */}
                {/* <Modal.Header closeButton>
                    
                </Modal.Header> */}
                 {/* <div className="d-flex justify-content-end p-2">
        <button className="btn-close" onClick={handleClose}></button>
    </div> */}
                {/* <Modal.Body > */}
                {/* <h1 className="" style={{ fontSize: '17px', padding: '0px 4px 20px 4px',  }}>
                        Please confirm your attorney's contact details so that we can send your certificate to your attorney.
                    </h1> */}
                    <div className="modal_content btn_wrap " >
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={ValidationSchema}
                            enableReinitialize={true}
                        >
                            {({ values, setValues,errors }) => (
                                <Form className="form-container" style={{ width: '96%', margin: 'auto' }}>
                                    <div className="row">
                                        {/* Checkbox */}
                                        {
                                            console.log("errorserrors",values)
                                        }
                                        <div className="head ">
                                            <h1 className="f18">Your Profile</h1>
                                        </div>
                                        <div className="row mt-40">

                                            <div className="col-lg-6 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">First Name</label>
                                                    {
                                                        console.log("userData?.firstname", userData?.firstname, !!userData?.firstname)
                                                    }
                                                    <Field
                                                        type="text"
                                                        name="firstname"
                                                        className="form-control"
                                                        placeholder="Enter first name"

                                                    // defaultValue={userData?.firstname || ""}
                                                    // readOnly={!!userData?.firstname} 
                                                    />
                                                    <ErrorMessage name="firstname" component="div" className="error text-danger" />

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">Middle Initial</label>
                                                    <Field
                                                        type="text"
                                                        name="middleinitial"
                                                        className="form-control"
                                                        placeholder="Enter middle initial"
                                                    // defaultValue={userData?.middleinitial || ""}
                                                    // readOnly={!!userData?.middleinitial} 
                                                    />
                                                    <ErrorMessage name="middleinitial" component="div" className="error text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">Last Name</label>
                                                    <Field
                                                        type="text"
                                                        name="lastname"
                                                        className="form-control"
                                                        placeholder="Enter last name"

                                                    // readOnly={!!userData?.lastname} 
                                                    />
                                                    <ErrorMessage name="lastname" component="div" className="error text-danger" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">Phone Number</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="phone"
                                                        placeholder="Enter your phone no."
                                                    //  defaultValue={userData?.phone || ""}
                                                    />
                                                    <ErrorMessage
                                                        name="phone"
                                                        component="div"
                                                        className="error-message text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="input_wrap" style={{ marginBottom: '10px' }}>
                                                <label className="label-title">Last Four Digits of SSN</label>
                                                <Field
                                                    type="text"
                                                    name="ssnLastFour"
                                                    className="form-control"
                                                    placeholder="Enter last four digits of SSN"
                                                />
                                                <ErrorMessage
                                                    name="ssnLastFour"
                                                    component="div"
                                                    className="error text-danger"
                                                />

                                            </div>
                                        </div>
                                        <p style={{  }}>
                                            {/* The Bankruptcy Code requires a certificate to verify course completion, which includes identifying information like your Social Security Number (SSN). We use your SSN to ensure accurate reporting and compliance with these legal requirements. Rest assured, your information is handled securely and used only for this purpose. */}

                                            Per the Bankruptcy Code, your certificate must include identifying details like the last four digits of your Social Security Number. We use your SSN to make sure your records are accurate and meet legal requirements. Your information is kept secure and used only for this purpose.
                                        </p>
                                        <div className="head mt-40">
                                            <h1 className="f18">Your Contact Info</h1>
                                        </div>
                                        <div className="row mt-40">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">Address Line 1</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="address1"
                                                        placeholder="Enter your address"
                                                    />
                                                    <ErrorMessage
                                                        name="address1"
                                                        component="div"
                                                        className="error-message text-danger"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">Address Line 2</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="address2"
                                                        placeholder="Enter your address"
                                                    />
                                                    <ErrorMessage
                                                        name="address2"
                                                        component="div"
                                                        className="error-message text-danger"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">City</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="city"
                                                        placeholder="Enter your city"
                                                    />
                                                    <ErrorMessage
                                                        name="city"
                                                        component="div"
                                                        className="error-message text-danger"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">State</label>
                                                    <Field name="state">
                                                        {({ field }) => (
                                                            <select
                                                                {...field}
                                                                onChange={(e) => {
                                                                    setValues({
                                                                        ...values,
                                                                        state: e.target.value,
                                                                    });
                                                                }}
                                                                className="form-control"
                                                            >
                                                                {statesData.length > 0 && (
                                                                    <option value="">Select a State</option>
                                                                )}

                                                                {statesData.length > 0 ? (
                                                                    statesData.map((state, index) => (
                                                                        <option key={index} value={state.state}>
                                                                            {state.state}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">Loading...</option>
                                                                )}
                                                            </select>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage
                                                        name="state"
                                                        component="div"
                                                        className="error text-danger"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input_wrap">
                                                    <label className="label-title">Zip Code</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="zip"
                                                        placeholder="Enter zip code"
                                                    />
                                                    <ErrorMessage
                                                        name="zip"
                                                        component="div"
                                                        className="error-message text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !userData?.couponcode_first &&
                                            <>
                                            <div className="head mt-40">
                                            <h1 className="f18">
                                                Your Attorney's Info{" "}
                                                {/* <span style={{ fontSize: "16px", fontWeight: "500" }}>
                                                    (If you don't have one, leave blank)
                                                </span> */}
                                            </h1>
                                        </div>
                                      
                                        <div className={`col-lg-12 col-md-12 ${values.agreeToBankruptcy && "mb-4"}`}>
                                        
                                            <div className="input_wrap checkbox-wrap" style={{ marginBottom: '0px', marginTop: '30px' }}>
                                            <h1 className="" style={{ fontSize: '17px', padding: '0px 4px 20px 4px',  }}>
                        Please confirm your attorney's contact details so that we can send your certificate to your attorney.
                    </h1>
                                                <label className="checkbox-label"
                                                    style={{ display: 'flex', alignItems: 'baseline', gap: '10px' }}
                                                >
                                                    <Field
                                                        type="checkbox"
                                                        name="agreeToBankruptcy"
                                                        className="checkbox-input"
                                                        onChange={(e) => {
                                                          
                                                            const isChecked = e.target.checked;
                                                            setValues({
                                                                ...values,
                                                                agreeToBankruptcy: isChecked,
                                                                ...(isChecked && {
                                                                    attorneyEmail: "",
                                                                    attorneyName: "",
                                                                    attorneyPhone: "",
                                                                  

                                                                }),
                                                              
                                                            });
                                                            if(isChecked){
                                                                setAtternyEmail("")
                                                                setAtternyName("")
                                                                setAtternyNumber("")
                                                                setUserAttorney("")
                                                            }
                                                        }}
                                                    />
                                                   
                                                    <p>
                                                       
                                                    “ I do not have an attorney, but I have decided that bankruptcy might be a good option for me. Please connect me with an attorney in my state. I understand that my information will be shared with a third party for this purpose and have read the 
                                                        <span
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation();
                                                                setShowDisclosureModal(true)
                                                            }}
                                                            style={{
                                                                color: '#0d6efd',
                                                                cursor: 'pointer',
                                                                textDecoration: 'underline',
                                                                paddingLeft:'5px'
                                                            }}
                                                        >
                                                          Privacy & Disclosure Policy 
                                                        </span>
                                                        <span style={{paddingLeft:'5px'}}>“</span> 
                                                    </p>

                                                </label>
                                            </div>
                                        </div>
{
    !values.agreeToBankruptcy &&
    <fieldset
                                            disabled={values.agreeToBankruptcy}
                                            className="fieldset-disabled col-lg-12"
                                        >
                                            <div className="row">

                                                <div className="row mt-40">
                                                    {!noCode && (
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input_wrap">
                                                                <label className="label-title">
                                                                    Enter Attorney Code
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={userAttorney}
                                                                    onChange={(e) => {
                                                                        setUserAttorney(e?.target?.value);
                                                                        setattCodeValidation(false);
                                                                    }}
                                                                    name="attycode"
                                                                    placeholder="Enter Attorney code"
                                                                    readOnly={readOnly}
                                                                />
                                                                {attCodeValidation && (
                                                                    <p className="text-danger">
                                                                        Invalid Attorney Code{" "}
                                                                    </p>
                                                                )}
                                                                
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="col-lg-6 col-md-6">
                                                        {!noCode && (
                                                            <button
                                                                className="btn_brnd-outline mr-22"
                                                                disabled={verifyCodeloading || readOnly}
                                                                type="button"
                                                                onClick={handleVerifyAttorney}
                                                            >
                                                                {verifyCodeloading ? (
                                                                    <ThreeDots
                                                                        height="20"
                                                                        width="52"
                                                                        radius="9"
                                                                        color="#ed1717"
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{
                                                                            display: "block",
                                                                        }}
                                                                        wrapperClassName="spinner"
                                                                        visible={true}
                                                                    />
                                                                ) : (
                                                                    <>{readOnly ?"Verified" :"Verify"}</>
                                                                    
                                                                )}
                                                            </button>
                                                        )}
                                                        {/* <button
                                                            className="btn_brnd my-3"
                                                            type="button"
                                                            onClick={() => {
                                                                setNoCode(!noCode);
                                                                setReadOnly(false);
                                                                setUserAttorney("");
                                                                setAtternyName("");
                                                                setAtternyEmail("");
                                                                setAtternyNumber("");
                                                            }}
                                                        >
                                                            {noCode ? "Add Code" : " No Code ?"}
                                                        </button> */}
                                                    </div>
                                                </div>
                                                <div className="row mt-40">
                                                    <div className="col-lg-4 col-md-6">
                                                        <div className="input_wrap">
                                                            <label className="label-title">Attorney Email</label>
                                                            <Field
                                                                type="email"
                                                                className="form-control"
                                                                name="attorneyEmail"
                                                                placeholder="Attorney Email"
                                                                readOnly={!noCode}
                                                                onChange={(e) => {
                                                                    setAtternyEmail(e.target.value);
                                                                }}
                                                                value={atternyEmail}
                                                            />
                                                            <ErrorMessage
                                                                name="attorneyEmail"
                                                                component="div"
                                                                className="error-message text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <div className="input_wrap">
                                                            <label className="label-title">Attorney Name</label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="attorneyName"
                                                                placeholder="Attorney Name"
                                                                readOnly={!noCode}
                                                                onChange={(e) => {
                                                                    setAtternyName(e.target.value);
                                                                }}
                                                                value={atternyName}
                                                            />
                                                            <ErrorMessage
                                                                name="attorneyName"
                                                                component="div"
                                                                className="error-message text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <div className="input_wrap">
                                                            <label className="label-title">Attorney Phone</label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="attorneyPhone"
                                                                placeholder="Attorney Phone"
                                                                readOnly={!noCode}
                                                                onChange={(e) => {
                                                                    setAtternyNumber(e.target.value);
                                                                }}
                                                                value={atternyNumber}
                                                            />
                                                            <ErrorMessage
                                                                name="attorneyPhone"
                                                                component="div"
                                                                className="error-message text-danger"
                                                            />
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </fieldset>
}
                                        
                                            </>
                                        }
                                        <div className="col-12" style={{ userSelect: "none" }}>
                                   <div className="iagree_radio">
                                     <input
                                       type="checkbox"
                                       name="isJointFiler"
                                       id="joint"
                                       onChange={handleCheckboxChange}
                                       className=""
                                       checked={showJointFiler}
                                     />
               
                                     {/* <label htmlFor="joint">Add a joint applicant</label> */}
                                     <label htmlFor="joint">Add a joint filer</label>
               
                                   </div>
                                 </div>
                                 {/* conditional rendering */}
                                 {showJointFiler && (
                                   <div>
                                     <div className="head mt-40">
                                       <h1 className="f18">Joint Applicant Info</h1>
                                     </div>
                                     <div className="row mt-40">
                                       <div className="col-lg-6 col-md-6">
                                         <div className="input_wrap">
                                           <label className="label-title">First Name</label>
                                           <Field
                                             type="text"
                                             className="form-control"
                                             name="jointFiler_firstName"
                                             placeholder="Enter your first name"
                                           />
                                           <ErrorMessage
                                             name="jointFiler_firstName"
                                             component="div"
                                             className="error-message text-danger"
                                           />
                                         </div>
                                       </div>
                                       <div className="col-lg-6 col-md-6">
                                         <div className="input_wrap">
                                           <label className="label-title">
                                             Middle Initial
                                           </label>
                                           <Field
                                             type="text"
                                             className="form-control"
                                             name="jointFiler_middleInitial"
                                             placeholder="Enter your middle name"
                                           />
                                           <ErrorMessage
                                             name="jointFiler_middleInitial"
                                             component="div"
                                             className="error-message text-danger"
                                           />
                                         </div>
                                       </div>
                                       <div className="col-lg-6 col-md-6">
                                         <div className="input_wrap">
                                           <label className="label-title">Last Name</label>
                                           <Field
                                             type="text"
                                             className="form-control"
                                             name="jointFiler_lastName"
                                             placeholder="Enter your last name"
                                           />
                                           <ErrorMessage
                                             name="jointFiler_lastName"
                                             component="div"
                                             className="error-message text-danger"
                                           />
                                         </div>
                                       </div>
                                       <div className="col-lg-6 col-md-6">
                                         <div className="input_wrap">
                                           <label className="label-title">
                                             Last Four Digits of SSN
                                           </label>
                                           <Field
                                             type="text"
                                             className="form-control"
                                             name="jointFiler_ssn"
                                             placeholder="Enter digit of SSN"
                                           />
                                           <ErrorMessage
                                             name="jointFiler_ssn"
                                             component="div"
                                             className="error-message text-danger"
                                           />
                                         </div>
                                       </div>
                                       <div className="col-lg-6 col-md-6">
                                         <div className="input_wrap">
                                           <label className="label-title">Phone Number</label>
                                           <Field
                                             type="text"
                                             className="form-control"
                                             name="joint_dlnum"
                                             placeholder="Enter your phone no."
                                           />
                                           <ErrorMessage
                                             name="joint_dlnum"
                                             component="div"
                                             className="error-message text-danger"
                                           />
                                         </div>
                                       </div>
                                       <div className="col-lg-6 col-md-6">
                                         <div className="input_wrap">
                                           <label className="label-title">
                                             Joint Filer's Username
                                           </label>
                                           <Field
                                             type="text"
                                             className="form-control"
                                             name="jointFiler_username"
                                             placeholder="Enter your username"
                                           />
                                           <ErrorMessage
                                             name="jointFiler_username"
                                             component="div"
                                             className="error-message text-danger"
                                           />
                                         </div>
                                       </div>
                                       <div className="col-lg-6 col-md-6">
                                         <div className="input_wrap">
                                           <label className="label-title">
                                             Joint Filer's Password
                                           </label>
                                           <Field
                                             type={showAttymPass ? "text" : "password"}
                                             className="form-control"
                                             name="jointFiler_password"
                                             placeholder="Enter your password"
                                           />
                                           {showAttymPass ? (
                                             <i
                                               className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                                               style={{ top: "20px", right: "10px" }}
                                               onClick={() => {
                                                 setShowAttymPass(false);
                                               }}
                                               aria-hidden="true"
                                             ></i>
                                           ) : (
                                             <i
                                               className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                                               style={{ top: "20px", right: "10px" }}
                                               onClick={() => {
                                                 setShowAttymPass(true);
                                               }}
                                               aria-hidden="true"
                                             ></i>
                                           )}
                                           <ErrorMessage
                                             name="jointFiler_password"
                                             component="div"
                                             className="error-message text-danger"
                                           />
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 )}
                                       
                                        <div className={`col-lg-4 col-md-6 ${!showJointFiler && "mt-5" }` }>
                                            <div className="input_wrap">
                                                <label className="label-title">Filing State</label>
                                                <Field name="filingState">
                                                    {({ field }) => (
                                                        <select
                                                            {...field}
                                                            value={selectedState}
                                                            onChange={(e) => {
                                                                setSelectedState(e.target.value);
                                                                setSelectedCounty("");
                                                                setValues({
                                                                    ...values,
                                                                    filingState: e.target.value,
                                                                });
                                                            }}
                                                            className="form-control"
                                                        >
                                                            {statesData.length > 0 && (
                                                                <option value="">Select a State</option>
                                                            )}

                                                            {statesData.length > 0 ? (
                                                                statesData.map((state, index) => (
                                                                    <option key={index} value={state.state}>
                                                                        {state.state}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <option value="">Loading...</option>
                                                            )}
                                                        </select>
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    name="filingState"
                                                    component="div"
                                                    className="error text-danger"
                                                />
                                            </div>
                                        </div>
                                        {selectedState && (
                                            <>
                                                <div className={`col-lg-4 col-md-6 ${!showJointFiler && "mt-5"} `}>
                                                    <div className="input_wrap">
                                                        <label className="label-title">Filing County</label>
                                                        <Field name="filingCounty">
                                                            {({ field }) => (
                                                                <select
                                                                    {...field}
                                                                    className="form-control"
                                                                    value={selectedCounty}
                                                                    onChange={(e) => {
                                                                        setSelectedCounty(e.target.value);
                                                                        setValues({
                                                                            ...values,
                                                                            filingCounty: e.target.value,
                                                                        });
                                                                    }}
                                                                >
                                                                    {countiesData.length > 0 && (
                                                                        <option value="">Select a county</option>
                                                                    )}

                                                                    {countiesData.length > 0 ? (
                                                                        countiesData.map((county, index) => (
                                                                            <option key={index} value={county.county}>
                                                                                {county.county}
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="">Loading...</option>
                                                                    )}
                                                                </select>
                                                            )}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="filingCounty"
                                                            component="div"
                                                            className="error text-danger"
                                                        />
                                                    </div>
                                                </div>

                                                {selectedCounty && (
                                                    <div className={`col-lg-4 col-md-6 ${!showJointFiler && "mt-5"} `}>
                                                        <div className="input_wrap">
                                                            <label className="label-title">
                                                                Filing District
                                                            </label>
                                                            <Field name="filingDistrict"


                                                            >
                                                                {({ field }) => (
                                                                    <select {...field} className="form-control"
                                                                        value={selectedDistrict}

                                                                        onChange={(e) => {
                                                                            setSelectedDistrict(e.target.value);
                                                                            setValues({
                                                                                ...values,
                                                                                filingDistrict: e.target.value,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {districtsData.length > 0 && (
                                                                            <option value="">
                                                                                Select a Judicial District
                                                                            </option>
                                                                        )}

                                                                        {districtsData.length > 0 ? (
                                                                            districtsData.map((district, index) => (
                                                                                <option
                                                                                    key={index}
                                                                                    value={district.subdistrict}
                                                                                >
                                                                                    {district.subdistrict ==
                                                                                        "District of Oregon - Portland"
                                                                                        ? "I live closer to Portland"
                                                                                        : district.subdistrict ==
                                                                                            "District of Oregon - Eugene"
                                                                                            ? "I live closer to Eugene"
                                                                                            : district.subdistrict}
                                                                                    {/* {district.subdistrict} */}
                                                                                </option>
                                                                            ))
                                                                        ) : (
                                                                            <option value="">Loading...</option>
                                                                        )}
                                                                    </select>
                                                                )}
                                                            </Field>
                                                            <ErrorMessage
                                                                name="filingDistrict"
                                                                component="div"
                                                                className="error text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <div style={{ textAlign: "center", marginTop: "15px" }}>
                                        <button disabled={loading} className="btn_brnd" type="submit">
                                            {loading ? (
                                                <ThreeDots
                                                    height="20"
                                                    width="60"
                                                    radius="9"
                                                    color="#4fa94d"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{ display: "block" }}
                                                    wrapperClassName="spinner"
                                                    visible={true}
                                                />
                                            ) : (
                                                "Complete my information"
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                {/* </Modal.Body>
                
            </Modal> */}
            {
                showDisclosureModal &&
                <DisclosureModal
                    showModal={showDisclosureModal}
                    setShowModal={setShowDisclosureModal}
                />
            }


        </div>
    );
};

export default CollectInfoModal;