"use client";

import { useState, useRef, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa"; // Using FaInfoCircle from react-icons/fa

export default function BankruptcyCard({ description, title, card }) {
    const [showDetails, setShowDetails] = useState(false);
    const detailsRef = useRef(null);

    // Close the details when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (detailsRef.current && !detailsRef.current.contains(event.target)) {
                setShowDetails(false);
            }
        }

        if (showDetails) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [showDetails]);

    // Function to get the correct content based on the card value
    const getCardContent = () => {
        if (card === "1") {
            return (
                <>
                    <h5 className="text-sm font-medium mb-2">This video breaks down:</h5>
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px", fontSize: "12px", marginBottom: "16px" }}>
                        <li style={{ listStyle: 'unset' }}>Which debts can be discharged in bankruptcy.</li>
                        <li style={{ listStyle: 'unset' }}>Which debts cannot (like child support, recent tax debt, and student loans).</li>
                        <li style={{ listStyle: 'unset' }}>How to navigate tax debt and payment plans in bankruptcy.</li>
                        <li style={{ listStyle: 'unset' }}>What happens to car loans and mortgages.</li>
                    </ul>
                </>
            );
        } else if (card === "2") {
            return (
                <>
                    <h6 className="text-sm font-medium mb-2">In this video, we discuss:</h6>
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px", fontSize: "12px", marginBottom: "16px" }}>
                        <li style={{listStyle:'unset'}}>Why lenders are more willing to finance a car during Chapter 7.</li>
                        <li style={{listStyle:'unset'}}>How bankruptcy can help you escape a bad car loan.</li>
                        <li style={{listStyle:'unset'}}>Whether you should keep your current car or surrender it.</li>
                        <li style={{listStyle:'unset'}}>The smartest way to get a reliable car without high-interest rates.</li>
                    </ul>
                </>
            );
        } else if (card === "3") {
            return (
                <>
                    <h6 className="text-sm font-medium mb-2">This video covers:</h6>
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px", fontSize: "12px", marginBottom: "16px" }}>
                        <li style={{listStyle:'unset'}}>How to request court approval for a car loan.</li>
                        <li style={{listStyle:'unset'}}>What types of vehicles are typically approved in Chapter 13.</li>
                        <li style={{listStyle:'unset'}}>Why working with the right lender matters.</li>
                        <li style={{listStyle:'unset'}}>What happens if you take out a loan without court approval.</li>
                    </ul>
                </>
            );
        }
        return null; // No content for other card values
    };

    return (
        <div
            className="card border-0 rounded-4 position-relative"
            style={{ height: "580px", overflow: "hidden" }}
        >
            {/* Video iframe */}
            <div className="position-relative">
                <iframe
                    width="100%"
                    height="250"
                    src="https://www.youtube.com/embed/9xwazD5SyVg"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>

                {/* Info Icon Button - Positioned Near Video */}
                <button
                    className="position-absolute border-0 d-flex align-items-center justify-content-center"
                    style={{
                        bottom: "7px",
                        right: "1px",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: "#4e9c4b", // Light green theme
                        color: "white",
                        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                        cursor: "pointer",
                        zIndex: "10",
                        border: "none",
                    }}
                    onClick={() => setShowDetails(!showDetails)}
                >
                    <FaInfoCircle size={20} />
                </button>
            </div>

            {/* Title */}
            <h3
                className="fw-medium text-secondary pt-3"
                style={{ fontSize: "1.15rem", fontWeight: "700", height: "90px" }}
            >
                {title}
            </h3>

            {/* Description */}
            <p className="text-muted small ">{description}</p>

            {/* Breakdown Section - Appears Over the Card */}
            {showDetails && (
                <div
                    ref={detailsRef}
                    className="position-absolute bg-white shadow-lg p-3 rounded"
                    style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: "400px",
                        zIndex: "20",
                        transition: "opacity 0.3s ease-in-out",
                        // border: "1px solid #8BC34A", 
                    }}
                >
                    {getCardContent()}

                    {/* Close Button */}
                    {/* <button
            className="btn_brnd"
            style={{
              backgroundColor: "#4e9c4b",
              color: "white",
            //   marginTop: "10px",
            }}
            onClick={() => setShowDetails(false)}
          >
            Close
          </button> */}
                </div>
            )}

            {/* Learn More Button */}
            <button className="btn_brnd mt-auto" style={{ width: "fit-content" }}>
                Learn More
            </button>
        </div>
    );
}


