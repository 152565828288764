import React, { useState } from "react";

import cross from "../../assets/images/SiteImg/cross.png";
import { ThreeDots } from "react-loader-spinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getAttCode } from "../../api/server";
import { toast } from "react-toastify";

const AttorneyInfoModal = ({
    showModal,
    setShowModal,
    setFieldValue,
    setUserAttorney,
    setAttorneyInfo,
    setAttorneyCodeVerified,
    attorneyInfo
}) => {

    const [loading, setLoading] = useState(false)
    return (
        <div
            id="AttorneyInfoModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            className={`modal ${showModal ? "show" : ""}  custom_modal `}
            style={{ display: showModal ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered" style={{ margin: 'auto', width: '550px' }}>
                <div className="modal-content pb-60">
                    <button
                        type="button"
                        className="btn_close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        <img src={cross} alt="cross" className="cross_btn" />
                    </button>

                    <div className="modal-body">

                        <div className="modal_content btn_wrap ">
                            <h1 className="f18 pb-3">Provide your attorney’s information, and we will search for their code</h1>

                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    email: attorneyInfo?.attorneyCode?.atty_email || "",
                                    phone: attorneyInfo?.data?.phone || "",
                                    name: attorneyInfo?.attorneyCode?.atty_name || "",
                                    attycode: attorneyInfo?.attorneyCode?.code || "",
                                }}
                                validationSchema={Yup.object({
                                    email: Yup.string()
                                        .email("Invalid email address")
                                        .required("Attorney Email is required"),
                                    phone: Yup.string()
                                        // .matches(/^\d+$/, "Phone number must contain only digits")
                                        // .min(10, "Phone number must be at least 10 digits")
                                        .required("Attorney Phone is required"),
                                    name: Yup.string().required("Attorney Name is required"),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    setLoading(true);
                                    getAttCode(values)
                                        .then((res) => {
                                            setLoading(false);

                                            if (res.data.message === "Success!") {

                                                toast.success("Attorney information verified successfully!");
                                                resetForm({ values: { ...values, attycode: res?.data?.attorneyCode?.code } });
                                                setFieldValue({ attycode: res?.data?.attorneyCode?.code })
                                                setUserAttorney(res?.data?.attorneyCode?.code)
                                                setAttorneyInfo(res?.data)
                                                setAttorneyCodeVerified(true)
                                                console.log("values>>>>>>>><<", values)
                                            }
                                        })
                                        .catch((error) => {


                                            setLoading(false);
                                            resetForm({ values: { ...values, attycode: "" } });

                                            toast.error(error?.response?.data?.error || "Inavalid Attorney Info");
                                            setUserAttorney("")
                                            setAttorneyInfo("")
                                            setAttorneyCodeVerified(false)
                                        });
                                    console.log("Form Submitted", values);
                                }}
                            >
                                {({ handleSubmit, setValues }) => (
                                    <Form onSubmit={handleSubmit} className="form-container" style={{ width: "100%" }} autoComplete="off">

                                        <div className="input_wrap" style={{ width: "100%", marginTop: "20px" }}>
                                            <label className="label-title">Attorney Email</label>
                                            <Field
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                placeholder="Attorney Email"
                                                style={{ height: "45px" }}
                                                autoComplete="off"
                                            />
                                            <ErrorMessage name="email" component="div" className="error-message text-danger" style={{ fontSize: "14px", position: "absolute" }} />
                                        </div>

                                        <div className="input_wrap" style={{ width: "100%", marginTop: "20px" }}>
                                            <label className="label-title">Attorney Name</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder="Attorney Name"
                                                style={{ height: "45px" }}
                                                autoComplete="off"
                                            />
                                            <ErrorMessage name="name" component="div" className="error-message text-danger" style={{ fontSize: "14px", position: "absolute" }} />
                                        </div>
                                        <div className="input_wrap" style={{ width: "100%", marginTop: "20px" }}>
                                            <label className="label-title">Attorney Phone</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="phone"
                                                placeholder="Attorney Phone"
                                                style={{ height: "45px" }}
                                                autoComplete="off"
                                            />
                                            <ErrorMessage name="phone" component="div" className="error-message text-danger" style={{ fontSize: "14px", position: "absolute" }} />
                                        </div>
                                        <div className="input_wrap" style={{ width: "100%", marginTop: "20px" }}>
                                            <label className="label-title">Attorney code</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="attycode"
                                                placeholder="Attorney Phone"
                                                style={{ height: "45px" }}
                                                autoComplete="off"
                                                readOnly="true"
                                            />
                                            {/* <ErrorMessage name="phone" component="div" className="error-message text-danger" style={{ fontSize: "14px", position: "absolute" }} /> */}
                                        </div>

                                        <button type="submit" className="signup-link mt-2 me-3 d-flex" style={{ fontSize: "18px", justifySelf: "center" }} disabled={loading}>
                                            {loading ? <ThreeDots height="20" width="60" radius="9" color="#4fa94d" /> : "Add Code"}
                                        </button>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttorneyInfoModal;