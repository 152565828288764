import React from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { courseData, courseUpdate } from "../../api/server";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import HowDidThisHappen from "./how-did-this-happen";

const Course = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const [getloading, setGetLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [courseInfo, setCourseInfo] = useState(null);
  const [checked, setChecked] = [];
  const [completedVideoRank, setCompletedVideoRank] = useState(7);
  const [courseComplete, setCourseComplete] = useState(18);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const isJoint = localStorage.getItem("isJoint");

  async function fetchCourseInfo() {
    try {
      const response = await courseData(
        userId ? "admin" : "user",
        userId ? userId : user.user.data.id,
        isJoint == "true" ? "true" : "false",
        retake ? 1 : 0
      );
      if (response.data.contentData.rank === 5) {
        navigate(
          `${
            userId
              ? `/how-did-this-happen-user?id=${userId}&retake=${retake}`
              : `/how-did-this-happen?retake=${retake}`
          }`
        );
      }
      setGetLoading(false);
      setUpdateLoading(false);
      setCourseInfo(response.data);
    } catch (error) {
      setUpdateLoading(false);
      setGetLoading(false);
    }
  }
  useEffect(() => {
    fetchCourseInfo();
  }, []);

  const handleComplete = async (rank) => {
    setUpdateLoading(true);
    const updatedCourseInfo = { video: `${rank}` };
    await courseUpdate(
      userId ? "admin" : "user",
      userId ? userId : user.user.data.id,
      updatedCourseInfo,
      isJoint == "true" ? "true" : "false",
      retake ? 1 : 0
    )
      .then((res) => {
        fetchCourseInfo();
        if (rank === 18) {
          if (userId) {
            navigate(`/editstudent?id=${userId}&retake=${retake}`);
          } else {
            navigate(`/creditcounseling?course=first&retake=${retake}`);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  return (
    <div>
      <Header />
      {getloading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <ThreeDots
            height="40"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            wrapperClassName="spinner"
            visible={true}
          />
        </div>
      ) : courseInfo?.contentData ? (
        <div className="theme_container mt-250 course_container">
          <div className="course-list-video">
            <div className="d-flex flex-column-reverse flex-lg-row row align-items-start">
              <div className="col-lg-5">
                <h4 className="f-26 d-none d-lg-block">
                  Pre-Filing Credit Counseling{" "}
                </h4>
                <ul className="video-list">
                  {Array.from({ length: 18 }).map((_, index) => (
                    <li
                      key={index}
                      style={{
                        padding: "10px 0px",
                        borderLeft:
                          index + 1 === courseInfo.contentData.rank
                            ? "5px solid #4E9C4B"
                            : "",
                        background:
                          index + 1 === courseInfo.contentData.rank
                            ? "#E0E0E066"
                            : "",
                        paddingLeft:
                          index + 1 === courseInfo.contentData.rank
                            ? "5px"
                            : "",
                      }}
                    >
                      {index + 1 < courseInfo.contentData.rank ? (
                        // Check if the video has been watched
                        <>
                          <span className="checkbox">
                            <i className="fa-solid fa-circle-check"></i>
                          </span>
                        </>
                      ) : (
                        <span className="play">
                          <i className="fa-regular fa-circle-play"></i>
                        </span>
                      )}
                      <div>
                        <p>
                          <span>Lesson {index + 1}:</span> of 18
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {updateLoading ? (
                <div className="col-lg-7 mb-5 mb-lg-0">
                  <ThreeDots
                    height="40"
                    width="80"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                </div>
              ) : (
                <>
                  {completedVideoRank === courseInfo.contentData.rank ? (
                    <div class="col-lg-7 mb-5 mb-lg-0">
                      <h4 class="f-26 d-lg-none">
                        Pre-Filing Credit Counseling{" "}
                      </h4>
                      <div class="box_text">
                        <p class="f-16">
                          We are ready to take a look at your budget analysis!
                          You can also reach this area by clicking “Budget
                          Analysis” from your profile at any time.
                        </p>
                      </div>
                      <div class="footer-form-btn">
                        <Link
                          to={
                            userId
                              ? `/addinfomultistepuser?id=${userId}&retake=${retake}`
                              : `/addinfomultistep?retake=${retake}`
                          }
                          class="btn_brnd mt-4 me-3 btn-next"
                        >
                          Budget Analysis{" "}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-7 mb-5 mb-lg-0">
                      <h4 class="f-26 d-lg-none">
                        Pre-Filing Credit Counseling{" "}
                      </h4>
                      {courseInfo.contentData?.image ? (
                        //   <video
                        //   width="100%"
                        //   height="450"
                        //   src={
                        //     courseInfo.contentData.image
                        //       ? courseInfo.contentData?.image
                        //       : "https://youtu.be/9xwazD5SyVg"
                        //   }
                        //   autoPlay
                        // ></video>

                        <video
                          width="100%"
                          height="450"
                          src={courseInfo.contentData.image}
                          controlsList="nodownload noplaybackrate"
                          disablePictureInPicture
                          autoPlay
                          controls
                          onEnded={() => {
                            handleComplete(courseInfo.contentData.rank);
                          }}
                        ></video>
                      ) : (
                        <iframe
                          width="100%"
                          height="450"
                          src={"https://www.youtube.com/embed/9xwazD5SyVg"}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      )}
                      <div style={{overflowX:'auto'}}>
                      <td 
                        dangerouslySetInnerHTML={{
                          __html: courseInfo.contentData.text,
                        }}
                      />
                      </div>
                     
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <h4 className="f-26 text-center" style={{ marginTop: "200px" }}>
          Pre-Filing Credit Counseling Completed
        </h4>
      )}
      <Footer />
    </div>
  );
};

export default Course;
