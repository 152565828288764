import React, { useEffect } from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../../store/userSlice";
import { changePassword, getCounty, getDistrict, getStates, userinfo, userUpdate } from "../../api/server";
import { Link, useNavigate } from "react-router-dom";
import cross from "../../assets/images/SiteImg/cross.png";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const UserProfileUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showJointFiler, setShowJointFiler] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const userData = useSelector((state) => state.user);

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showOldPass, setShowAOldmPass] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountySecond, setSelectedCountySecond] = useState("");
  const [selectedStateSecond, setSelectedStateSecond] = useState("");
  const [countiesData, setCountiesData] = useState([]);
  const [countiesDataSecond, setCountiesDataSecond] = useState([]);

  const [districtsData, setDistrictsData] = useState([]);
  const [districtsDataSecond, setDistrictsDataSecond] = useState([]);
  const [updatedUserData, setUpdatedUserData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    userinfo(userData.user.data.id)
      .then((res) => {
        console.log("lkjaklsjf>>", res?.data?.data)
        setUpdatedUserData(res?.data?.data)
        setLoading(false)
        if(res?.data?.data?.judicialdistrict_state_second){
          setSelectedStateSecond(res?.data?.data?.judicialdistrict_state_second)
        }
        if(res?.data?.data?.judicialdistrict_state_first){
          setSelectedState(res?.data?.data?.judicialdistrict_state_first)
        }
        if(res?.data?.data?.judicialdistrict_county_first){
          setSelectedCounty(res?.data?.data?.judicialdistrict_county_first)
        }
        if(res?.data?.data?.judicialdistrict_county_second){
          setSelectedCountySecond(res?.data?.data?.judicialdistrict_county_second)
        }
      })
      .catch((err) => {
        setUpdatedUserData()
        setLoading(false)
      });
  }, []);



  useEffect(() => {
    if (updatedUserData?.joint_username) {
      setShowJointFiler(true)
    }
  }, [updatedUserData]);

  useEffect(() => {
    const fetchStatesData = async () => {
      try {
        const response = await getStates();
        const data = response.data;
        setStatesData(data);
      } catch (error) {
        console.error("Error fetching states data:", error);
      }
    };
    fetchStatesData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setCountiesData([]);
      try {
        if (selectedState) {
          /////////////////////// get data of county////////////////
          const response = await getCounty({ state: selectedState });
          const countiesData = response.data;
          setCountiesData(countiesData);
        }
      } catch (error) { }
    };

    fetchData();
  }, [selectedState]);

  useEffect(() => {
    const fetchData = async () => {
      setCountiesDataSecond([]);
      try {
        if (selectedStateSecond) {
          /////////////////////// get data of county////////////////
          const response = await getCounty({ state: selectedStateSecond });
          const countiesData = response.data;
          console.log("adfgadf", countiesData)
          setCountiesDataSecond(countiesData);
        }
      } catch (error) { }
    };

    fetchData();
  }, [selectedStateSecond]);

  useEffect(() => {
    const fetchData = async () => {
      setDistrictsData([]);
      try {
        if (selectedCounty) {
          /////////////////////// get data of District////////////////
          const districtResponse = await getDistrict({
            state: selectedState,
            county: selectedCounty,
          });
          const distData = districtResponse.data;

          setDistrictsData(distData);
        }
      } catch (error) { }
    };

    fetchData();
  }, [selectedCounty]);

  useEffect(() => {
    const fetchData = async () => {
      setDistrictsDataSecond([]);
      try {
        if (selectedCountySecond) {

          const districtResponse = await getDistrict({
            state: selectedStateSecond,
            county: selectedCountySecond,
          });
          const distData = districtResponse.data;

          setDistrictsDataSecond(distData);
        }
      } catch (error) { }
    };

    fetchData();
  }, [selectedCountySecond]);



  console.log("userDatauserData", userData)
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    ssn: Yup.string().required("Last four digits of SSN are required").matches(/^\d{4}$/, "SSN must be exactly 4 digits and only numbers are allowed"),
    phone: Yup.string()
      .typeError("Phone number must be a number")
      .required("Phone number is required"),
    addr1: Yup.string().required("Address Line 1 is required"),
    addr2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.number().required("Zip Code is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    // confirmEmail: Yup.string()
    //   .oneOf([Yup.ref("email"), null], "Emails must match")
    //   .required("Confirm Email is required"),
    username: Yup.string().required("Username is required"),
    // userpassword: Yup.string()
    //   .min(8, "Password must be at least 8 characters")
    //   .required("Password is required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("userpassword"), null], "Passwords must match")
    //   .required("Confirm Password is required"),
    joint_firstname: showJointFiler && Yup.string().required("First name is required"),
    joint_middleinitial: showJointFiler && Yup.string(),
    joint_lastname: showJointFiler && Yup.string().required("Last name is required"),
    joint_ssn: showJointFiler && Yup.string().required("Last four digits of SSN are required").matches(/^\d{4}$/, "SSN must be exactly 4 digits and only numbers are allowed"),
    // jointPhoneNumber: Yup.string().required("Phone is required"),
    // joint_username: Yup.string().required("Username is required"),
    judicialdistrict_state_first: updatedUserData?.activated_first == "checked" && !updatedUserData?.judicialdistrict_first && Yup.string().required("State is required"),
    judicialdistrict_county_first: updatedUserData?.activated_first == "checked" && !updatedUserData?.judicialdistrict_first && Yup.string().required("Country is required"),
    judicialdistrict_first: updatedUserData?.activated_first == "checked" && !updatedUserData?.judicialdistrict_first && Yup.string().required("District is required"),

    judicialdistrict_state_second: updatedUserData?.activated_second == "checked" && !updatedUserData?.judicialdistrict_second && Yup.string().required("State is required"),
    judicialdistrict_county_second: updatedUserData?.activated_second == "checked" && !updatedUserData?.judicialdistrict_second && Yup.string().required("Country is required"),
    judicialdistrict_second: updatedUserData?.activated_second == "checked" && !updatedUserData?.judicialdistrict_second && Yup.string().required("District is required"),
  });

  const modalValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .notOneOf(
        [Yup.ref("oldPassword")],
        "New password must be different from the old password"
      )
      .min(8, "New password must be at least 8 characters long")

      .matches(
        /^(?=.*[A-Z])/,
        "New password must contain at least one uppercase letter"
      ),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords do not match")
      .required("Confirm password is required"),
  });

  const handleCheckboxChange = (e) => {
    setShowJointFiler(e.target.checked);
  };

  const handleSubmit = async (values) => {
    delete values?.password
    // console.log("values>>><<<<",values)
    setPostLoading(true);
    try {
      const response = await userUpdate(values, userData.user.data.id);
      setPostLoading(false);
      toast.success("Profile updated successfully");
      dispatch(login(response.data));
      navigate("/userprofile");
    } catch (error) {
      setPostLoading(false);
      toast.error(error.response.data.error);
      console.error("Error updating user data:", error);
    }
  };

  const handleSubmit1 = async (values) => {
    setPostLoading(true);
    const payload = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    await changePassword(payload)
      .then((res) => {
        toast.success("Password updated successfully");
        setPostLoading(false);
        const modalBackdrop = document.querySelector(".modal-backdrop");
        if (modalBackdrop) {
          document.body.removeChild(modalBackdrop);
        }
        setPasswordModal(false);
      })
      .catch((err) => {
        setPostLoading(false);
        toast.error(err.response.data.error);
      });
  };
  {
    console.log("updatedUserData", updatedUserData, userData.user.data)
  }
  return (
    <div>
      <Header />
      {
        loading ?
          <section className=" mt-250">
            <div style={{ textAlign: "center" }}>
              <ThreeDots
                height="20"
                width="110"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "block",
                }}
                wrapperClassName="spinner"
                visible={true}
              />

            </div>
          </section>
          :
          updatedUserData &&
          <Formik
            initialValues={updatedUserData} // Set the initial values from the fetched user data
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setValues, errors, isValid, dirty, handleSubmit }) => (
              <Form>
                {
                  console.log("errorserrors", errors)
                }
                <section className="breadcrumb-section mt-250">
                  <div className="theme_container">
                    <div className="user-data1 ">
                      <h1 className="f-26 f-700">Update Profile</h1>
                      {/* button for update Password */}
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#Serious"
                        className="btn_brnd "
                        onClick={() => {
                          setPasswordModal(true);
                        }}
                      >
                        Update Password
                      </button>
                    </div>

                    <div className="head">
                      <h1 className="f18">Main Filer Info</h1>
                    </div>
                    <div className="row mt-40">
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">First Name</label>
                          <Field
                            type="text"
                            name="firstname"
                            className="form-control"
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="firstname"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Middle Initial</label>
                          <Field
                            type="text"
                            name="middleinitial"
                            className="form-control"
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="middleinitial"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Last Name</label>
                          <Field
                            type="text"
                            name="lastname"
                            className="form-control"
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="lastname"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">
                            Last Four Digits of SSN
                          </label>
                          <Field
                            type="text"
                            name="ssn"
                            className="form-control"
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="ssn"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Phone Number</label>
                          <Field
                            type="text"
                            name="phone"
                            className="form-control"
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="phone"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Add other sections of the form as needed */}
                    <div className="head  mt-40">
                      <h1 className="f18">Contact Info</h1>
                    </div>
                    <div className="row mt-40">
                      <div className="col-lg-6 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Address Line 1</label>
                          <Field
                            type="text"
                            name="addr1"
                            className="form-control"
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="addr1"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Address Line 2</label>
                          <Field
                            type="text"
                            name="addr2"
                            className="form-control"
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="addr2"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">City</label>
                          <Field type="text" name="city" className="form-control" />
                          <ErrorMessage
                            className="error text-danger"
                            name="city"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">State</label>
                          <Field
                            type="text"
                            name="state"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Zip Code</label>
                          <Field type="text" name="zip" className="form-control" />
                          <ErrorMessage
                            className="error text-danger"
                            name="zip"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="head mt-40">
                      <h1 className="f18">Account Info</h1>
                    </div>
                    <div className="row mt-40">
                      <div className="col-lg-6 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Your Email</label>
                          <Field
                            type="text"
                            name="email"
                            className="form-control"
                            readOnly
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="email"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Choose a Username</label>
                          <Field
                            type="text"
                            name="username"
                            className="form-control"
                            readOnly
                          />
                          <ErrorMessage
                            className="error text-danger"
                            name="username"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="iagree_radio">
                        <input
                          type="checkbox"
                          name="isJointFiler"
                          id="joint"
                          onChange={handleCheckboxChange}
                          className=""
                          checked={showJointFiler}
                        />

                        <label htmlFor="joint">Add a joint filer</label>
                      </div>
                    </div>
                    {/* conditional rendering */}
                    {showJointFiler && (
                      <div>
                        <div className="head mt-40">
                          <h1 className="f18">Joint Filer Info</h1>
                        </div>
                        <div className="row mt-40">
                          <div className="col-lg-4 col-md-6">
                            <div className="input_wrap">
                              <label className="label-title">First Name</label>
                              <Field
                                type="text"
                                className="form-control"
                                name="joint_firstname"
                              />
                              <ErrorMessage
                                name="joint_firstname"
                                component="div"
                                className="error-message text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="input_wrap">
                              <label className="label-title">Middle Initial</label>
                              <Field
                                type="text"
                                className="form-control"
                                name="joint_middleinitial"
                              />
                              <ErrorMessage
                                name="joint_middleinitial"
                                component="div"
                                className="error-message text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="input_wrap">
                              <label className="label-title">Last Name</label>
                              <Field
                                type="text"
                                className="form-control"
                                name="joint_lastname"
                              />
                              <ErrorMessage
                                name="joint_lastname"
                                component="div"
                                className="error-message text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="input_wrap">
                              <label className="label-title">
                                Last Four Digits of SSN
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name="joint_ssn"
                              />
                              <ErrorMessage
                                name="joint_ssn"
                                component="div"
                                className="error-message text-danger"
                              />
                            </div>
                          </div>


                          {/* 
                      <div className="col-lg-6 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">
                            Joint Filer's Username
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="joint_username"
                            
                            readOnly
                          />
                          <ErrorMessage
                            name="joint_username"
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div> */}
                        </div>
                      </div>
                    )}
                    {
                      updatedUserData?.activated_first == "checked" && !updatedUserData?.judicialdistrict_first &&
                      <>
                        <div className="head  mt-5">
                          <h1 className="f18">For Course One</h1>
                        </div>
                        <div className="row mt-5" >

                          <div className="col-lg-4 col-md-6 ">
                            <div className="input_wrap">
                              <label className="label-title">Filing State</label>
                              <Field name="judicialdistrict_state_first">
                                {({ field }) => (
                                  <select
                                    {...field}
                                    value={selectedState}
                                    onChange={(e) => {
                                      setSelectedState(e.target.value);
                                      setSelectedCounty("");
                                      setValues({
                                        ...values,
                                        judicialdistrict_state_first: e.target.value,
                                      });
                                    }}
                                    className="form-control"
                                  >
                                    {statesData.length > 0 && (
                                      <option value="">Select a State</option>
                                    )}

                                    {statesData.length > 0 ? (
                                      statesData.map((state, index) => (
                                        <option key={index} value={state.state}>
                                          {state.state}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="">Loading...</option>
                                    )}
                                  </select>
                                )}
                              </Field>
                              <ErrorMessage
                                name="judicialdistrict_state_first"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>



                          {selectedState && (
                            <>
                              <div className="col-lg-4 col-md-6">
                                <div className="input_wrap">
                                  <label className="label-title">Filing County</label>
                                  <Field name="judicialdistrict_county_first">
                                    {({ field }) => (
                                      <select
                                        {...field}
                                        className="form-control"
                                        value={selectedCounty}
                                        onChange={(e) => {
                                          setSelectedCounty(e.target.value);
                                          setValues({
                                            ...values,
                                            judicialdistrict_county_first: e.target.value,
                                          });
                                        }}
                                      >
                                        {countiesData.length > 0 && (
                                          <option value="">Select a county</option>
                                        )}
                                        {/* {values.judicialdistrict_county_first === "" && (
                                    <option value="">Select a county</option>
                                  )} */}
                                        {countiesData.length > 0 ? (
                                          countiesData.map((county, index) => (
                                            <option key={index} value={county.county}>
                                              {county.county}
                                            </option>
                                          ))
                                        ) : (
                                          <option value="">Loading...</option>
                                        )}
                                      </select>
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name="judicialdistrict_county_first"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              {selectedCounty && (
                                <div className="col-lg-4 col-md-6">
                                  <div className="input_wrap">
                                    <label className="label-title">
                                      Filing District
                                    </label>
                                    <Field name="judicialdistrict_first">
                                      {({ field }) => (
                                        <select {...field} className="form-control">
                                          {districtsData.length > 0 && (
                                            <option value="">
                                              Select a Judicial District
                                            </option>
                                          )}
                                          {/* {values.judicialdistrict_first === "" && (
                                    <option value="">
                                      Select a Judicial District
                                    </option>
                                  )} */}
                                          {districtsData.length > 0 ? (
                                            districtsData.map((district, index) => (
                                              <option
                                                key={index}
                                                value={district.subdistrict}
                                              >
                                                {district.subdistrict ==
                                                  "District of Oregon - Portland"
                                                  ? "I live closer to Portland"
                                                  : district.subdistrict ==
                                                    "District of Oregon - Eugene"
                                                    ? "I live closer to Eugene"
                                                    : district.subdistrict}
                                                {/* {district.subdistrict} */}
                                              </option>
                                            ))
                                          ) : (
                                            <option value="">Loading...</option>
                                          )}
                                        </select>
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="judicialdistrict_first"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </>

                    }
                    {
                      updatedUserData?.activated_second == "checked" && !updatedUserData?.judicialdistrict_second &&


                      <>
                        <div className={`head ${values?.activated_first == "checked" ? "mt-3" : "mt-5"} `}>
                          <h1 className="f18">For Course Two</h1>
                        </div>
                        <div className={`row mt-5 `}>

                          <div className="col-lg-4 col-md-6">
                            <div className="input_wrap">
                              <label className="label-title">Filing State</label>
                              <Field name="judicialdistrict_state_second">
                                {({ field }) => (
                                  <select
                                    {...field}
                                    value={selectedStateSecond}
                                    onChange={(e) => {
                                      setSelectedStateSecond(e.target.value);
                                      setSelectedCountySecond("");
                                      setValues({
                                        ...values,
                                        judicialdistrict_state_second: e.target.value,
                                      });
                                    }}
                                    className="form-control"
                                  >
                                    {statesData.length > 0 && (
                                      <option value="">Select a State</option>
                                    )}

                                    {statesData.length > 0 ? (
                                      statesData.map((state, index) => (
                                        <option key={index} value={state.state}>
                                          {state.state}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="">Loading...</option>
                                    )}
                                  </select>
                                )}
                              </Field>
                              <ErrorMessage
                                name="judicialdistrict_state_second"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          {
                            console.log("countiesDataSecond", countiesDataSecond)

                          }
                          {selectedStateSecond && (
                            <>
                              <div className="col-lg-4 col-md-6">
                                <div className="input_wrap">
                                  <label className="label-title">Filing County</label>
                                  <Field name="judicialdistrict_county_second">
                                    {({ field }) => (
                                      <select
                                        {...field}
                                        className="form-control"
                                        value={selectedCountySecond}
                                        onChange={(e) => {
                                          setSelectedCountySecond(e.target.value);
                                          setValues({
                                            ...values,
                                            judicialdistrict_county_second: e.target.value,
                                          });
                                        }}
                                      >
                                        {countiesDataSecond.length > 0 && (
                                          <option value="">Select a county</option>
                                        )}
                                        {/* {values.judicialdistrict_county_first === "" && (
                                    <option value="">Select a county</option>
                                  )} */}
                                        {countiesDataSecond.length > 0 ? (
                                          countiesDataSecond.map((county, index) => (
                                            <option key={index} value={county.county}>
                                              {county.county}
                                            </option>
                                          ))
                                        ) : (
                                          <option value="">Loading...</option>
                                        )}
                                      </select>
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name="judicialdistrict_county_second"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              {selectedCountySecond && (
                                <div className="col-lg-4 col-md-6">
                                  <div className="input_wrap">
                                    <label className="label-title">
                                      Filing District
                                    </label>
                                    <Field name="judicialdistrict_second">
                                      {({ field }) => (
                                        <select {...field} className="form-control">
                                          {districtsDataSecond.length > 0 && (
                                            <option value="">
                                              Select a Judicial District
                                            </option>
                                          )}
                                          {/* {values.judicialdistrict_first === "" && (
                                    <option value="">
                                      Select a Judicial District
                                    </option>
                                  )} */}
                                          {districtsDataSecond.length > 0 ? (
                                            districtsDataSecond.map((district, index) => (
                                              <option
                                                key={index}
                                                value={district.subdistrict}
                                              >
                                                {district.subdistrict ==
                                                  "District of Oregon - Portland"
                                                  ? "I live closer to Portland"
                                                  : district.subdistrict ==
                                                    "District of Oregon - Eugene"
                                                    ? "I live closer to Eugene"
                                                    : district.subdistrict}
                                                {/* {district.subdistrict} */}
                                              </option>
                                            ))
                                          ) : (
                                            <option value="">Loading...</option>
                                          )}
                                        </select>
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="judicialdistrict_second"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </>

                    }

                    <div className="row mt-40">
                      <div className="col-12">
                        <div className="btn_wrap text-center">
                          <Link
                            className="btn_brnd-outline"
                            to={"/userprofile"}
                            style={{ marginRight: "50px" }}
                          >
                            Go Back
                          </Link>
                          <button
                            type="submit"
                            className="btn_brnd"
                            // onClick={() => handleSubmit(values)}
                            // disabled={postLoading}
                            disabled={postLoading}
                          >
                            {postLoading ? (
                              <ThreeDots
                                height="20"
                                width="60"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Form>
            )}
          </Formik>
      }

      <Footer />
      <div
        id="Serious"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        className={`modal ${passwordModal ? "show" : ""} fade custom_modal `}
        style={{ display: passwordModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content pb-60">
            <button
              type="button"
              className="btn_close"
              data-bs-dismiss="modal"
              onClick={() => {
                setPasswordModal(false);
              }}
            >
              <img src={cross} alt="cross" className="cross_btn" />
            </button>
            <div className="modal-body">
              <div className="modal_content btn_wrap text-center mt-40">
                {/* Formik Form inside the modal */}
                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={modalValidationSchema}
                  onSubmit={handleSubmit1}
                >
                  {({ isSubmitting, values }) => (
                    <Form>
                      {/* Your form fields */}
                      <div className="password-fields">
                        <div className="col-lg-4 col-md-6">
                          <div style={{ textAlign: "center" }}>
                            <h6
                              id="firstcourse"
                              style={{ width: "800px", marginBottom: "30px" }}
                            >
                              Change{" "}
                              <span className="text_success">Password</span>
                            </h6>
                          </div>
                          <div className="input_wrap">
                            <label className="label-title">Old Password</label>
                            <Field
                              type={showOldPass ? "text" : "password"}
                              name="oldPassword"
                              className="form-control"
                            />
                            {showOldPass ? (
                              <i
                                className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                                style={{ top: "20px", right: "10px" }}
                                onClick={() => {
                                  setShowAOldmPass(false);
                                }}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                                style={{ top: "20px", right: "10px" }}
                                onClick={() => {
                                  setShowAOldmPass(true);
                                }}
                                aria-hidden="true"
                              ></i>
                            )}
                            <ErrorMessage
                              className="error text-danger"
                              name="oldPassword"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="row mt-40">
                          <div className="col-lg-4 col-md-6">
                            <div className="input_wrap">
                              <label className="label-title">
                                New Password
                              </label>
                              <Field
                                type={showPass ? "text" : "password"}
                                name="newPassword"
                                className="form-control"
                              />
                              {showPass ? (
                                <i
                                  className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                                  style={{ top: "20px", right: "10px" }}
                                  onClick={() => {
                                    setShowPass(false);
                                  }}
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                                  style={{ top: "20px", right: "10px" }}
                                  onClick={() => {
                                    setShowPass(true);
                                  }}
                                  aria-hidden="true"
                                ></i>
                              )}
                              <ErrorMessage
                                name="newPassword"
                                className="error text-danger"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="input_wrap">
                              <label className="label-title">
                                Confirm Password
                              </label>
                              <Field
                                type={showConfirmPass ? "text" : "password"}
                                name="confirmPassword"
                                className="form-control"
                              />
                              {showConfirmPass ? (
                                <i
                                  className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                                  style={{ top: "20px", right: "10px" }}
                                  onClick={() => {
                                    setShowConfirmPass(false);
                                  }}
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                                  style={{ top: "20px", right: "10px" }}
                                  onClick={() => {
                                    setShowConfirmPass(true);
                                  }}
                                  aria-hidden="true"
                                ></i>
                              )}
                              <ErrorMessage
                                name="confirmPassword"
                                className="error text-danger"
                                component="div"
                              />
                            </div>
                          </div>
                          {/* Other fields */}

                          <div className="btn_wrap text-center mt-40">
                            <button
                              className="btn_brnd-outline mr-22 mb-2 "
                              data-bs-dismiss="modal"
                              type="button"
                              disabled={postLoading}
                              onClick={() => {
                                setPasswordModal(false);
                              }}
                            >
                              Go Back
                            </button>
                            {/* Use a button type submit inside the form */}
                            <button
                              type="submit"
                              // onClick={() => handleSubmit1(values)}
                              disabled={postLoading}
                              className="btn_brnd"
                            >
                              {postLoading ? (
                                <ThreeDots
                                  height="20"
                                  width="148"
                                  radius="9"
                                  color="#4fa94d"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{
                                    display: "block",
                                  }}
                                  wrapperClassName="spinner"
                                  visible={true}
                                />
                              ) : (
                                "Change Password"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ///////////////////////////////////// */}
    </div>
  );
};

export default UserProfileUpdate;
