
import logo from "../../assets/images/logo.png"
import SignupForm from "../financialeducation/SignupForm"
import whyevergreen from "../../assets/images/why-evergreen.avif"
import { FaChartLine, FaStar, FaWallet } from "react-icons/fa6";
import { BsCalculator } from "react-icons/bs";
import { FaPenAlt, FaTools } from "react-icons/fa";
import FAQSection from "../financialeducation/FAQSection";
import { TiChevronRight } from "react-icons/ti";
// import whyevergreenicone from "../../assets/images/whyevergreenicone.png"
import GoogleAddPopup from "../financialeducation/GoogleAddPopup";
import { useState } from "react";
import facebook from "../../assets/images/SiteImg/icon/facebook.svg";
import twitter from "../../assets/images/SiteImg/icon/twitter.svg";
import address from "../../assets/images/SiteImg/icon/address.svg";
import { Link } from "react-router-dom";
const faqs = [
  {
    question: "Why is this financial literacy course free?",
    answer:
      "Evergreen is a nonprofit and offers financial education seeks as part of its mission of empowering and uplifting individuals through quality financial counseling and education.",
  },
  { question: "What will I learn from this financial education course?", answer: "You’ll learn about all of your options for eliminating debt, and you’ll receive online financial planning worksheets, budget calculators, and information about the laws surrounding debt. You’ll also receive information about credit scoring and the impact of debt on your credit score. At the end of the course, you will have information about your next best steps for eliminating your debt." },
  { question: "“Is Evergreen approved to issue credit counseling and personal financial management certificates?”", answer: "Yes. This free course is approved by the Department of Justice’s U.S. Trustees Office in all jurisdictions except for North Carolina and Alabama. If you are already filing bankruptcy, or if you decide to file bankruptcy in the next six months, you will need a certificate proving that you have completed the credit counseling course, and the debtor education course. By taking this free first course, you will receive a certificates evidencing your completion of the credit counseling course, in compliance with the Bankruptcy Code." },
  { question: "Who is this financial education course for?", answer: "This financial literacy course is available to anyone who understands that the first step to building wealth is eliminating debt. If you are ready to stop living paycheck-to-paycheck and start saving, investing, and living, this course is for you." },
];
const Financialeducation = () => {
  const [view, setView] = useState(false);
  return (
    <>
      {/* <ToastContainer /> */}
      <section className="fnedu_heroSection" id="fnedu_heroSection">
        <div className="fnedu_heroSection__top">
          <div className="container container__fnedu fnedu_heroSection__position">
            <div className="row top_row">
              <div className="col-lg-7">
                <div className="fnedu_heroSection__contentHolder">
                  <img src={logo} alt="" className="fnedu_heroSection__logo" />
                  <h1 className="fnedu_heroSection__heading">
                    How Can I Get Out of Debt Quickly?
                  </h1>
                  <p className="fnedu_heroSection__subheading">
                    Take Our Free Financial Education Course and Learn How!
                  </p>
                  <ul className="fnedu_heroSectionLisy">
                    <p className="fnedu_heroSectionLisy__text">
                      Learn the answers to these questions:
                    </p>
                    <li className="fnedu_heroSectionLisy__text">What&apos;s the best way to pay off credit card debt?</li>
                    <li className="fnedu_heroSectionLisy__text">Should I consolidate my debts?</li>
                    <li className="fnedu_heroSectionLisy__text">How can I negotiate with creditors to lower my payments?</li>
                    <li className="fnedu_heroSectionLisy__text">What is a good credit score, and how can I rebuild my credit?</li>
                  </ul>
                </div>

              </div>
              <div className="col-lg-5">
                <SignupForm setView={setView} />
              </div>
            </div>
          </div>
          <div className="bottom_inside_divider"></div>
        </div>
        <div className="container container__fnedu ">
          <div className="row bottomRow_fna_hersec">
            <div className="col-12">
              <h2 className="financial-literacy__title">
                Financial Literacy <span className="financial-literacy__highlight">for Beginners</span>
              </h2>
            </div>
            <div className="col-lg-6">
              <p className="financial-literacy__description">
                Everyone deserves access to clear, reliable financial guidance. That’s why Evergreen Financial Counseling,
                a nonprofit credit-counseling approved by the Department of Justice, is proud to offer this course completely free.
                No strings attached—just valuable tools and resources to help you start your journey toward financial literacy and stability.
              </p>
            </div>

            <div className="col-lg-6">
              <p className="financial-literacy__resources-title">
                Plus, we will send you free resources about rebuilding your credit score
              </p>
              <ul className="financial-literacy__list">
                <li className="financial-literacy__list-item">“How long will it take to rebuild my credit?”</li>
                <li className="financial-literacy__list-item">“How can I buy a home?”</li>
                <li className="financial-literacy__list-item">“What credit cards will approve me?”</li>
              </ul>
            </div>
          </div>

          <div className="why-evergreen__button-container">
            <a href="#fnedu_heroSection" className="why-evergreen__button why-evergreen__button--financial-literacy">
              Claim My Free Course
              <TiChevronRight className="why-evergreen__button_icone" />
            </a>
          </div>
        </div>
      </section >
      <section className="why-evergreen">
        {/* <img src={whyevergreenicone} alt="" className="why-evergreen__icone" /> */}
        <div className="container container__fnedu">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-evergreen__contentHolder">
                <h2 className="why-evergreen__title">Why Use Evergreen Financial Literacy Class?</h2>
                <p className="why-evergreen__description">
                  Evergreen Financial Counseling is a nonprofit credit-counseling approved by the Department of Justice.
                </p>
                <p className="why-evergreen__description">
                  Our core values of honesty, integrity, excellence, and compassion drive everything we do. These values define
                  our culture, guide our actions, and fuel our commitment to delivering the highest level of service to our
                  valued clients. We believe that by embodying these core values, we can make a meaningful difference in the
                  lives of our clients and help them achieve their financial goals with confidence and integrity.
                </p>
              </div>
            </div>

            <div className="col-lg-6 why-evergreen__image-container">
              <img
                src={whyevergreen}
                alt="Smiling Professional"
                className="why-evergreen__image"
              />
            </div>
          </div>

          <div className="why-evergreen__courses">
            <div className="why-evergreen__course">
              <div className="evergreen__course_icons_holder">
                <FaChartLine />
              </div>
              <p className="why-evergreen__course-text">Credit scores explained</p>
            </div>

            <div className="why-evergreen__course">
              <div className="evergreen__course_icons_holder">
                <BsCalculator />
              </div>
              <p className="why-evergreen__course-text">Financial planning calculator</p>
            </div>

            <div className="why-evergreen__course">
              <div className="evergreen__course_icons_holder">
                <FaWallet />
              </div>
              <p className="why-evergreen__course-text">Budget planner worksheet</p>
            </div>

            <div className="why-evergreen__course">
              <div className="evergreen__course_icons_holder">
                <FaPenAlt />
              </div>
              <p className="why-evergreen__course-text">Financial literacy test</p>
            </div>

            <div className="why-evergreen__course">
              <div className="evergreen__course_icons_holder">
                <FaTools />
              </div>
              <p className="why-evergreen__course-text">Smart budgeting tools</p>
            </div>
          </div>

          <div className="why-evergreen__button-container">
            <a href="#fnedu_heroSection" className="why-evergreen__button">
              Get Instant Access
              <TiChevronRight className="why-evergreen__button_icone" />
            </a>
          </div>
        </div>
      </section>
      <section className="reviews">
        <div className="container container__fnedu2">
          <h6 className="reviews__subtitle">Client Reviews</h6>
          <h2 className="reviews__title">
            What Clients Say About Our <span className="reviews__highlight"> Financial Literacy </span> for Adults Class?

            <svg width="230" height="22" viewBox="0 0 240 22" fill="none" className="reviews__title__line reviews__title__line--review">
              <path className="wow" fill-rule="evenodd" clip-rule="evenodd" d="M0.440833 21.1152C78.9497 6.16225 158.05 4.53412 235.949 13.8239C237.497 14.0088 239.796 12.4065 239.988 9.93474C240.181 7.4176 238.026 5.44088 236.474 5.2332C157.99 -5.31675 79.1936 0.359501 0.316568 19.7785C-0.184784 19.9023 -0.0511379 21.2092 0.440833 21.1152Z" fill="#ffc062"></path>
            </svg>
          </h2>
          <p className="reviews__description">
            At the core of Evergreen&apos;s philosophy is this belief: A person&apos;s debt does not define who they are as a person. We believe that each person is priceless, regardless of how much money they have or owe. That&apos;s why we offer no-cost and non-judgmental programs for supporting you in your journey toward financial empowerment. But don&apos;t take it from us! Hear what some of our satisfied clients have to say about Evergreen Financial Consulting.
          </p>

          <div className="reviews__cards__wrapper">
            <div className=" reviews__card">
              <div className="card__rating">
                <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </div>
              <h4 className="card__name">S.R. Auburn</h4>
              <p className="card__location">Wa</p>
              <p className="card__text">Great presentation and options for those dealing with financial problems.</p>
            </div>
            <div className=" reviews__card">
              <div className="card__rating">
                <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </div>
              <h4 className="card__name">T.W. Caldwell</h4>
              <p className="card__location">Idaho</p>
              <p className="card__text">There were a bunch of interesting information from this course that really hit home thank you.</p>
            </div>
            <div className=" reviews__card">
              <div className="card__rating">
                <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </div>
              <h4 className="card__name">T.M. Lebanon</h4>
              <p className="card__location">Or</p>
              <p className="card__text">Very good course. We learned a lot about budgets, credit and keeping track of spending.</p>
            </div>
          </div>

          <div className="why-evergreen__button-container">
            <a href="#fnedu_heroSection" className="why-evergreen__button why-evergreen__button--reviews">
              Sign Up Here
              <TiChevronRight className="why-evergreen__button_icone" />
            </a>
          </div>
        </div>
      </section>

      <FAQSection faqs={faqs} />


      <section className="newsletter">
        <div className="container container__fnedu">
          <div className="flex newsletter__row">
            <h1 className="newsletter__heading">Sign Up for Our Financial Literacy Course Now!
            </h1>
            <a href="#fnedu_heroSection" className="why-evergreen__button why-evergreen__button--newletter">
              Claim My Free Course
              <TiChevronRight className="why-evergreen__button_icone" />
            </a>
          </div>
        </div>
      </section>
      <GoogleAddPopup setView={setView} view={view} />
      <div>
        {/* <!-- footer_Wrap --> */}
        <section className="footer_top">
          <div className="theme_container">
            <div className="footer_top_inner">
              <div className="row align-items-center">
                <div className="col-lg-9">
                  <h1 className="f-52">
                    Talk <span>With Us!</span>
                  </h1>
                  <p
                    className="f-18 f-500 mb-0"
                    style={{ textTransform: "none" }}
                  >
                    We're on your side for a better financial future.
                  </p>
                </div>
                <div className="col-lg-3 text-center justify-content-end d-flex">
                  <Link to={"/contactus"}>
                    {" "}
                    <button className="btn btn_brnd   ">Let's Talk</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer_Wrap">
          <div className="theme_container ">
            <div className="footer_inner">
              <div className="row">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <h1 className="f26 text-white link_after">Useful links</h1>
                    <ul className="footer-lit mt-20">
                      <li>
                        <Link
                          className="nav-link	 mr-80"
                          aria-current="page"
                          to={`/attorneys`}
                        >
                          Attorneys
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link className="nav-link mr-80" to={`/about`}>
                          About Us
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link className="nav-link mr-80 " to={`/faq`}>
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link mr-80" to={`/privacy-policy`}>
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link mr-80 "
                          to={`/terms-and-condition`}
                        >
                          Terms & Condition
                        </Link>
                      </li>
                    </ul>
                    <ul className="social_icon">
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=100086498252029"
                          target="_blank"
                        >
                          <img src={facebook} alt="facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/EvergreenFinCou"
                          target="_blank"
                        >
                          <img src={twitter} alt="twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6 footer_text">
                    <h1 className="f26 text-white">Contact Us</h1>
                    <p className="f-16 text-white mt-20">
                      <img src={address} alt="address" />
                      Evergreen Financial Counseling 7137 E. Rancho Vista Drive,
                      Suite B21 Scottsdale, AZ 85251
                    </p>
                    {/* <p className="text-white f-16">
                      <a href="tel:800-581-3513">
                        <img src={phone} alt="" />
                        800-581-3513*
                      </a>
                    </p>
                    <p
                      className="f-16 text-white"
                      style={{ textTransform: "lowercase" }}
                    >
                      <a
                        href="mailto:counselor@evergreenclass.com"
                        style={{ textTransform: "lowercase" }}
                      >
                        <img src={email} alt="" />
                        counselor@evergreenclass.com
                      </a>
                    </p>

                    <p className="f-16 text-white" style={{ marginBottom: '0', display: 'block' }}>
                      Questions about the Credit Rebuilder Program?&nbsp;
                      <a href="tel:800-720-6886" style={{ paddingLeft: '5px' }}>
                        <img src={phone} alt="Phone Icon" style={{ marginRight: '5px' }} />
                        <strong>Call 800-720-6886</strong>
                      </a>
                    </p> */}

                    <p className="f-16 text-white">

                    </p>

                  </div>
                  <div className="col-lg-4 col-md-6 mx-auto">
                    <h1 className="f26 text-white">Evergreen's Mission</h1>
                    <p className="f-16 mt-20 text-white text-left">
                      Evergreen financial counseling seeks to empower and uplift
                      individuals through quality financial counseling and
                      education.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider-line mt-12"></div>
            <p className="f16 text_dark footer-bottom mb-0">
              Rights - ©2023{" "}
              <span className="text-white">Evergreen Financial Counseling</span>.
              All rights reserved.{" "}
            </p>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Financialeducation