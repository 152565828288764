import React, { useEffect, useState } from "react";
import img1 from "../../assets/images/SiteImg/img1.png";
import img2 from "../../assets/images/SiteImg/img2.png";
import img3 from "../../assets/images/SiteImg/img3.png";
import whyEverGreen from "../../assets/images/SiteImg/whyevergreen.png";
import whyEverGreenAfter from "../../assets/images/SiteImg/whyevergreen-after.png";
import ceo1 from "../../assets/images/SiteImg/ceo1.png";
import ceo2 from "../../assets/images/SiteImg/ceo2.png";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import plusIcon from "../../assets/images/SiteImg/plus.png";
import minusIcon from "../../assets/images/SiteImg/white-minus-icon.png";
import Uvuru from "../../assets/images/Uvuru.png";
import Uvuru_Mobile from "../../assets/images/Uvuru-mobile.png";

const HomePage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.user.token);
  const [isExpanded1, setIsExpanded1] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(true);

  const toggleContent1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };
  useLayoutEffect(() => {
    if (token) {
      if (user.user.data.type === "Admin") {
        navigate("/admin-dashboard");
      } else if (user.user.data.type === "Atty") {
        navigate("/manage-clients");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  return (
    <div>
      {/* <!-- home_page --> */}
      <section className="home_page">
        <Header />
        <div className="banner-content">
          <h1 className="f-70">
            Your path <span style={{ textTransform: "lowercase" }}>to</span>{" "}
            financial recovery!
          </h1>
          <p
            className="f-18 text-white f-500 mt-12"
            style={{ textTransform: "none" }}
          >
            Credit Counseling, Debtor Education, and Credit Rebuilding.
          </p>
        </div>
      </section>

      {/* <!-- Start-Course --> */}
      <section className="Start-Course Course_wrap">
        <div className="theme_container ">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="custom_card" style={{ paddingBottom: "185px" }}>
                <div className="img_card_wrap">
                  {" "}
                  <img src={img1} alt="img1" className="img-fluid" />{" "}
                </div>
                <p className="fa-16 text-center pt-2 fw-600">Required</p>
                <div className="custom_card_body">
                  <Link to={user.token ? "/dashboard" : "/login"}>
                    <button className="btn btn_brnd w-100 text-center mt-12">
                      Begin First Course
                    </button>
                  </Link>
                  <h4 className="f-26 mt-35 text-center">
                    Pre-Bankruptcy Credit Counseling
                  </h4>
                  <p className="f-16 mt-12 text-center">
                    Take this class before you file.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="custom_card" style={{ paddingBottom: "185px" }}>
                <div className="img_card_wrap">
                  {" "}
                  <img src={img2} alt="img2" className="img-fluid" />{" "}
                </div>
                <p className="fa-16 text-center pt-2 fw-600">Required</p>
                <div className="custom_card_body">
                  <Link to={user.token ? "/dashboard" : "/login"}>
                    <button className="btn btn_brnd w-100 text-center mt-12">
                      Begin Second Course
                    </button>
                  </Link>
                  <h4 className="f-26 mt-35 text-center">
                    Post-Filing Debtor Education
                  </h4>
                  <p className="f-16 mt-12 text-center">
                    Take this class after you file.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="custom_card">
                <div className="img_card_wrap">
                  {" "}
                  <img src={img3} alt="img3" className="img-fluid" />{" "}
                </div>
                <p className="fa-16 text-center pt-2 fw-600">
                  Claim your gift!
                </p>
                <div className="custom_card_body">
                  <Link to="/720course">
                    <button className="btn btn_brnd w-100 text-center mt-12">
                      Begin Bonus Course
                    </button>
                  </Link>
                  <h4
                    className="f-26 mt-35 text-center"
                    style={{ textTransform: "none" }}
                  >
                    How to Rebuild your Credit After Bankruptcy
                  </h4>
                  <p className="f-16 mt-12 text-center">
                    As a gift for completing your two required courses through
                    Evergreen, we will enroll you free of charge into this BONUS
                    course, where you will learn how to rebuild your credit
                    score after a bankruptcy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Why_Evergreen">
        <div className="theme_container">
          <h1 className="f-52 text-center" style={{ textTransform: "none" }}>
            Free and <span>Compassionate Credit</span> Education
          </h1>
          <p
            className="f-16 text-center mt-20"
            style={{ textTransform: "none" }}
          >
            Are you worried about what bankruptcy will do to your credit score?
            Fill out the form below, and we will send you our quick start
            booklet,{" "}
            <i>
              The Three-Day Strategy for Rebuilding Your Credit Score After
              Bankruptcy.
            </i>
          </p>
          <div className="row mt-60">
            <div className="col-lg-7">
              <div>
                <iframe
                  className="custom-iframe"
                  src="https://api.leadconnectorhq.com/widget/form/isIns6e1VR4pFWtWwjUD"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  id="inline-isIns6e1VR4pFWtWwjUD"
                  data-layout="{'id':'INLINE'}"
                  data-trigger-type="alwaysShow"
                  data-trigger-value=""
                  data-activation-type="alwaysActivated"
                  data-activation-value=""
                  data-deactivation-type="neverDeactivate"
                  data-deactivation-value=""
                  data-form-name="Contact Us"
                  data-height="400"
                  data-layout-iframe-id="inline-isIns6e1VR4pFWtWwjUD"
                  data-form-id="isIns6e1VR4pFWtWwjUD"
                  title="Contact Us"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-5">
              <div style={{ marginLeft: "30px" }}>
                <p
                  className="f-18"
                  style={{ lineHeight: "1", textTransform: "none" }}
                >
                  {" "}
                  Plus, we will send you free resources that will address your
                  worries:
                </p>
                <ul className="mt-4" style={{ textAlign: "justify" }}>
                  <li style={{ listStyleType: "disc", listStyle: "inside" }}>
                    “How can I repay my debt?“
                  </li>
                  <li style={{ listStyleType: "disc", listStyle: "inside" }}>
                    “What will happen to my credit score?”
                  </li>
                  <li style={{ listStyleType: "disc", listStyle: "inside" }}>
                    “Are my wages going to be garnished?”
                  </li>
                  <li style={{ listStyleType: "disc", listStyle: "inside" }}>
                    “What will my family and friends think?”
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Why_Evergreen --> */}
      <section className="Why_Evergreen">
        <div className="theme_container">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="f-52 ">
                Why <span>Evergreen?</span>
              </h1>
              <p className="f-16 text-left">
                <b>
                  Evergreen Is a Leading Provider of Comprehensive Credit
                  Counseling Services
                </b>
              </p>
              <p className="f-16  text-left">
                {" "}
                Our core values of honesty, integrity, excellence, and
                compassion drive everything we do. These values define our
                culture, guide our actions, and fuel our commitment to
                delivering the highest level of service to our valued clients.
                We believe that by embodying these core values, we can make a
                meaningful difference in the lives of our clients and help them
                achieve their financial goals with confidence and integrity.{" "}
              </p>
            </div>
            <div className="col-lg-6 d-flex text-end">
              <div className="Why_Evergreen_img">
                {" "}
                <img
                  src={whyEverGreen}
                  className="img-fluid"
                  alt="Why_Evergreen"
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="Why_Evergreen_img_after">
          {" "}
          <img
            src={whyEverGreenAfter}
            className="img-fluid"
            alt="whyevergreen-after"
          />{" "}
        </div>
      </section>

      {/* <!-- Client_Reviews --> */}
      <section className="Client_Reviews">
        <div className="theme_container">
          <div className="heading_Wrap">
            <h1 className="f-52 text-center">
              Client <span> Reviews</span>
            </h1>
            <p className="f-16 text-center mt-35">
              At the core of Evergreen's philosophy is this belief: A person's
              debt does not define who they are as a person. We believe that
              each person is priceless, regardless of how much money they have
              or owe. That's why we offer low-cost and non-judgmental program
              for supporting you in your journey toward financial empowerment.
              But don't take it from us! Hear what some of our satisfied clients
              have to say about Evergreen Financial Consulting.
            </p>
          </div>
          <div className="row mt-60">
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo1} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">S.R. Auburn</h1>
                    <p className="f-18 f-500 mb-0">Wa</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    Great presentation and options for those dealing with
                    financial problems.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo2} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">T.W. Caldwell</h1>
                    <p className="f-18 f-500 mb-0">Idaho</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    There were a bunch of interesting information from this
                    course that really hit home thank you.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo1} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">T.M. Lebanon</h1>
                    <p className="f-18 f-500 mb-0">Or</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    Very good course. We learned a lot about budgets, credit and
                    keeping track of spending.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo1} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">B.C. Lowell</h1>
                    <p className="f-18 f-500 mb-0">Oregon</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    Unexpectedly good, informative review of possible avenues...
                    Very appropriate.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo2} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">R.C. Newport News</h1>
                    <p className="f-18 f-500 mb-0">Va</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Needed Encouragement
                  </p> */}
                  <p className="f-16 mb-0">
                    I was very glad to hear the teacher speak on encouragement.
                    No matter what you go through you are still a valuable
                    person. That made me feel so good!!.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo1} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">R.E. Vancouver</h1>
                    <p className="f-18 f-500 mb-0">Washington</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    The information was very useful and informational. It helps
                    to understand the ways your spending plan can affect you on
                    a daily basis.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Global Impact --> */}
      <section className="global-impact-wrap" style={{ position: "relative" }}>
        <div className="theme_container">
          <div
            className="heading_Wrap text-center"
            style={{ maxWidth: "930px" }}
          >
            <h1 className="f-52">Making a Global Impact</h1>
            <h1 className="f-52">
              Through <span>Education</span>
            </h1>
            <p
              className="f-16  mt-36 global-impact-wrap-first-para"
              style={{ zIndex: "1", position: "relative" }}
            >
              At Evergreen Financial Counseling, we believe education is the
              foundation of opportunity. That’s why we are proud to donate a
              portion of our proceeds to{" "}
              <a
                style={{ color: "#4e9c4b", fontWeight: "500" }}
                href="http://www.sunriserd.com"
                target="_blank"
              >
                Sunrise Rural Dwellers
              </a>
              , an organization dedicated to empowering communities in Uvuru, a
              community in Uzo Uwani Local Government Area of Enugu State,
              Nigeria. One of its key initiatives is helping children access
              education by covering school fees that many families cannot
              afford.
            </p>
          </div>
          <div className="row mt-36 global-impact-mobile">
            <div className="col-lg-7">
              <p className="f-16 global-impact-paragraph ">
                As part of our mission of empowering people through education,
                Evergreen Financial Counseling is helping send 400 children in
                Uvuru attend school, providing them with opportunities to learn,
                grow, and build brighter futures.
              </p>
              <Card style={{ margin: "20px auto", border: "unset" }}>
                <Card.Body>
                  {/* First Section */}
                  <div className="mb-4">
                    <Card.Title as="h4" className="mb-3">
                      <Button
                        variant={isExpanded1 ? "#4E9C4B " : ""}
                        size="sm"
                        onClick={toggleContent1}
                        style={{
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                          border: "1px solid green",
                          boxShadow: "unset",
                          backgroundColor: isExpanded1 ? "#4E9C4B" : "",
                        }}
                      >
                        {isExpanded1 ? (
                          <img src={minusIcon} alt="minus-icon" />
                        ) : (
                          <img src={plusIcon} alt="plus-icon" />
                        )}
                      </Button>
                      <span
                        style={{
                          fontSize: "22px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          color: "#0c1922",
                        }}
                      >
                        Here’s why this matters:
                      </span>
                    </Card.Title>
                    {isExpanded1 && (
                      <Card.Text>
                        <p className="pl-40">
                          Nigeria has no public education systems. Instead,
                          families have to pay monthly to send their children to
                          school—a cost that is out of reach for many families
                        </p>
                        <p className="pl-40">
                          If a husband dies, his property often passes to his
                          family—usually his parents or male relatives—leaving
                          the widow without resources. Education becomes an
                          impossible dream for many of these children.
                        </p>
                      </Card.Text>
                    )}
                  </div>

                  <div>
                    <Card.Title as="h4" className="mb-3 d-flex">
                      <Button
                        variant={isExpanded2 ? "#4E9C4B" : ""}
                        size="sm"
                        onClick={toggleContent2}
                        style={{
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                          border: "1px solid green",
                          boxShadow: "unset",
                          backgroundColor: isExpanded2 ? "#4E9C4B" : "",
                        }}
                      >
                        {isExpanded2 ? (
                          <img src={minusIcon} alt="minus-icon" />
                        ) : (
                          <img src={plusIcon} alt="plus-icon" />
                        )}
                      </Button>
                      <span
                        style={{
                          fontSize: "22px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          color: "#0c1922",
                        }}
                      >
                        When you choose Evergreen, you help change that.{" "}
                      </span>
                    </Card.Title>
                    {isExpanded2 && (
                      <Card.Text>
                        <p className="pl-40">
                          Evergreen starts by helping you complete your
                          bankruptcy requirements, but we don’t stop there. As
                          part of our commitment to education, we enroll you
                          in 7 Steps to a 720 Credit Score—for free—so you can
                          rebuild your financial future with confidence.
                        </p>
                        <p className="pl-40">
                          And we take it even further, reaching across the globe
                          to help educate children in need. Your decision to
                          work with us is making a lasting impact, not just in
                          your life but in the lives of hundreds of children
                          gaining access to education and opportunity
                        </p>
                      </Card.Text>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-5 " style={{ zIndex: "1" }}>
              <img
                src={Uvuru}
                alt="global-impact"
                className="global-impact-desktop-img"
              />
              <img
                src={Uvuru_Mobile}
                alt="global-impact"
                className="global-impact-mobile-img"
              />
            </div>
          </div>
        </div>
        <div
          className="Why_Evergreen_img_after"
          style={{ zIndex: "0", top: "30px" }}
        >
          <img
            src={whyEverGreenAfter}
            className="img-fluid"
            alt="whyevergreen-after"
          />
        </div>
      </section>

      {/* <!-- FAQ --> */}
      <section className="FAQ">
        <div className="theme_container">
          <div className="heading_Wrap">
            <h1 className="f-52">
              Frequently Asked <span>Questions</span>
            </h1>
            <p className="f-16 text-center mt-35">
              Our process is straightforward, simple and, most of all, friendly.
            </p>
          </div>
          <div className="faq_Wrap mt-60">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What is my username and password?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    If you have registered with Evergreen in the past, simply
                    enter the username and password you created the first time
                    you logged in. If you are a new Evergreen student, select
                    which class you would like to take from the home page, and
                    follow the prompts for registration.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What if I forgot my password?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Click the “Forgot Password?” link below the login box on the
                    homepage. Enter your email and follow the prompts.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Will my spouse and I have separate usernames and passwords?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes. You and your spouse can take the class together, but
                    you will have separate usernames and passwords.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Will my attorney receive a copy of my certificate?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    During registration, you'll have the option to enter your
                    attorney's email address. We will send your attorney a
                    certificate at the same time we send your certificate to
                    you. If you have received your certificate, we have emailed
                    it to your attorney as well.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    When will I get my certificate?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    After you have finished your course, a counselor will
                    generate your certificate of completion, upload it to your
                    account, and email it to you. You can also log into your
                    account to download your certificate at any time.
                    <br />
                    <br />
                    If you are in an emergency and need help finding your
                    certificate, feel free to call us at 1(800) 581-3513 and
                    we'll expedite your certificate.
                  </div>
                </div>
              </div>
              <div className="faq_btn mt-70 text-center ">
                <a href={`/faq`}>
                  <button className="btn btn_brnd  ">View All FAQs</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default HomePage;
