import React, { useEffect, useState } from "react";
import img_1 from "../../assets/images/SiteImg/img_1.png";
import img_2 from "../../assets/images/SiteImg/img_2.png";
import { Card, Button } from "react-bootstrap";
import plusIcon from '../../assets/images/SiteImg/plus.png'
import minusIcon from '../../assets/images/SiteImg/white-minus-icon.png'
import Uvuru from '../../assets/images/Uvuru.png'
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import Uvuru_Mobile from '../../assets/images/Uvuru-mobile.png'

const About = () => {
  const [isExpanded1, setIsExpanded1] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(true);

  const toggleContent1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
     
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <Header />
      <section className="about_us_page mt-250">
      <h1 style={{textTransform:"none"}} className="f-52 text-center">
      Ready to Connect With a Mortgage Broker? 
          </h1>
          <p className="mt-20 f-16 text-center">
          Complete this form, and we will connect you with a mortgage broker who can help you explore your journey to homeownership.

                </p>
                <div
      data-tf-live="01JJD04RAJKZDJMTHTM3DQYK4H"
      style={{ width: "100%", height: "400px" }}
    ></div>
      </section>
     

      <Footer />
    </div>
  );
};

export default About;
