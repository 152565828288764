import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <chat-widget location-id="4DBqkkZmMk8no4RZHPMT"

        legal-msg="By submitting your contact information you agree to receive SMS or e-mails from Evergreen Financial Counseling. Message and data rates may apply. Message frequency varies."
        show-prompt="false" use-email-field="true" chat-type="liveChat"
        live-chat-user-inactive-msg="Please leave your contact details. We will get back to you shortly"
        live-chat-feedback-note="Thank you, for taking your time." primary-color="#508E45" show-consent-checkbox="true">
      </chat-widget>
      <ToastContainer />
    </Provider>
  </BrowserRouter>
);


reportWebVitals();
