import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { toast } from "react-toastify";
import {
  UnEnroll,
  VerifyUser,
  deleteJointInfo,
  generateCert,
  generateCertRetake,
  generateCertSecond,
  generateCertSecondRetake,
  getCounty,
  getDistrict,
  getStates,
  getUserData,
  getUserRetakeData,
  resendCert,
  resendCertRetake,
  retakeCCCourse,
  retakeDECourse,
  updateProfile,
  uploadCert,
  uploadCertRetake,
  updateAdminAssociateAttorney,
  changePasswordByAdmin
} from "../../api/server";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import cross from "../../assets/images/SiteImg/cross.png";
import VerifyButton from "../Common/VerifyButton";
import { MdWarning } from "react-icons/md";
import { FaCopy } from "react-icons/fa";

const intialLoadingStates = {
  isCCVerifying:false,
  isDEVerifying:false,
  CCAssociating:false,
  DEAssociating:false
}

const EditingClient = () => {
  const [showJointFiler, setShowJointFiler] = useState(false);
  const [isInputClicked, setIsInputClicked] = useState(false);
  const [mainPasswordMessage, setMainPasswordMessage] = useState("");
  const [jointPasswordMessage, setJointPasswordMessage] = useState("");



  // const validationSchema = Yup.object().shape({
  //   firstname: Yup.string().required("First Name is required"),
  //   lastname: Yup.string().required("Last Name is required"),
  //   ssn: Yup.string().required("Last Four Digits of SSN is required").matches(/^\d{4}$/, "SSN must be exactly 4 digits and only numbers are allowed"),
  //   phone: Yup.string().required("Phone Number is required"),
  //   password: Yup.string(),
  //   // .required("Password is required"),
  //   addr1: Yup.string().required("Address Line 1 is required"),
  //   addr2: Yup.string(),
  //   city: Yup.string().required("City is required"),
  //   state: Yup.string().required("State is required"),
  //   zip: Yup.string().required("Zip Code is required"),
  //   email: Yup.string().required("Your Email is required"),
  //   username: Yup.string().required("Username is required"),
  //   joint_firstname: Yup.string(),
  //   joint_lastname: Yup.string(),
  //   joint_ssn: showJointFiler && Yup.string().required("Last Four Digits of SSN is required").matches(/^\d{4}$/, "SSN must be exactly 4 digits and only numbers are allowed"),
  //   joint_phone: Yup.string(),
  //   joint_firstname: showJointFiler
  //     ? Yup.string().required("First name is required")
  //     : Yup.string(),
  //   joint_lastname: showJointFiler
  //     ? Yup.string().required("Last name is required")
  //     : Yup.string(),

  //   joint_username: showJointFiler
  //     ? Yup.string().required("Username is required")
  //     : Yup.string(),
  //   // joint_password: showJointFiler
  //   //   ? Yup.string().required("Password is required")
  //   //   : Yup.string(),
  //   takenByPhone: Yup.boolean(),
  // });
  
  const validationSchema = Yup.object().shape({
  });

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const cert = queryParams.get("cert");

  const [deleteJoint, setDeleteJoint] = useState(false);

  const [response, setResponse] = useState();
  const [courseData, setCourseData] = useState();
  const [retakecourseData, setRetakeCourseData] = useState();
  const [secEmails, setSecEmails] = useState([]);
  const [getloading, setGetLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const [cancelEnrollLoader, setCancelEnrollLoader] = useState(false);
  const [cancelEnrollLoaderFirst, setCancelEnrollLoaderFirst] = useState(false);

  const [statesData, setStatesData] = useState([]);

  const [selectedStateFirst, setSelectedStateFirst] = useState("");
  const [selectedStateSecond, setSelectedStateSecond] = useState("");

  const [selectedCountyFirst, setSelectedCountyFirst] = useState("");
  const [selectedCountySecond, setSelectedCountySecond] = useState("");

  const [countiesDataFirst, setCountiesDataFirst] = useState([]);
  const [countiesDataSecond, setCountiesDataSecond] = useState([]);

  const [districtsDataFirst, setDistrictsDataFirst] = useState([]);
  const [districtsDataSecond, setDistrictsDataSecond] = useState([]);

  const [oldcode, setOldcode] = useState("");

  const [firstPDF, setFirstPDF] = useState();
  const [firstPDFJoint, setFirstPDFJoint] = useState();

  const [uploadingFirst, setUploadingFirst] = useState(false);
  const [uploadingFirstJoint, setUploadingFirstJoint] = useState(false);

  const [secondPDF, setSecondPDF] = useState();
  const [secondPDFJoint, setSecondPDFJoint] = useState();

  const [uploadingSecond, setUploadingSecond] = useState(false);
  const [uploadingSecondJoint, setUploadingSecondJoint] = useState(false);

  const [generatingFirst, setGeneratingFirst] = useState(false);
  const [generatingFirstJoint, setGeneratingFirstJoint] = useState(false);

  const [generatingSecond, setGeneratingSecond] = useState(false);
  const [generatingSecondJoint, setGeneratingSecondJoint] = useState(false);

  const [firstPDFRetake, setFirstPDFRetake] = useState();
  const [firstPDFJointRetake, setFirstPDFJointRetake] = useState();

  const [uploadingFirstRetake, setUploadingFirstRetake] = useState(false);
  const [uploadingFirstJointRetake, setUploadingFirstJointRetake] = useState(false);

  const [secondPDFRetake, setSecondPDFRetake] = useState();
  const [secondPDFJointRetake, setSecondPDFJointRetake] = useState();

  const [uploadingSecondRetake, setUploadingSecondRetake] = useState(false);
  const [uploadingSecondJointRetake, setUploadingSecondJointRetake] = useState(false);

  const [generatingFirstRetake, setGeneratingFirstRetake] = useState(false);
  const [generatingFirstJointRetake, setGeneratingFirstJointRetake] = useState(false);

  const [generatingSecondRetake, setGeneratingSecondRetake] = useState(false);
  const [generatingSecondJointRetake, setGeneratingSecondJointRetake] = useState(false);

  const [resendingFirstRetake, setResendingFirstRetake] = useState(false);
  const [resendingFirstJointRetake, setResendingFirstJointRetake] = useState(false);

  const [resendingSecondRetake, setResendingSecondRetake] = useState(false);
  const [resendingSecondJointRetake, setResendingSecondJointRetake] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState(intialLoadingStates)
  const [attCodeValidation, setattCodeValidation] = useState(false);
  const [passwordUpdating, setPasswordUpdating] = useState(false)

  const [showPass, setShowPass] = useState(false);
  const [showAttymPass, setShowAttymPass] = useState(false);

  const [resendingFirst, setResendingFirst] = useState(false);
  const [resendingFirstJoint, setResendingFirstJoint] = useState(false);

  const [reTakingCC, setReTakingCC] = useState(false);
  const [reTakingDE, setReTakingDE] = useState(false);

  // [allow associate]
  const [allowAssociate, setAllowAssociate] = useState({
    CC:true,
    DE:true
  })

  const [resendingSecond, setResendingSecond] = useState(false);
  const [resendingSecondJoint, setResendingSecondJoint] = useState(false);
  const [cancelEnrollData, setCancelEnrollData] = useState({
    id: "",
    cource: "",
  });
  const [isModal, setIsModal] = useState(false);

  const [copied, setCopied] = useState(false);
  const [copiedSecond, setCopiedSecond] = useState(false);



  const handleCopy = () => {
  
    let linkToCopy = `https://www.evergreenclass.com/beforecontactwithcounselor?id=${response?.id}&course=first`; 

    navigator.clipboard.writeText(linkToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); 
  };
  
  const handleCopysecond = () => {
    let linkToCopy = `https://www.evergreenclass.com/beforecontactwithcounselor?id=${response?.id}&course=second`; 
      navigator.clipboard.writeText(linkToCopy);
      setCopiedSecond(true);
      setTimeout(() => setCopied(false), 2000); 
    };

  const handleUnEnroll = async (setValues, values) => {
    const data = {
      id: cancelEnrollData.id,
      course: cancelEnrollData.cource,
    };
    console.log("value === ", cancelEnrollData.cource === "first", data);
    try {
      if (cancelEnrollData.cource === "first") {
        setCancelEnrollLoaderFirst(true);
      } else {
        setCancelEnrollLoader(true);
      }

      const response = await UnEnroll(data);
      if (response) {
        if (cancelEnrollData.cource === "first") {
          setValues({
            ...values,
            courseFirstEnrol: false,
            paid_first:""
          });
        } else {
          setValues({
            ...values,
            courseSecondEnrol: false,
            paid_second:""
          });
        }
        setCancelEnrollLoader(false);
        setCancelEnrollLoaderFirst(false);
        toast.success("Cancel Enrollment successfully");
      }
      setIsModal(false);
      setCancelEnrollData({
        id: "",
        cource: "",
      });
    } catch (error) {
      setCancelEnrollLoader(false);
      setCancelEnrollLoaderFirst(false);
      setIsModal(false);
      toast.error(error.response.data.error);
      console.error("Error:", error);
      setCancelEnrollData({
        id: "",
        cource: "",
      });
    }
  };

  const {
    handleChange,
    touched,
    values,
    setValues,
    errors,
    handleSubmit,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: { response },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setValues }) => {
      if (
        values.couponcode_first &&
        oldcode !== values.couponcode_first &&
        values.attorneyCodeExist
      ) {
        toast.error("Please verify attorney code.");
      } else {
        setPostLoading(true);
        updateProfile(userId, values)
          .then((res) => {
            fetchData(true);
            fetchRetakeData(true);
            if (values.couponcode_first == "") {
              setSecEmails([]);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.error);
            setPostLoading(false);
          });
      }
    },
  });
  console.log("-----values ===> ", values)
  const generateCertificate = (values, isJoint) => {
    generateCert(userId, values, isJoint ? "true" : "false")
      .then((res) => {
        toast.success(res.data.message);
        setGeneratingFirst(false);
        setGeneratingFirstJoint(false);
        let data = { ...courseData };

        if (values.course == "first") {
          if (res.data.mainLink) {
            data = {
              ...data,
              courseFirstGenerated: "yes",
              courseFirstDownloaded: res.data.mainLink,
              courseFirstDate: res.data.date,
            };
          }
          if (res.data.jointLink) {
            data = {
              ...data,
              jointCourseFirstGenerated: "yes",
              jointCourseFirstDownloaded: res.data.jointLink,
              jointCourseFirstDate: res.data.date,
            };
          }
          setCourseData({
            ...data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setGeneratingFirst(false);
        setGeneratingFirstJoint(false);
      });
  };

  const resendCertificate = (values, setLoading) => {
    resendCert(values)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  const generateCertificateSecond = (values) => {
    generateCertSecond(userId, values)
      .then((res) => {
        toast.success(res.data.message);
        setCourseData({
          ...courseData,
          jointCourseSecondGenerated: "yes",
          jointCourseSecondDownloaded: res.data.link2,
          jointCourseSecondDate: res.data.date,
          generated: "yes",
          downloaded: res.data.link1,
          date: res.data.date,
        });
        if (values.sendEmail) {
          navigate(
            `/certificate-to-court?id=${userId}&court=${res.data.courtLink}`
          );
        }
        setGeneratingSecond(false);
        setGeneratingSecondJoint(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setGeneratingSecond(false);
        setGeneratingSecondJoint(false);
      });
  };

  const uploadCertificate = (course, values, isJoint) => {
    const data = new FormData();
    data.append("courseId", values.courseId);
    data.append("course", values.course);
    data.append("email", values.email);
    data.append("attyemail", values.attyemail);
    data.append("file", values.file);
    data.append("retake", 0);

    uploadCert(userId, data, isJoint ? "true" : "false")
      .then((res) => {
        toast.success(res.data.error);
        setUploadingFirst(false);
        setUploadingFirstJoint(false);
        if (course == "first") {
          if (isJoint) {
            setCourseData({
              ...courseData,
              jointCourseFirstGenerated: "yes",
              jointCourseFirstDownloaded: res.data.link,
              jointCourseFirstDate: res.data.date,
            });
          } else {
            setCourseData({
              ...courseData,
              courseFirstGenerated: "yes",
              courseFirstDownloaded: res.data.link,
              courseFirstDate: res.data.date,
            });
          }
        } else {
          if (isJoint) {
            setCourseData({
              ...courseData,
              jointCourseSecondGenerated: "yes",
              jointCourseSecondDownloaded: res.data.link,
              jointCourseSecondDate: res.data.date,
            });
          } else {
            setCourseData({
              ...courseData,
              generated: "yes",
              downloaded: res.data.link,
              date: res.data.date,
            });
          }
        }
        setUploadingSecond(false);
        setUploadingSecondJoint(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setUploadingFirst(false);
        setUploadingSecond(false);
        setUploadingFirstJoint(false);
        setUploadingSecondJoint(false);
      });
  };

  const reTakeCC = () => {
    retakeCCCourse(userId)
      .then((res) => {
        toast.success(res.data.message);
        setReTakingCC(false);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setReTakingCC(false);
      });
  };

  const reTakeDE = () => {
    retakeDECourse(userId)
      .then((res) => {
        toast.success(res.data.message);
        setReTakingDE(false);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setReTakingDE(false);
      });
  };

  const generateCertificateRetake = (values, isJoint) => {
    generateCertRetake(userId, values, isJoint ? "true" : "false")
      .then((res) => {
        toast.success(res.data.message);
        setGeneratingFirstRetake(false);
        setGeneratingFirstJointRetake(false);
        let data = { ...retakecourseData };

        if (values.course == "first") {
          if (res.data.mainLink) {
            data = {
              ...data,
              courseFirstGenerated: "yes",
              courseFirstDownloaded: res.data.mainLink,
              courseFirstDate: res.data.date,
            };
          }
          if (res.data.jointLink) {
            data = {
              ...data,
              jointCourseFirstGenerated: "yes",
              jointCourseFirstDownloaded: res.data.jointLink,
              jointCourseFirstDate: res.data.date,
            };
          }
          console.log(data,res);
          
          setRetakeCourseData({
            ...data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setGeneratingFirstRetake(false);
        setGeneratingFirstJointRetake(false);
      });
  };

  const resendCertificateRetake = (values, setLoading) => {
    resendCertRetake(values)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  const generateCertificateSecondRetake = (values) => {
    generateCertSecondRetake(userId, values)
      .then((res) => {
        toast.success(res.data.message);
        setRetakeCourseData({
          ...retakecourseData,
          jointCourseSecondGenerated: "yes",
          jointCourseSecondDownloaded: res.data.link2,
          jointCourseSecondDate: res.data.date,
          generated: "yes",
          downloaded: res.data.link1,
          date: res.data.date,
        });
        if (values.sendEmail) {
          navigate(
            `/certificate-to-court?id=${userId}&court=${res.data.courtLink}`
          );
        }
        setGeneratingSecondRetake(false);
        setGeneratingSecondJointRetake(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setGeneratingSecondRetake(false);
        setGeneratingSecondJointRetake(false);
      });
  };

  const uploadCertificateRetake = (course, values, isJoint) => {
    const data = new FormData();
    data.append("courseId", values.courseId);
    data.append("course", values.course);
    data.append("email", values.email);
    data.append("attyemail", values.attyemail);
    data.append("file", values.file);
    data.append("retake", 1);

    uploadCertRetake(userId, data, isJoint ? "true" : "false")
      .then((res) => {
        toast.success(res.data.error);
        setUploadingFirstRetake(false);
        setUploadingFirstJointRetake(false);
        if (course == "first") {
          if (isJoint) {
            setRetakeCourseData({
              ...retakecourseData,
              jointCourseFirstGenerated: "yes",
              jointCourseFirstDownloaded: res.data.link,
              jointCourseFirstDate: res.data.date,
            });
          } else {
            setRetakeCourseData({
              ...retakecourseData,
              courseFirstGenerated: "yes",
              courseFirstDownloaded: res.data.link,
              courseFirstDate: res.data.date,
            });
          }
        } else {
          if (isJoint) {
            setRetakeCourseData({
              ...retakecourseData,
              jointCourseSecondGenerated: "yes",
              jointCourseSecondDownloaded: res.data.link,
              jointCourseSecondDate: res.data.date,
            });
          } else {
            setRetakeCourseData({
              ...retakecourseData,
              generated: "yes",
              downloaded: res.data.link,
              date: res.data.date,
            });
          }
        }
        setUploadingSecondRetake(false);
        setUploadingSecondJointRetake(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setUploadingFirstRetake(false);
        setUploadingSecondRetake(false);
        setUploadingFirstJointRetake(false);
        setUploadingSecondJointRetake(false);
      });
  };

  const fetchData = async (show = false, fromDeleteJoint = false) => {
    await getUserData(userId)
      .then((res) => {
        if (show) {
          toast.success("User updated successfully");
          setPostLoading(false);
        }

        setCourseData(res.data.data.fetchStudent);
        setOldcode(res.data.data.user.couponcode_first);
        console.log("res.data.data.user",res.data.data.user)
        const response_value_body = {
          ...res.data.data.user,
          deliveryMethod:"E-mail",
          takenByPhone:res.data.data.user.takenByPhone === "true",
          activeCode:res.data.data.activeCode,
          password:res.data.data.fetchStudent.password,
          joint_password:res.data.data.fetchStudent.joint_password,        
        }
        
        setMainPasswordMessage(res.data.data.fetchStudent.mainPasswordMessage)
        setJointPasswordMessage(res.data.data.fetchStudent.jointPasswordMessage)
        setResponse(response_value_body);
        setValues(response_value_body);
        setSecEmails(res.data.data.secEmails);
        if (res.data.data.user.joint_username !== "") {
          setShowJointFiler(true);
        }
        setSelectedStateFirst(res.data.data.user.judicialdistrict_state_first);
        setSelectedCountyFirst(
          res.data.data.user.judicialdistrict_county_first
        );
        setSelectedCountySecond(
          res.data.data.user.judicialdistrict_county_second
        );
        setSelectedStateSecond(
          res.data.data.user.judicialdistrict_state_second
        );
        setGetLoading(false);
        if (fromDeleteJoint) {
          setShowJointFiler(false);
          setDeleteJoint(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setGetLoading(false);
        setDeleteJoint(false);
      });
    try {
      const response = await getStates();
      const data = response.data;
      setStatesData(data);
    } catch (error) {
      console.error("Error fetching states data:", error);
    }
  };
  const fetchRetakeData = async (show = false, fromDeleteJoint = false) => {
    await getUserRetakeData(userId)
      .then((res) => {
        setRetakeCourseData(res.data.data.fetchStudent);
        setGetLoading(false);
        if (fromDeleteJoint) {
          setShowJointFiler(false);
          setDeleteJoint(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setGetLoading(false);
        setDeleteJoint(false);
      });
  };
  useEffect(() => {
    fetchRetakeData();
    fetchData();
  }, []);

  useEffect(() => {
    setCountiesDataFirst([]);
    const fetchData = async () => {
      try {
        if (selectedStateFirst) {
          const response = await getCounty({ state: selectedStateFirst });
          const countiesData = response.data;
          setCountiesDataFirst(countiesData);
        }
      } catch (error) {}
    };

    fetchData();
  }, [selectedStateFirst]);

  useEffect(() => {
    setCountiesDataSecond([]);

    const fetchData = async () => {
      try {
        if (selectedStateSecond) {
          const response = await getCounty({ state: selectedStateSecond });
          const countiesData = response.data;
          setCountiesDataSecond(countiesData);
        }
      } catch (error) {}
    };

    fetchData();
  }, [selectedStateSecond]);

  useEffect(() => {
    setDistrictsDataFirst([]);
    const fetchData = async () => {
      try {
        if (selectedCountyFirst) {
          const districtResponse = await getDistrict({
            state: selectedStateFirst,
            county: selectedCountyFirst,
          });
          const distData = districtResponse.data;

          setDistrictsDataFirst(distData);
        }
      } catch (error) {}
    };

    fetchData();
  }, [selectedCountyFirst]);

  useEffect(() => {
    setDistrictsDataSecond([]);
    const fetchData = async () => {
      try {
        if (selectedCountySecond) {
          const districtResponse = await getDistrict({
            state: selectedStateSecond,
            county: selectedCountySecond,
          });
          const distData = districtResponse.data;

          setDistrictsDataSecond(distData);
        }
      } catch (error) {}
    };

    fetchData();
  }, [selectedCountySecond]);

  const handleVerifyAttorney = async (code, setValues, course) => {
    setattCodeValidation(false);
    // e.preventDefault();
    const body = {
      code: code,
    };
    try {
      setLoadingStates(prev=>(
        {...prev, isCCVerifying:course === "CC", isDEVerifying:course === "DE"}
      ))
      const response = await VerifyUser(body);
      if (response.status === 200) {
        const data = response.data;
        setOldcode(code);
        const payStatus =
          response.data.data.deliverymethod == "bill" ? "checked" : "";
        setSecEmails(response.data.secEmails);
        setValues((prev) => ({
          ...prev,
          // couponcode_first: course==="CC"? code:prev.couponcode_first,
          // couponcode_second: course==="DE"? code:prev.couponcode_second,
          attyemail: data.data.email,
          attorneyname: data.data.firstname,
          attorneyCodeExist: true,
          attyphone: data.data.phone,
          // paid_first: prev.courseFirstEnrol ? prev.paid_first : payStatus,
          // paid_second: prev.courseSecondEnrol ? prev.paid_second : payStatus,
        }));

        if (course==="DE")  setLoadingStates(prev=>({...prev, isDEVerifying:false}))
        else setLoadingStates(prev=>({...prev, isCCVerifying:false}))
        toast.success("Attorney code verified!");
      } else {
        setattCodeValidation(course);
      }
    } catch (error) {
      setattCodeValidation(course);
      console.error("Error fetching states data:", error);
      setLoadingStates(intialLoadingStates);
    }
  };

  const deleteJointFiler = () => {
    setDeleteJoint(true);
    deleteJointInfo(userId)
      .then((res) => {
        fetchData(true, true);
        fetchRetakeData(true, true);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setDeleteJoint(false);
      });
  };
  const changePasswordHandler = async() =>{
    if (passwordUpdating ) return;
    if (!values?.password || values?.password?.length<1) return toast.error("Password cannot be empty!") 
    setPasswordUpdating(true)
    try{
      const body ={
        id:userId,
        password:values.password,
        isJoint:false
      }
      console.log("------- body", body, values.password)
      const response = await changePasswordByAdmin(body)
      if (response.status===200){
        toast.success("Passowrd updated successfully!")
      }
    }
    catch (error){
      toast.error(error.response.data.error)
      setPasswordUpdating(false)
    }

    finally{
      setPasswordUpdating(false)
    }
  }


  // course can be CC || DE
  const handleAssociateAttorney = async(course) => {
    console.log("🚀 ~ handleAssociateAttorney ~ course:", course)
    if (loadingStates.CCAssociating || loadingStates.DEAssociating) return;    
    try {
      setLoadingStates(prev=>({...prev, CCAssociating:course === "CC", DEAssociating:course === "DE"}))
      const body = {
        id:userId,
        course,
        couponcode_first:values.couponcode_first,
        couponcode_second:values.couponcode_second,
        paid_first: values.paid_first,
        paid_second: values.paid_second,
        attyemail: values.attyemail,
        attorneyname: values.attorneyname,
        attyphone: values.attyphone,
        courseFirstEnrol:values.courseFirstEnrol,
        courseSecondEnroll:values.courseSecondEnrol
  
      }
      console.log("🚀 ~ handleAssociateAttorney ~ body:", body)
      const response = await updateAdminAssociateAttorney(body)
      if (response.status===200){
        toast.success(`${course} course associated successfully!`)
        setResponse(prev=>({...prev,paid_first:response.data.paid_first,paid_second:response.data.paid_second}))
        setValues((prev)=>({...prev, activeCode:course, paid_first:response.data.paid_first,paid_second:response.data.paid_second}))
      }
      setLoadingStates(intialLoadingStates)

    } catch (error) {
      console.log("🚀 ~ handleAssociateAttorney ~ error:", error)
      toast.error(error.response.data.error)
      setLoadingStates(intialLoadingStates)
    }
    
  }

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <DashboardNavbar />
          {getloading || !response ? (
            <p>
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </p>
          ) : (
            values && (
              <section className="client-editing">
                {
                  console.log("courseData.perFirstCourseMain",response,courseData,courseData.perFirstCourseMain)
                }
                {
                  
                  ( !response?.judicialdistrict_first && response?.courseFirstEnrol && courseData?.courseFirstGenerated == "" && courseData.perFirstCourseMain =="100") &&

                  <div className="text-box text_box_sec" 
                  style={{ 
                    marginBottom: '2%', 
                    padding: '15px', 
                    backgroundColor: '#FFA500',  // Orange (Warning)
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    borderRadius: '5px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '8px',
                    position: 'relative',
                    minHeight:'60px',
                    maxHeight:'60px'
                  }}>
                  <MdWarning size={24} />
                  <h5 style={{ marginBottom: '0', flex: 1 }}>
                  Missing information for Course One. Share the link to complete it.
                  </h5>
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <button 
                      onClick={handleCopy}
                      style={{ 
                        backgroundColor: '#fff', 
                        color: '#FFA500', 
                        border: 'none', 
                        padding: '8px 12px', 
                        borderRadius: '5px', 
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        position: 'relative' // Keep button position intact
                      }}>
                      <FaCopy /> Copy Link
                    </button>
                    {copied && 
                      <span style={{ 
                        position: 'absolute', 
                        top: '-25px', 
                        left: '50%', 
                        transform: 'translateX(-50%)', 
                        backgroundColor: '#000', 
                        color: '#fff', 
                        padding: '4px 8px', 
                        borderRadius: '4px', 
                        fontSize: '12px',
                        whiteSpace: 'nowrap'
                      }}>
                        Link Copied!
                      </span>
                    }
                  </div>
                  </div>
                }
                {

                  (!response?.judicialdistrict_second && response?.courseSecondEnrol && courseData?.generated == "" && courseData.perSecondCourseMain =="100") &&

                  <div className="text-box text_box_sec" 
                  style={{ 
                    marginBottom: '5%', 
                    padding: '15px', 
                    backgroundColor: '#FFA500',  
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    borderRadius: '5px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '8px',
                    position: 'relative', 
                    minHeight:'60px',
                    maxHeight:'60px'
                  }}>
                  <MdWarning size={24} />
                  <h5 style={{ marginBottom: '0', flex: 1 }}>
                  Missing information for Course Two. Share the link to complete it.
                  </h5>
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <button 
                      onClick={handleCopysecond}
                      style={{ 
                        backgroundColor: '#fff', 
                        color: '#FFA500', 
                        border: 'none', 
                        padding: '8px 12px', 
                        borderRadius: '5px', 
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        position: 'relative' // Keep button position intact
                      }}>
                      <FaCopy /> Copy Link
                    </button>
                    {copiedSecond && 
                      <span style={{ 
                        position: 'absolute', 
                        top: '-25px', 
                        left: '50%', 
                        transform: 'translateX(-50%)', 
                        backgroundColor: '#000', 
                        color: '#fff', 
                        padding: '4px 8px', 
                        borderRadius: '4px', 
                        fontSize: '12px',
                        whiteSpace: 'nowrap'
                      }}>
                        Link Copied!
                      </span>
                    }
                  </div>
                 </div>
                
                }
                <form onSubmit={handleSubmit}>
                  <div className="col-lg-12">
                    <div className="input_wrap">
                      <label className="label-title">Notes</label>
                      <textarea
                        name="notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notes}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="client-head">
                    <h1>Account Details</h1>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">
                          Bankruptcy Case No.
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="12-34567"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="bankruptcycasenum"
                          value={values.bankruptcycasenum}
                        />
                        {errors.bankruptcycasenum &&
                        touched.bankruptcycasenum ? (
                          <p className="text-danger">
                            {errors.bankruptcycasenum}
                          </p>
                        ) : null}

                        <div></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">Attorney Name</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="attorneyname"
                          value={values.attorneyname}
                        />
                        {errors.attorneyname && touched.attorneyname ? (
                          <p className="text-danger">{errors.attorneyname}</p>
                        ) : null}
                      </div>
                    </div>

                    {response.courseFirstEnrol && (
                      <div className="col-lg-6">
                        <div className="col-lg-12 mb-4 fw-bold">
                          <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                            Judicial District(First Course)
                          </h5>
                        </div>
                        <div className="col-lg-12">
                          <div className=" input_wrap ">
                            <label className="label-title">State</label>
                            <select
                              value={values.judicialdistrict_state_first}
                              onChange={(e) => {
                                setSelectedStateFirst(e.target.value);
                                setSelectedCountyFirst("");
                                setValues({
                                  ...values,
                                  judicialdistrict_state_first: e.target.value,
                                  judicialdistrict_county_first: "",
                                  judicialdistrict_first: "",
                                });
                              }}
                              className="form-control"
                            >
                              {statesData.length > 0 && (
                                <option value="">Select a State</option>
                              )}

                              {statesData.length > 0 ? (
                                statesData.map((state, index) => (
                                  <option key={index} value={state.state}>
                                    {state.state}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                            {errors.judicialdistrict_state_first &&
                            touched.judicialdistrict_state_first ? (
                              <p className="text-danger">
                                {errors.judicialdistrict_state_first}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {selectedStateFirst && (
                          <div className="row">
                            <div className="col-lg-6">
                              <div className=" input_wrap ">
                                <select
                                  className="form-control"
                                  value={values.judicialdistrict_county_first}
                                  onChange={(e) => {
                                    setSelectedCountyFirst(e.target.value);
                                    setValues({
                                      ...values,
                                      judicialdistrict_county_first:
                                        e.target.value,
                                    });
                                  }}
                                >
                                  {countiesDataFirst.length > 0 && (
                                    <option value="">Select a county</option>
                                  )}
                                  {/* {values.judicialdistrict_county === "" && (
                                <option value="">Select a county</option>
                              )} */}
                                  {countiesDataFirst.length > 0 ? (
                                    countiesDataFirst.map((county, index) => (
                                      <option key={index} value={county.county}>
                                        {county.county}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Loading...</option>
                                  )}
                                </select>
                                {errors.judicialdistrict_county_first &&
                                touched.judicialdistrict_county_first ? (
                                  <p className="text-danger">
                                    {errors.judicialdistrict_county_first}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            {selectedCountyFirst && (
                              <div className="col-lg-6">
                                <div className=" input_wrap ">
                                  <select
                                    className="form-control"
                                    value={values.judicialdistrict_first}
                                    onChange={(e) => {
                                      setValues({
                                        ...values,
                                        judicialdistrict_first: e.target.value,
                                      });
                                    }}
                                  >
                                    {districtsDataFirst.length > 0 && (
                                      <option value="">
                                        Select a Judicial District
                                      </option>
                                    )}
                                    {/* {values.judicialdistrict === "" && (
                                <option value="">
                                  Select a Judicial District
                                </option>
                              )} */}
                                    {districtsDataFirst.length > 0 ? (
                                      districtsDataFirst.map(
                                        (district, index) => (
                                          <option
                                            key={index}
                                            value={district.subdistrict}
                                          >
                                            {district.subdistrict}
                                          </option>
                                        )
                                      )
                                    ) : (
                                      <option value="">Loading...</option>
                                    )}
                                  </select>
                                  {errors.judicialdistrict_first &&
                                  touched.judicialdistrict_first ? (
                                    <p className="text-danger">
                                      {errors.judicialdistrict_first}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {response.courseSecondEnrol && (
                      <div className="col-lg-6">
                        <div className="col-lg-12 mb-4 fw-bold">
                          <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                            Judicial District(Second Course)
                          </h5>
                        </div>
                        <div className="col-lg-12">
                          <div className=" input_wrap ">
                            <label className="label-title">State</label>
                            <select
                              value={selectedStateSecond}
                              onChange={(e) => {
                                setSelectedStateSecond(e.target.value);
                                setSelectedCountySecond("");
                                setValues({
                                  ...values,
                                  judicialdistrict_state_second: e.target.value,
                                  judicialdistrict_county_second: "",
                                  judicialdistrict_second: "",
                                });
                              }}
                              className="form-control"
                            >
                              {statesData.length > 0 && (
                                <option value="">Select a State</option>
                              )}

                              {statesData.length > 0 ? (
                                statesData.map((state, index) => (
                                  <option key={index} value={state.state}>
                                    {state.state}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                            {errors.judicialdistrict_state_second &&
                            touched.judicialdistrict_state_second ? (
                              <p className="text-danger">
                                {errors.judicialdistrict_state_second}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {selectedStateSecond && (
                          <div className="row">
                            <div className="col-lg-6">
                              <div className=" input_wrap ">
                                <select
                                  className="form-control"
                                  value={values.judicialdistrict_county_second}
                                  onChange={(e) => {
                                    setSelectedCountySecond(e.target.value);
                                    setValues({
                                      ...values,
                                      judicialdistrict_county_second:
                                        e.target.value,
                                    });
                                  }}
                                >
                                  {countiesDataSecond.length > 0 && (
                                    <option value="">Select a county</option>
                                  )}
                                  {/* {values.judicialdistrict_county === "" && (
                                <option value="">Select a county</option>
                              )} */}
                                  {countiesDataSecond.length > 0 ? (
                                    countiesDataSecond.map((county, index) => (
                                      <option key={index} value={county.county}>
                                        {county.county}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Loading...</option>
                                  )}
                                </select>
                                {errors.judicialdistrict_county_second &&
                                touched.judicialdistrict_county_second ? (
                                  <p className="text-danger">
                                    {errors.judicialdistrict_county_second}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            {selectedCountySecond && (
                              <div className="col-lg-6">
                                <div className=" input_wrap ">
                                  <select
                                    className="form-control"
                                    value={values.judicialdistrict_second}
                                    onChange={(e) => {
                                      setValues({
                                        ...values,
                                        judicialdistrict_second: e.target.value,
                                      });
                                    }}
                                  >
                                    {districtsDataSecond.length > 0 && (
                                      <option value="">
                                        Select a Judicial District
                                      </option>
                                    )}
                                    {/* {values.judicialdistrict === "" && (
                                <option value="">
                                  Select a Judicial District
                                </option>
                              )} */}
                                    {districtsDataSecond.length > 0 ? (
                                      districtsDataSecond.map(
                                        (district, index) => (
                                          <option
                                            key={index}
                                            value={district.subdistrict}
                                          >
                                            {district.subdistrict}
                                          </option>
                                        )
                                      )
                                    ) : (
                                      <option value="">Loading...</option>
                                    )}
                                  </select>
                                  {errors.judicialdistrict_second &&
                                  touched.judicialdistrict_second ? (
                                    <p className="text-danger">
                                      {errors.judicialdistrict_second}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="client-head">
                    <div className="row align-items-center">
                      <div className="col-lg-4">
                        <h1>Personal Info...</h1>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="firstname"
                          value={values.firstname}
                        />
                        {errors.firstname && touched.firstname ? (
                          <p className="text-danger">{errors.firstname}</p>
                        ) : null}

                        <div></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">Middle Initial</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="middleinitial"
                          value={values.middleinitial}
                        />
                        {errors.middleinitial && touched.middleinitial ? (
                          <p className="text-danger">{errors.middleinitial}</p>
                        ) : null}

                        <div></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="lastname"
                          value={values.lastname}
                        />
                        {errors.lastname && touched.lastname ? (
                          <p className="text-danger">{errors.lastname}</p>
                        ) : null}

                        <div></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">Last 4 SSN</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="ssn"
                          value={values.ssn}
                        />
                        {errors.ssn && touched.ssn ? (
                          <p className="text-danger">{errors.ssn}</p>
                        ) : null}

                        <div></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">Phone No.</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="phone"
                          value={values.phone}
                        />
                        {errors.phone && touched.phone ? (
                          <p className="text-danger">{errors.phone}</p>
                        ) : null}

                        <div></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <p className="text-danger">{errors.email}</p>
                        ) : null}

                        <div></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div 
                        style={{
                          display:"flex",
                          justifyContent:"space-between",
                          gap:"12px"
                        }} 
                        className=" input_wrap flex justify-between">
                        <div 
                          style={{
                            flexGrow:1
                          }}
                          >
                          <label className="label-title">Password</label>
                          <div 
                            style={{
                              position:"relative",
                              width:"100%"
                            }} 
                              className="flex gap-2 items-center ">

                            <input
                              type={showPass ? "text" : "password"}
                              className="relative form-control"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              onFocus={() => setIsInputClicked(true)}
                            />
                              
                                {showPass ? (
                                  <i
                                    className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                                    style={{ top: "20px", right: "10px" }}
                                    onClick={() => {
                                      setShowPass(false);
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                ) : ( 
                                  <i
                                    className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                                    style={{ top: "20px", right: "10px" }}
                                    onClick={() => {
                                      setShowPass(true);
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )}
                          </div>
                          {errors.password && touched.password ? (
                            <p className="text-danger">{errors.password}</p>
                            ) : null}

                         
                        {mainPasswordMessage && <p style={{marginTop:'4px',  fontSize: "14px", color: "green" }}>{mainPasswordMessage}</p>}

                        </div>
                         <div style={{paddingTop:'2%'}}>
                         <button
                            type="button"
                            disabled={values.password.length<1 || !values.password || !isInputClicked || passwordUpdating } 
                            onClick={changePasswordHandler}
                            style={{alignItems:"center"}}
                            className="btn_brnd">
                               {passwordUpdating ? (
                                  <ThreeDots
                                    height="20"
                                    width="148"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Change Password"
                                )}
                              
                          </button>
                         </div>
                          
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">User Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username}
                        />
                        {errors.username && touched.username ? (
                          <p className="text-danger">{errors.username}</p>
                        ) : null}
                        <div></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap ">
                        <label className="label-title">
                          Mother Maiden Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mother_maiden}
                          name="mother_maiden"
                        />
                        {errors.mother_maiden && touched.mother_maiden ? (
                          <p className="text-danger">{errors.mother_maiden}</p>
                        ) : null}
                        <div></div>
                      </div>
                    </div>
                    <div className="iagree_radio mb-3">
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setValues({
                              ...values,
                              takenByPhone: !values.takenByPhone,
                            });
                          }}
                          onBlur={handleBlur}
                          checked={values.takenByPhone}
                          id="courseTaken"
                          className=" "
                        />
                        <label for="courseTaken">Taken by phone</label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="iagree_radio mb-3">
                        <div>
                          <input
                            type="checkbox"
                            name="tickbox"
                            id="joint"
                            className=" "
                            checked={showJointFiler}
                            onChange={(e) => {
                              setShowJointFiler(e.target.checked);
                            }}
                          />
                          <label for="joint">Joint </label>
                        </div>
                      </div>
                      {/* conditional rendering */}
                      {showJointFiler && (
                        <div>
                          <div className="head mt-40 d-flex justify-content-between align-items-center">
                            <h1 className="f18">Joint Filer Info</h1>
                            {values.joint_username && (
                              <button
                                type="button"
                                disabled={deleteJoint}
                                className="btn_brnd"
                                onClick={deleteJointFiler}
                              >
                                {deleteJoint ? (
                                  <ThreeDots
                                    height="20"
                                    width="135"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Delete Joint Filer"
                                )}
                              </button>
                            )}
                          </div>
                          <div className="row mt-40">
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="joint_firstname"
                                  placeholder="Enter your first name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.joint_firstname}
                                />
                                {errors.joint_firstname &&
                                touched.joint_firstname ? (
                                  <p className="text-danger">
                                    {errors.joint_firstname}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Middle Initial
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="joint_middleinitial"
                                  placeholder="Enter your middle name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.joint_middleinitial}
                                />
                                {errors.joint_middleinitial &&
                                touched.joint_middleinitial ? (
                                  <p className="text-danger">
                                    {errors.joint_middleinitial}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="joint_lastname"
                                  placeholder="Enter your last name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.joint_lastname}
                                />
                                {errors.joint_lastname &&
                                touched.joint_lastname ? (
                                  <p className="text-danger">
                                    {errors.joint_lastname}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Last Four Digits of SSN
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="joint_ssn"
                                  placeholder="Enter digit of SSN"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.joint_ssn}
                                />
                                {errors.joint_ssn && touched.joint_ssn ? (
                                  <p className="text-danger">
                                    {errors.joint_ssn}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="joint_phone"
                                  placeholder="Enter your phone no."
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.joint_phone}
                                />
                                {errors.joint_phone && touched.joint_phone ? (
                                  <p className="text-danger">
                                    {errors.joint_phone}
                                  </p>
                                ) : null}
                              </div>
                            </div> */}
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Joint Filer's Username
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="joint_username"
                                  placeholder="Enter your username"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.joint_username}
                                />
                                {errors.joint_username &&
                                touched.joint_username ? (
                                  <p className="text-danger">
                                    {errors.joint_username}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Joint Filer's Password
                                </label>
                                <input
                                  type={showAttymPass ? "text" : "password"}
                                  className="form-control"
                                  name="joint_password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.joint_password}
                                />
                                {showAttymPass ? (
                                  <i
                                    className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                                    style={{ top: "20px", right: "10px" }}
                                    onClick={() => {
                                      setShowAttymPass(false);
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                                    style={{ top: "20px", right: "10px" }}
                                    onClick={() => {
                                      setShowAttymPass(true);
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )}
                                {errors.joint_password &&
                                touched.joint_password ? (
                                  <p className="text-danger">
                                    {errors.joint_password}
                                  </p>
                                ) : null}
                                {jointPasswordMessage && <p style={{marginTop:'4px',  fontSize: "14px", color: "green" }}>{jointPasswordMessage}</p>}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="client-head">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <h1>Completed courses</h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div
                          className="client-head"
                          style={{
                            backgroundColor: "transparent",
                            marginBottom: "20px",
                            marginTop: "-30px",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div class="row">
                                <div class="col-lg-6">
                                  <h1>Credit Counseling</h1>
                                </div>
                                {values.courseFirstEnrol && (
                                  <div class="col-lg-6">
                                    <div className="btn_wrap text-center">
                                      <button
                                        type="button"
                                        className="btn_brnd"
                                        disabled={cancelEnrollLoaderFirst}
                                        style={{ padding: "10px 18px" }}
                                        onClick={() => {
                                          setCancelEnrollData({
                                            // value:'first',
                                            id: values.id,
                                            cource: "first",
                                          });
                                          setIsModal(true);
                                          // handleUnEnroll(values.id,"first",setValues,values)
                                        }}
                                      >
                                        Cancel Enrollment
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="iagree_radio mb-4">
                          <div className="d-flex justify-content-between">
                            <div className="w-25">
                              <div className="iagree_radio">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="tickbox"
                                    checked={values.paid_first === "checked"}
                                    onChange={(e) => {
                                      setValues({
                                        ...values,
                                        paid_first: e.target.checked
                                          ? "checked"
                                          : "",
                                      });
                                    }}
                                    id="paid_first"
                                    className=" "
                                  />
                                  <label for="paid_first">Paid </label>
                                </div>
                                {errors.start && touched.start ? (
                                  <p className="text-danger">{errors.start}</p>
                                ) : null}
                              </div>
                            </div>
                            {values.courseFirstEnrol && (
                              <Link
                                to={`/budget-analysis-report?id=${userId}`}
                                style={{
                                  color: "#4e9c4b",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                  width: "55%",
                                }}
                              >
                                View Budget Analysis
                              </Link>
                            )}
                          </div>
                          {values.courseFirstEnrol ? (
                            <div className="mt-4 mb-4 flex">
                              <Link to={`/user-first-course?id=${userId}`}>
                                <button className="btn_brnd">
                                  Continue Course
                                </button>
                              </Link>
                              <Link
                                to={`/courseprogress?id=${userId}&course=first`}
                                className="ms-4"
                              >
                                <button type="button" className="btn_brnd">
                                  Course Progress
                                </button>
                              </Link>
                            </div>
                          ) : (
                            <div className="mt-4 mb-4 ">
                              <Link
                                to={`/enroll-user-first-course?id=${userId}`}
                              >
                                <button className="btn_brnd">Enroll Now</button>
                              </Link>
                            </div>
                          )}
                        </div>
                        {values.courseFirstEnrol && (
                          <div className="col-lg-12 fw-bold">
                            <h5 style={{ fontWeight: "600", fontSize: "17px" }}>
                              Main Filer
                              <span style={{ fontSize: "14px" }}>
                                {" "}
                                ({courseData.perFirstCourseMain.toFixed(0)}%
                                Completed)
                              </span>
                            </h5>
                          </div>
                        )}
                        <div className="col-lg-12">
                          {values.courseFirstEnrol &&
                            courseData?.courseFirstGenerated == "" && (
                              <button
                                type="button"
                                className="btn_brnd mb-2"
                                onClick={() => {
                                  if (values.judicialdistrict_first) {
                                    setGeneratingFirst(true);

                                    if (
                                      response.judicialdistrict_first !==
                                      values.judicialdistrict_first
                                    ) {
                                      setPostLoading(true);
                                      updateProfile(userId, values)
                                        .then((res) => {
                                          toast.success(
                                            "User updated successfully"
                                          );
                                          setPostLoading(false);
                                          generateCertificate(
                                            {
                                              courseMainId:
                                                courseData.courseFirstId,
                                              courseJointId:
                                                courseData.jointCourseFirstId,
                                              main: true,
                                              joint:
                                                courseData?.perFirstCourseJoint ===
                                                100,
                                              course: "first",
                                              email:
                                                response.deliverymethod ==
                                                "E-mail"
                                                  ? response.email
                                                  : "",
                                              attyemail: response.attypref
                                                ? response.attyemail
                                                : "",
                                            },
                                            false
                                          );
                                        })
                                        .catch((err) => {
                                          toast.error(err.response.data.error);
                                          setPostLoading(false);
                                        });
                                    } else {
                                      generateCertificate(
                                        {
                                          courseMainId:
                                            courseData.courseFirstId,
                                          courseJointId:
                                            courseData.jointCourseFirstId,
                                          course: "first",
                                          main: true,
                                          joint:
                                            courseData?.perFirstCourseJoint ===
                                            100,
                                          email:
                                            response.deliverymethod == "E-mail"
                                              ? response.email
                                              : "",
                                          attyemail: response.attypref
                                            ? response.attyemail
                                            : "",
                                        },
                                        false
                                      );
                                    }
                                  } else {
                                    toast.error(
                                      "Judicial District is required"
                                    );
                                  }
                                }}
                                disabled={generatingFirst}
                              >
                                {generatingFirst ? (
                                  <ThreeDots
                                    height="20"
                                    width="160"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Generate CC Certificate"
                                )}
                              </button>
                            )}
                            {
                              console.log("asfdasd",values.courseFirstEnrol,courseData)
                            }
                          {values.courseFirstEnrol &&
                            courseData?.courseFirstGenerated == "yes" &&
                            (courseData.courseFirstDownloaded &&
                            courseData.courseFirstDownloaded !== "yes" ? (
                              <a
                                className="text-inherit"
                                href={courseData.courseFirstDownloaded}
                                target="_blank"
                              >
                                <button
                                  className="btn_brnd mt-lg-2 mb-4 mb-lg-0"
                                  type="button"
                                >
                                  View CC Certificate
                                </button>
                              </a>
                            ) : (
                              <button
                                className="btn_brnd mt-lg-2 mb-4 mb-lg-0 tooltips"
                                type="button"
                              >
                                View CC Certificate
                              </button>
                            ))}
                        </div>
                        <div className="col-lg-12 mt-5">
                          {values.courseFirstEnrol &&
                            courseData?.courseFirstGenerated == "yes" && (
                              <div className=" input_wrap">
                                <label className="label-title">CC Date</label>
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  value={
                                    courseData?.courseFirstDate?.split(
                                      "T"
                                    )[0] ?? ""
                                  }
                                />
                                {errors.start && touched.start ? (
                                  <p className="text-danger">{errors.start}</p>
                                ) : null}
                              </div>
                            )}
                          {values.courseFirstEnrol && (
                            <div className="col-lg-12">
                              <div className=" input_wrap d-flex">
                                <label className="label-title">CC Cert</label>
                                <div className="file-uplaod form-control">
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => {
                                      setFirstPDF(e.target.files[0]);
                                    }}
                                  />
                                  {firstPDF ? (
                                    firstPDF.name
                                  ) : (
                                    <>
                                      {" "}
                                      <p>No file selected...</p>
                                      <span> Brows...</span>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4 w-100">
                                <div
                                  className="form-text"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <button
                                    className="btn_brnd"
                                    type="button"
                                    disabled={uploadingFirst || !firstPDF}
                                    onClick={() => {
                                      setUploadingFirst(true);
                                      uploadCertificate(
                                        "first",
                                        {
                                          courseId: courseData.courseFirstId,
                                          course: "first",
                                          email:
                                            response.deliverymethod == "E-mail"
                                              ? response.email
                                              : "",
                                          attyemail: response.attypref
                                            ? response.attyemail
                                            : "",
                                          file: firstPDF,
                                        },
                                        false
                                      );
                                    }}
                                  >
                                    {uploadingFirst ? (
                                      <ThreeDots
                                        height="20"
                                        width="150"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{
                                          display: "block",
                                        }}
                                        wrapperClassName="spinner"
                                        visible={true}
                                      />
                                    ) : (
                                      "Upload CC Certificate"
                                    )}
                                  </button>
                                  {values.courseFirstEnrol &&
                                    courseData?.courseFirstGenerated == "yes" &&
                                    courseData.courseFirstDownloaded &&
                                    courseData.courseFirstDownloaded !==
                                      "yes" && (
                                      <button
                                        className="btn_brnd mb-4 mb-lg-0 ms-4"
                                        type="button"
                                        disabled={resendingFirst}
                                        onClick={() => {
                                          setResendingFirst(true);
                                          resendCertificate(
                                            {
                                              firstName: values.firstname,
                                              lastName: values.lastname,
                                              link: courseData.courseFirstDownloaded,
                                              email: values.email,
                                            },
                                            setResendingFirst
                                          );
                                        }}
                                      >
                                        {resendingFirst ? (
                                          <ThreeDots
                                            height="20"
                                            width="60"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            wrapperClassName="spinner"
                                            visible={true}
                                          />
                                        ) : (
                                          "Resend"
                                        )}
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {values.joint_username && (
                          <>
                            {values.courseFirstEnrol && (
                              <div className="col-lg-12 fw-bold">
                                <h5
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "17px",
                                  }}
                                >
                                  Joint Filer{" "}
                                  <span style={{ fontSize: "14px" }}>
                                    {" "}
                                    (
                                    {courseData.perFirstCourseJoint &&
                                      courseData.perFirstCourseJoint.toFixed(0)}
                                    % Completed)
                                  </span>
                                </h5>
                              </div>
                            )}
                            <div className="col-lg-12">
                              {values.courseFirstEnrol &&
                                courseData.jointCourseFirstGenerated == "" && (
                                  <button
                                    type="button"
                                    className="btn_brnd"
                                    onClick={() => {
                                      if (values.judicialdistrict_first) {
                                        setGeneratingFirstJoint(true);

                                        if (
                                          response.judicialdistrict_first !==
                                          values.judicialdistrict_first
                                        ) {
                                          setPostLoading(true);
                                          updateProfile(userId, values)
                                            .then((res) => {
                                              toast.success(
                                                "User updated successfully"
                                              );
                                              setPostLoading(false);
                                              generateCertificate(
                                                {
                                                  courseMainId:
                                                    courseData.courseFirstId,
                                                  courseJointId:
                                                    courseData.jointCourseFirstId,
                                                  main:
                                                    courseData.perFirstCourseMain ===
                                                    100,
                                                  joint: true,
                                                  course: "first",
                                                  email:
                                                    response.deliverymethod ==
                                                    "E-mail"
                                                      ? response.email
                                                      : "",
                                                  attyemail: response.attypref
                                                    ? response.attyemail
                                                    : "",
                                                },
                                                true
                                              );
                                            })
                                            .catch((err) => {
                                              toast.error(
                                                err.response.data.error
                                              );
                                              setPostLoading(false);
                                            });
                                        } else {
                                          generateCertificate(
                                            {
                                              courseMainId:
                                                courseData.courseFirstId,
                                              courseJointId:
                                                courseData.jointCourseFirstId,
                                              course: "first",
                                              main:
                                                courseData.perFirstCourseMain ===
                                                100,
                                              joint: true,
                                              email:
                                                response.deliverymethod ==
                                                "E-mail"
                                                  ? response.email
                                                  : "",
                                              attyemail: response.attypref
                                                ? response.attyemail
                                                : "",
                                            },
                                            true
                                          );
                                        }
                                      } else {
                                        toast.error(
                                          "Judicial District is required"
                                        );
                                      }
                                    }}
                                    disabled={generatingFirstJoint}
                                  >
                                    {generatingFirstJoint ? (
                                      <ThreeDots
                                        height="20"
                                        width="160"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{
                                          display: "block",
                                        }}
                                        wrapperClassName="spinner"
                                        visible={true}
                                      />
                                    ) : (
                                      "Generate CC Certificate"
                                    )}
                                  </button>
                                )}
                              {values.courseFirstEnrol &&
                                courseData.jointCourseFirstGenerated == "yes" &&
                                (courseData.jointCourseFirstDownloaded &&
                                courseData.jointCourseFirstDownloaded !==
                                  "yes" ? (
                                  <a
                                    className="text-inherit"
                                    href={courseData.jointCourseFirstDownloaded}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn_brnd mt-lg-2 mb-4 mb-lg-0"
                                      type="button"
                                    >
                                      View CC Certificate
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    className="btn_brnd mt-lg-2 mb-4 mb-lg-0 tooltips"
                                    type="button"
                                  >
                                    View CC Certificate
                                  </button>
                                ))}
                            </div>
                            <div className="col-lg-12 mt-5">
                              {values.courseFirstEnrol &&
                                courseData.jointCourseFirstGenerated ==
                                  "yes" && (
                                  <div className=" input_wrap">
                                    <label className="label-title">
                                      CC Date
                                    </label>
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      value={
                                        courseData?.jointCourseFirstDate?.split(
                                          "T"
                                        )[0] ?? ""
                                      }
                                    />
                                    {errors.start && touched.start ? (
                                      <p className="text-danger">
                                        {errors.start}
                                      </p>
                                    ) : null}
                                  </div>
                                )}
                              {values.courseFirstEnrol && (
                                <div className="col-lg-12">
                                  <div className=" input_wrap d-flex">
                                    <label className="label-title">
                                      CC Cert
                                    </label>
                                    <div className="file-uplaod form-control">
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => {
                                          setFirstPDFJoint(e.target.files[0]);
                                        }}
                                      />
                                      {firstPDFJoint ? (
                                        firstPDFJoint.name
                                      ) : (
                                        <>
                                          {" "}
                                          <p>No file selected...</p>
                                          <span> Brows...</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-4 w-100">
                                    <div
                                      className="form-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <button
                                        className="btn_brnd"
                                        type="button"
                                        disabled={
                                          uploadingFirstJoint || !firstPDFJoint
                                        }
                                        onClick={() => {
                                          setUploadingFirstJoint(true);
                                          uploadCertificate(
                                            "first",
                                            {
                                              courseId:
                                                courseData.jointCourseFirstId,
                                              course: "first",
                                              email:
                                                response.deliverymethod ==
                                                "E-mail"
                                                  ? response.email
                                                  : "",
                                              attyemail: response.attypref
                                                ? response.attyemail
                                                : "",
                                              file: firstPDFJoint,
                                            },
                                            true
                                          );
                                        }}
                                      >
                                        {uploadingFirstJoint ? (
                                          <ThreeDots
                                            height="20"
                                            width="150"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            wrapperClassName="spinner"
                                            visible={true}
                                          />
                                        ) : (
                                          "Upload CC Certificate"
                                        )}
                                      </button>
                                      {values.courseFirstEnrol &&
                                        courseData.jointCourseFirstGenerated ==
                                          "yes" &&
                                        courseData.jointCourseFirstDownloaded &&
                                        courseData.jointCourseFirstDownloaded !==
                                          "yes" && (
                                          <button
                                            className="btn_brnd mb-4 mb-lg-0 ms-4"
                                            type="button"
                                            disabled={resendingFirstJoint}
                                            onClick={() => {
                                              setResendingFirstJoint(true);
                                              resendCertificate(
                                                {
                                                  firstName:
                                                    values.joint_firstname,
                                                  lastName:
                                                    values.joint_lastname,
                                                  link: courseData.jointCourseFirstDownloaded,
                                                  email: values.email,
                                                },
                                                setResendingFirstJoint
                                              );
                                            }}
                                          >
                                            {resendingFirstJoint ? (
                                              <ThreeDots
                                                height="20"
                                                width="60"
                                                radius="9"
                                                color="#4fa94d"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{
                                                  display: "block",
                                                }}
                                                wrapperClassName="spinner"
                                                visible={true}
                                              />
                                            ) : (
                                              "Resend"
                                            )}
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div
                          className="client-head"
                          style={{
                            backgroundColor: "transparent",
                            marginBottom: "20px",
                            marginTop: "-30px",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div class="row">
                                <div class="col-lg-5">
                                  <h1>Debtor Education</h1>
                                </div>
                                {values.courseSecondEnrol && (
                                  <div class="col-lg-7">
                                    <div className="btn_wrap text-center">
                                      <button
                                        type="button"
                                        className=" btn_brnd  mt-20"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#nonSerious"

                                        disabled={cancelEnrollLoader}
                                        style={{ padding: "10px 18px" }}
                                        // onClick={()=>handleUnEnroll(values.id,"second",setValues,values)}
                                        onClick={() => {
                                          setCancelEnrollData({
                                            // value:'first',
                                            id: values.id,
                                            cource: "second",
                                          });
                                          console.log("values.id", values.id);

                                          setIsModal(true);
                                          // handleUnEnroll(values.id,"first",setValues,values)
                                        }}
                                      >
                                        Cancel Enrollment
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="iagree_radio mb-4">
                          <div className="col-lg-6">
                            <div className="iagree_radio mb-3">
                              <div>
                                <input
                                  type="checkbox"
                                  name="tickbox"
                                  checked={values.paid_second === "checked"}
                                  onChange={(e) => {
                                    setValues({
                                      ...values,
                                      paid_second: e.target.checked
                                        ? "checked"
                                        : "",
                                    });
                                  }}
                                  id="paid_second"
                                  className=" "
                                />
                                <label for="paid_second">Paid </label>
                              </div>
                              {errors.start && touched.start ? (
                                <p className="text-danger">{errors.start}</p>
                              ) : null}
                            </div>
                            {/* <div className="iagree_radio mb-3">
                            <div>
                              <input
                                type="checkbox"
                                name="tickbox"
                                id=""
                                className=" "
                              />
                              <label for="">Taken by phone: </label>
                            </div>
                            {errors.start && touched.start ? (
                              <p className="text-danger">{errors.start}</p>
                            ) : null}
                          </div> */}
                          </div>

                          {values.courseSecondEnrol ? (
                            <div className="mt-4 mb-4">
                              <Link to={`/user-second-course?id=${userId}`}>
                                <button className="btn_brnd">
                                  Continue Course
                                </button>
                              </Link>
                              <Link
                                to={`/courseprogress?id=${userId}&course=second`}
                                className="ms-4"
                              >
                                <button type="button" className="btn_brnd">
                                  Course Progress
                                </button>
                              </Link>
                            </div>
                          ) : (
                            <div className="mt-4 mb-4 ">
                              <Link
                                to={`/enroll-user-second-course?id=${userId}`}
                              >
                                <button className="btn_brnd">Enroll Now</button>
                              </Link>
                            </div>
                          )}
                        </div>

                        {values.courseSecondEnrol && (
                          <div className="col-lg-12 fw-bold">
                            <h5 style={{ fontWeight: "600", fontSize: "17px" }}>
                              Main Filer{" "}
                              <span style={{ fontSize: "14px" }}>
                                {" "}
                                ({courseData.perSecondCourseMain.toFixed(0)}%
                                Completed)
                              </span>
                            </h5>
                          </div>
                        )}
                        <div className="col-lg-6">
                          {values.courseSecondEnrol &&
                            courseData.generated == "" && (
                              <button
                                className="btn_brnd"
                                type="button"
                                onClick={() => {
                                  if (values.judicialdistrict_second) {
                                    setGeneratingSecond(true);

                                    if (
                                      response.judicialdistrict_second !==
                                      values.judicialdistrict_second
                                    ) {
                                      setPostLoading(true);
                                      updateProfile(userId, values)
                                        .then((res) => {
                                          toast.success(
                                            "User updated successfully"
                                          );
                                          setPostLoading(false);
                                          generateCertificateSecond({
                                            sendEmail: courseData.sendEmail,
                                            courseId: courseData.courseSecondId,
                                            jointCourseId:
                                              courseData.jointCourseSecondId,
                                            course: "second",
                                            bankruptcycasenum:
                                              values.bankruptcycasenum,
                                            email:
                                              response.deliverymethod ==
                                              "E-mail"
                                                ? response.email
                                                : "",
                                            attyemail: response.attypref
                                              ? response.attyemail
                                              : "",
                                          });
                                        })
                                        .catch((err) => {
                                          toast.error(err.response.data.error);
                                          setPostLoading(false);
                                        });
                                    } else {
                                      generateCertificateSecond({
                                        sendEmail: courseData.sendEmail,
                                        courseId: courseData.courseSecondId,
                                        jointCourseId:
                                          courseData.jointCourseSecondId,
                                        course: "second",
                                        bankruptcycasenum:
                                          values.bankruptcycasenum,
                                        email:
                                          response.deliverymethod == "E-mail"
                                            ? response.email
                                            : "",
                                        attyemail: response.attypref
                                          ? response.attyemail
                                          : "",
                                      });
                                    }
                                  } else {
                                    toast.error(
                                      "Judicial District is required"
                                    );
                                  }
                                }}
                                disabled={generatingSecond}
                              >
                                {generatingSecond ? (
                                  <ThreeDots
                                    height="20"
                                    width="160"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Generate DE Certificate"
                                )}
                              </button>
                            )}
                          {values.courseSecondEnrol &&
                            courseData.generated == "yes" &&
                            (courseData.downloaded &&
                            courseData.downloaded !== "yes" ? (
                              <a href={courseData.downloaded} target="_blank">
                                <button
                                  className="btn_brnd mt-lg-2 mb-4 mb-lg-0"
                                  type="button"
                                >
                                  View DE Certificate
                                </button>
                              </a>
                            ) : (
                              <button
                                className="btn_brnd mt-lg-2 mb-4 mb-lg-0 tooltips"
                                type="button"
                              >
                                View DE Certificate
                              </button>
                            ))}
                        </div>
                        <div className="col-lg-12 mt-5">
                          {values.courseSecondEnrol && courseData.generated && (
                            <div className=" input_wrap">
                              <label className="label-title">DE Date</label>
                              <input
                                type="text"
                                readOnly
                                className="form-control"
                                value={courseData?.date?.split("T")[0] ?? ""}
                              />
                              {errors.start && touched.start ? (
                                <p className="text-danger">{errors.start}</p>
                              ) : null}
                            </div>
                          )}
                          {values.courseSecondEnrol && (
                            <div className="col-lg-12">
                              <div className=" input_wrap d-flex">
                                <label className="label-title">DE Cert</label>
                                <div className="file-uplaod form-control">
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => {
                                      setSecondPDF(e.target.files[0]);
                                    }}
                                  />
                                  {secondPDF ? (
                                    secondPDF.name
                                  ) : (
                                    <>
                                      {" "}
                                      <p>No file selected...</p>
                                      <span> Brows...</span>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4 w-100">
                                <div
                                  className="form-text"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <button
                                    className="btn_brnd"
                                    type="button"
                                    disabled={uploadingSecond || !secondPDF}
                                    onClick={() => {
                                      setUploadingSecond(true);
                                      uploadCertificate(
                                        "second",
                                        {
                                          courseId: courseData.courseSecondId,
                                          course: "second",
                                          email:
                                            response.deliverymethod == "E-mail"
                                              ? response.email
                                              : "",
                                          attyemail: response.attypref
                                            ? response.attyemail
                                            : "",
                                          file: secondPDF,
                                        },
                                        false
                                      );
                                    }}
                                  >
                                    {uploadingSecond ? (
                                      <ThreeDots
                                        height="20"
                                        width="150"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{
                                          display: "block",
                                        }}
                                        wrapperClassName="spinner"
                                        visible={true}
                                      />
                                    ) : (
                                      "Upload DE Certificate"
                                    )}
                                  </button>

                                  {values.courseSecondEnrol &&
                                    courseData.generated == "yes" &&
                                    courseData.downloaded &&
                                    courseData.downloaded !== "yes" && (
                                      <button
                                        className="btn_brnd mb-4 mb-lg-0 ms-4"
                                        type="button"
                                        disabled={resendingSecond}
                                        onClick={() => {
                                          setResendingSecond(true);
                                          resendCertificate(
                                            {
                                              firstName: values.firstname,
                                              lastName: values.lastname,
                                              link: courseData.downloaded,
                                              email: values.email,
                                            },
                                            setResendingSecond
                                          );
                                        }}
                                      >
                                        {resendingSecond ? (
                                          <ThreeDots
                                            height="20"
                                            width="60"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            wrapperClassName="spinner"
                                            visible={true}
                                          />
                                        ) : (
                                          "Resend"
                                        )}
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {values.joint_username && (
                          <>
                            {values.courseSecondEnrol && (
                              <div className="col-lg-12 fw-bold">
                                <h5
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "17px",
                                  }}
                                >
                                  Joint Filer{" "}
                                  <span style={{ fontSize: "14px" }}>
                                    {" "}
                                    (
                                    {courseData.perSecondCourseJoint &&
                                      courseData.perSecondCourseJoint.toFixed(
                                        0
                                      )}
                                    % Completed)
                                  </span>
                                </h5>
                              </div>
                            )}
                            <div className="col-lg-6">
                              {values.courseSecondEnrol &&
                                courseData.jointCourseSecondGenerated == "" && (
                                  <button
                                    className="btn_brnd"
                                    type="button"
                                    onClick={() => {
                                      if (values.judicialdistrict_second) {
                                        setGeneratingSecondJoint(true);

                                        if (
                                          response.judicialdistrict_second !==
                                          values.judicialdistrict_second
                                        ) {
                                          setPostLoading(true);
                                          updateProfile(userId, values)
                                            .then((res) => {
                                              toast.success(
                                                "User updated successfully"
                                              );
                                              setPostLoading(false);
                                              generateCertificateSecond({
                                                sendEmail: courseData.sendEmail,
                                                courseId:
                                                  courseData.courseSecondId,
                                                jointCourseId:
                                                  courseData.jointCourseSecondId,
                                                course: "second",
                                                bankruptcycasenum:
                                                  values.bankruptcycasenum,
                                                email:
                                                  response.deliverymethod ==
                                                  "E-mail"
                                                    ? response.email
                                                    : "",
                                                attyemail: response.attypref
                                                  ? response.attyemail
                                                  : "",
                                              });
                                            })
                                            .catch((err) => {
                                              toast.error(
                                                err.response.data.error
                                              );
                                              setPostLoading(false);
                                            });
                                        } else {
                                          generateCertificateSecond({
                                            sendEmail: courseData.sendEmail,
                                            courseId: courseData.courseSecondId,
                                            jointCourseId:
                                              courseData.jointCourseSecondId,
                                            course: "second",
                                            bankruptcycasenum:
                                              values.bankruptcycasenum,
                                            email:
                                              response.deliverymethod ==
                                              "E-mail"
                                                ? response.email
                                                : "",
                                            attyemail: response.attypref
                                              ? response.attyemail
                                              : "",
                                          });
                                        }
                                      } else {
                                        toast.error(
                                          "Judicial District is required"
                                        );
                                      }
                                    }}
                                    disabled={generatingSecondJoint}
                                  >
                                    {generatingSecondJoint ? (
                                      <ThreeDots
                                        height="20"
                                        width="160"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{
                                          display: "block",
                                        }}
                                        wrapperClassName="spinner"
                                        visible={true}
                                      />
                                    ) : (
                                      "Generate DE Certificate"
                                    )}
                                  </button>
                                )}
                              {values.courseSecondEnrol &&
                                courseData.jointCourseSecondGenerated ==
                                  "yes" &&
                                (courseData.jointCourseSecondDownloaded &&
                                courseData.jointCourseSecondDownloaded !==
                                  "yes" ? (
                                  <a
                                    href={
                                      courseData.jointCourseSecondDownloaded
                                    }
                                    target="_blank"
                                  >
                                    <button
                                      className="btn_brnd mt-lg-2 mb-4 mb-lg-0"
                                      type="button"
                                    >
                                      View DE Certificate
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    className="btn_brnd mt-lg-2 mb-4 mb-lg-0 tooltips"
                                    type="button"
                                  >
                                    View DE Certificate
                                  </button>
                                ))}
                            </div>
                            <div className="col-lg-12 mt-5">
                              {values.courseSecondEnrol &&
                                courseData.jointCourseSecondGenerated && (
                                  <div className=" input_wrap">
                                    <label className="label-title">
                                      DE Date
                                    </label>
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      value={
                                        courseData?.jointCourseSecondDate?.split(
                                          "T"
                                        )[0] ?? ""
                                      }
                                    />
                                    {errors.start && touched.start ? (
                                      <p className="text-danger">
                                        {errors.start}
                                      </p>
                                    ) : null}
                                  </div>
                                )}
                              {values.courseSecondEnrol && (
                                <div className="col-lg-12">
                                  <div className=" input_wrap d-flex">
                                    <label className="label-title">
                                      DE Cert
                                    </label>
                                    <div className="file-uplaod form-control">
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => {
                                          setSecondPDFJoint(e.target.files[0]);
                                        }}
                                      />
                                      {secondPDFJoint ? (
                                        secondPDFJoint.name
                                      ) : (
                                        <>
                                          {" "}
                                          <p>No file selected...</p>
                                          <span> Brows...</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-4 w-100">
                                    <div
                                      className="form-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <button
                                        className="btn_brnd"
                                        type="button"
                                        disabled={
                                          uploadingSecondJoint ||
                                          !secondPDFJoint
                                        }
                                        onClick={() => {
                                          setUploadingSecondJoint(true);
                                          uploadCertificate(
                                            "second",
                                            {
                                              courseId:
                                                courseData.jointCourseSecondId,
                                              course: "second",
                                              email:
                                                response.deliverymethod ==
                                                "E-mail"
                                                  ? response.email
                                                  : "",
                                              attyemail: response.attypref
                                                ? response.attyemail
                                                : "",
                                              file: secondPDFJoint,
                                            },
                                            true
                                          );
                                        }}
                                      >
                                        {uploadingSecondJoint ? (
                                          <ThreeDots
                                            height="20"
                                            width="150"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            wrapperClassName="spinner"
                                            visible={true}
                                          />
                                        ) : (
                                          "Upload DE Certificate"
                                        )}
                                      </button>

                                      {values.courseSecondEnrol &&
                                        courseData.jointCourseSecondGenerated ==
                                          "yes" &&
                                        courseData.jointCourseSecondDownloaded &&
                                        courseData.jointCourseSecondDownloaded !==
                                          "yes" && (
                                          <button
                                            className="btn_brnd mb-4 mb-lg-0 ms-4"
                                            type="button"
                                            disabled={resendingSecondJoint}
                                            onClick={() => {
                                              setResendingSecondJoint(true);
                                              resendCertificate(
                                                {
                                                  firstName:
                                                    values.joint_firstname,
                                                  lastName:
                                                    values.joint_lastname,
                                                  link: courseData.jointCourseSecondDownloaded,
                                                  email: values.email,
                                                },
                                                setResendingSecondJoint
                                              );
                                            }}
                                          >
                                            {resendingSecondJoint ? (
                                              <ThreeDots
                                                height="20"
                                                width="60"
                                                radius="9"
                                                color="#4fa94d"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{
                                                  display: "block",
                                                }}
                                                wrapperClassName="spinner"
                                                visible={true}
                                              />
                                            ) : (
                                              "Resend"
                                            )}
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {/* <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-hidden="true"
      className={`modal ${isModal !== "" ? "show" : ""} fade custom_modal `}
      style={{
        display: isModal !== "" ? "block" : "none",
        background: "rgba(0,0,0,0.5)",
      }}
    
              >
                <div className="modal-dialog">
                  <div className="modal-content pb-60">
                    <button
                      type="button"
                      className="btn_close"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setIsModal(false);
                      }}
                    >
                      <img src={cross} alt="cross" className="cross_btn" />
                    </button>
                    <div className="modal-body">
                      <div className="modal_content">
                        <p className="f-16 text-center mt-12 fw-700">
                          Are You sure!
                        </p>

                        <div className="btn_wrap text-center mt-40">
                          <button
                            className="btn_brnd-outline mr-22 mb-2"
                            data-bs-dismiss="modal"
                            type="button"
                            onClick={() => {
                              setIsModal(false);
                            }}
                          >
                           Cancel
                          </button>

                          <button
                            className="btn_brnd"
                            // data-bs-toggle="modal"
                            // data-bs-target="#Serious"
                             type="button"
                            onClick={()=>handleUnEnroll(setValues,values)}
                          >
                            {cancelEnrollLoader || cancelEnrollLoaderFirst? (
                  <ThreeDots
                    height="20"
                    width="150"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Confirm"
                )}
                             
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                    {isModal && (
                      <div
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-hidden="true"
                        className={`modal ${
                          isModal !== "" ? "show" : ""
                        } fade custom_modal `}
                        style={{
                          display: isModal !== "" ? "block" : "none",
                          background: "rgba(0,0,0,0.5)",
                        }}
                      >
                        <div className="modal-dialog">
                          <div
                            className="modal-content"
                            style={{ maxWidth: "600px", margin: "0px auto" }}
                          >
                            <button
                              type="button"
                              className="btn_close"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                setIsModal(false);
                              }}
                              disabled={
                                cancelEnrollLoader || cancelEnrollLoaderFirst
                              }
                            >
                              <img
                                src={cross}
                                alt="cross"
                                className="cross_btn"
                              />
                            </button>
                            <div className="modal-body">
                              <div className="modal_content">
                                <h1 className="f-26 text-center">
                                  Are you{" "}
                                  <span className="text_success">sure</span> ?
                                </h1>
                                <p className="f-16 text-center mt-12">
                                  Do you really want to cancel Enrollment?
                                </p>
                                <div className="btn_wrap text-center">
                                  <button
                                    className="btn_brnd"
                                    type="button"
                                    onClick={() => {
                                      setIsModal(false);
                                    }}
                                    disabled={
                                      cancelEnrollLoader ||
                                      cancelEnrollLoaderFirst
                                    }
                                    style={{ marginRight: "1rem" }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className={`btn_brnd btn_delete `}
                                    // disabled={loading}
                                    type="button"
                                    onClick={() =>
                                      handleUnEnroll(setValues, values)
                                    }
                                  >
                                    {cancelEnrollLoader ||
                                    cancelEnrollLoaderFirst ? (
                                      <ThreeDots
                                        height="20"
                                        width="150"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{
                                          display: "block",
                                        }}
                                        wrapperClassName="spinner"
                                        visible={true}
                                      />
                                    ) : (
                                      "Confirm"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="client-head">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <h1>Re-Take courses</h1>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div
                          className="client-head"
                          style={{
                            backgroundColor: "transparent",
                            marginBottom: "20px",
                            marginTop: "-30px",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div class="row">
                                <div class="col-lg-6">
                                  <h1>Credit Counseling</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {values.course_first_retake !== "1" ? (
                          <div className="col-lg-6 mb-20">
                            <button
                              className="btn_brnd"
                              type="button"
                              onClick={() => {
                                setReTakingCC(true);
                                reTakeCC();
                              }}
                              disabled={reTakingCC}
                            >
                              {reTakingCC ? (
                                <ThreeDots
                                  height="20"
                                  width="100"
                                  radius="9"
                                  color="#4fa94d"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{
                                    display: "block",
                                  }}
                                  wrapperClassName="spinner"
                                  visible={true}
                                />
                              ) : (
                                "Re-Take"
                              )}
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="iagree_radio mb-4">
                              {values.courseFirstEnrol ? (
                                <div className="mt-4 mb-4 flex">
                                  <Link
                                    to={`/user-first-course?id=${userId}&retake=true`}
                                  >
                                    <button className="btn_brnd">
                                      Continue Course
                                    </button>
                                  </Link>
                                  <Link
                                    to={`/courseprogress?id=${userId}&course=first&retake=true`}
                                    className="ms-4"
                                  >
                                    <button type="button" className="btn_brnd">
                                      Course Progress
                                    </button>
                                  </Link>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {values.courseFirstEnrol && (
                              <div className="col-lg-12 fw-bold">
                                <h5
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "17px",
                                  }}
                                >
                                  Main Filer
                                  <span style={{ fontSize: "14px" }}>
                                    {" "}
                                    (
                                    {retakecourseData?.perFirstCourseMain?.toFixed(
                                      0
                                    )}
                                    % Completed)
                                  </span>
                                </h5>
                              </div>
                            )}
                            <div className="col-lg-12">
                              {values.courseFirstEnrol &&
                                retakecourseData?.courseFirstGenerated == "" && (
                                  <button
                                    type="button"
                                    className="btn_brnd mb-2"
                                    onClick={() => {
                                      if (values.judicialdistrict_first) {
                                        setGeneratingFirstRetake(true);

                                        if (
                                          response.judicialdistrict_first !==
                                          values.judicialdistrict_first
                                        ) {
                                          setPostLoading(true);
                                          updateProfile(userId, values)
                                            .then((res) => {
                                              toast.success(
                                                "User updated successfully"
                                              );
                                              setPostLoading(false);
                                              generateCertificateRetake(
                                                {
                                                  courseMainId:
                                                    retakecourseData?.courseFirstId,
                                                  courseJointId:
                                                    retakecourseData?.jointCourseFirstId,
                                                  main: true,
                                                  joint:
                                                    retakecourseData?.perFirstCourseJoint ===
                                                    100,
                                                  course: "first",
                                                  email:
                                                    response.deliverymethod ==
                                                    "E-mail"
                                                      ? response.email
                                                      : "",
                                                  attyemail: response.attypref
                                                    ? response.attyemail
                                                    : "",
                                                },
                                                false
                                              );
                                            })
                                            .catch((err) => {
                                              toast.error(
                                                err.response.data.error
                                              );
                                              setPostLoading(false);
                                            });
                                        } else {
                                          generateCertificateRetake(
                                            {
                                              courseMainId:
                                                retakecourseData?.courseFirstId,
                                              courseJointId:
                                                retakecourseData?.jointCourseFirstId,
                                              course: "first",
                                              main: true,
                                              joint:
                                                retakecourseData?.perFirstCourseJoint ===
                                                100,
                                              email:
                                                response.deliverymethod ==
                                                "E-mail"
                                                  ? response.email
                                                  : "",
                                              attyemail: response.attypref
                                                ? response.attyemail
                                                : "",
                                            },
                                            false
                                          );
                                        }
                                      } else {
                                        toast.error(
                                          "Judicial District is required"
                                        );
                                      }
                                    }}
                                    disabled={generatingFirstRetake}
                                  >
                                    {generatingFirstRetake ? (
                                      <ThreeDots
                                        height="20"
                                        width="160"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{
                                          display: "block",
                                        }}
                                        wrapperClassName="spinner"
                                        visible={true}
                                      />
                                    ) : (
                                      "Generate CC Certificate"
                                    )}
                                  </button>
                                )}
                              {values.courseFirstEnrol &&
                                retakecourseData?.courseFirstGenerated ==
                                  "yes" &&
                                (retakecourseData?.courseFirstDownloaded &&
                                retakecourseData?.courseFirstDownloaded !==
                                  "yes" ? (
                                  <a
                                    className="text-inherit"
                                    href={
                                      retakecourseData?.courseFirstDownloaded
                                    }
                                    target="_blank"
                                  >
                                    <button
                                      className="btn_brnd mt-lg-2 mb-4 mb-lg-0"
                                      type="button"
                                    >
                                      View CC Certificate
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    className="btn_brnd mt-lg-2 mb-4 mb-lg-0 tooltips"
                                    type="button"
                                  >
                                    View CC Certificate
                                  </button>
                                ))}
                            </div>
                            <div className="col-lg-12 mt-5">
                              {values.courseFirstEnrol &&
                                retakecourseData?.courseFirstGenerated ==
                                  "yes" && (
                                  <div className=" input_wrap">
                                    <label className="label-title">
                                      CC Date
                                    </label>
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      value={
                                        retakecourseData?.courseFirstDate?.split(
                                          "T"
                                        )[0] ?? ""
                                      }
                                    />
                                    {errors.start && touched.start ? (
                                      <p className="text-danger">
                                        {errors.start}
                                      </p>
                                    ) : null}
                                  </div>
                                )}
                              {values.courseFirstEnrol && (
                                <div className="col-lg-12">
                                  <div className=" input_wrap d-flex">
                                    <label className="label-title">
                                      CC Cert
                                    </label>
                                    <div className="file-uplaod form-control">
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => {
                                          setFirstPDFRetake(e.target.files[0]);
                                        }}
                                      />
                                      {firstPDFRetake ? (
                                        firstPDFRetake.name
                                      ) : (
                                        <>
                                          {" "}
                                          <p>No file selected...</p>
                                          <span> Brows...</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-4 w-100">
                                    <div
                                      className="form-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <button
                                        className="btn_brnd"
                                        type="button"
                                        disabled={
                                          uploadingFirstRetake ||
                                          !firstPDFRetake
                                        }
                                        onClick={() => {
                                          setUploadingFirstRetake(true);
                                          uploadCertificateRetake(
                                            "first",
                                            {
                                              courseId:
                                                retakecourseData?.courseFirstId,
                                              course: "first",
                                              email:
                                                response.deliverymethod ==
                                                "E-mail"
                                                  ? response.email
                                                  : "",
                                              attyemail: response.attypref
                                                ? response.attyemail
                                                : "",
                                              file: firstPDFRetake,
                                            },
                                            false
                                          );
                                        }}
                                      >
                                        {uploadingFirstRetake ? (
                                          <ThreeDots
                                            height="20"
                                            width="150"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            wrapperClassName="spinner"
                                            visible={true}
                                          />
                                        ) : (
                                          "Upload CC Certificate"
                                        )}
                                      </button>
                                      {values.courseFirstEnrol &&
                                        retakecourseData?.courseFirstGenerated ==
                                          "yes" &&
                                        retakecourseData?.courseFirstDownloaded &&
                                        retakecourseData?.courseFirstDownloaded !==
                                          "yes" && (
                                          <button
                                            className="btn_brnd mb-4 mb-lg-0 ms-4"
                                            type="button"
                                            disabled={resendingFirstRetake}
                                            onClick={() => {
                                              setResendingFirstRetake(true);
                                              resendCertificateRetake(
                                                {
                                                  firstName: values.firstname,
                                                  lastName: values.lastname,
                                                  link: retakecourseData?.courseFirstDownloaded,
                                                  email: values.email,
                                                },
                                                setResendingFirstRetake
                                              );
                                            }}
                                          >
                                            {resendingFirstRetake ? (
                                              <ThreeDots
                                                height="20"
                                                width="60"
                                                radius="9"
                                                color="#4fa94d"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{
                                                  display: "block",
                                                }}
                                                wrapperClassName="spinner"
                                                visible={true}
                                              />
                                            ) : (
                                              "Resend"
                                            )}
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            {values.joint_username && (
                              <>
                                {values.courseFirstEnrol && (
                                  <div className="col-lg-12 fw-bold">
                                    <h5
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "17px",
                                      }}
                                    >
                                      Joint Filer{" "}
                                      <span style={{ fontSize: "14px" }}>
                                        {" "}
                                        (
                                        {retakecourseData?.perFirstCourseJoint &&
                                          retakecourseData?.perFirstCourseJoint.toFixed(
                                            0
                                          )}
                                        % Completed)
                                      </span>
                                    </h5>
                                  </div>
                                )}
                                <div className="col-lg-12">
                                  {values.courseFirstEnrol &&
                                    retakecourseData?.jointCourseFirstGenerated ==
                                      "" && (
                                      <button
                                        type="button"
                                        className="btn_brnd"
                                        onClick={() => {
                                          if (values.judicialdistrict_first) {
                                            setGeneratingFirstJointRetake(true);

                                            if (
                                              response.judicialdistrict_first !==
                                              values.judicialdistrict_first
                                            ) {
                                              setPostLoading(true);
                                              updateProfile(userId, values)
                                                .then((res) => {
                                                  toast.success(
                                                    "User updated successfully"
                                                  );
                                                  setPostLoading(false);
                                                  generateCertificateRetake(
                                                    {
                                                      courseMainId:
                                                        retakecourseData?.courseFirstId,
                                                      courseJointId:
                                                        retakecourseData?.jointCourseFirstId,
                                                      main:
                                                        retakecourseData?.perFirstCourseMain ===
                                                        100,
                                                      joint: true,
                                                      course: "first",
                                                      email:
                                                        response.deliverymethod ==
                                                        "E-mail"
                                                          ? response.email
                                                          : "",
                                                      attyemail:
                                                        response.attypref
                                                          ? response.attyemail
                                                          : "",
                                                    },
                                                    true
                                                  );
                                                })
                                                .catch((err) => {
                                                  toast.error(
                                                    err.response.data.error
                                                  );
                                                  setPostLoading(false);
                                                });
                                            } else {
                                              generateCertificateRetake(
                                                {
                                                  courseMainId:
                                                    retakecourseData?.courseFirstId,
                                                  courseJointId:
                                                    retakecourseData?.jointCourseFirstId,
                                                  course: "first",
                                                  main:
                                                    retakecourseData?.perFirstCourseMain ===
                                                    100,
                                                  joint: true,
                                                  email:
                                                    response.deliverymethod ==
                                                    "E-mail"
                                                      ? response.email
                                                      : "",
                                                  attyemail: response.attypref
                                                    ? response.attyemail
                                                    : "",
                                                },
                                                true
                                              );
                                            }
                                          } else {
                                            toast.error(
                                              "Judicial District is required"
                                            );
                                          }
                                        }}
                                        disabled={generatingFirstJointRetake}
                                      >
                                        {generatingFirstJointRetake ? (
                                          <ThreeDots
                                            height="20"
                                            width="160"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            wrapperClassName="spinner"
                                            visible={true}
                                          />
                                        ) : (
                                          "Generate CC Certificate"
                                        )}
                                      </button>
                                    )}
                                  {values.courseFirstEnrol &&
                                    retakecourseData?.jointCourseFirstGenerated ==
                                      "yes" &&
                                    (retakecourseData?.jointCourseFirstDownloaded &&
                                    retakecourseData?.jointCourseFirstDownloaded !==
                                      "yes" ? (
                                      <a
                                        className="text-inherit"
                                        href={
                                          retakecourseData?.jointCourseFirstDownloaded
                                        }
                                        target="_blank"
                                      >
                                        <button
                                          className="btn_brnd mt-lg-2 mb-4 mb-lg-0"
                                          type="button"
                                        >
                                          View CC Certificate
                                        </button>
                                      </a>
                                    ) : (
                                      <button
                                        className="btn_brnd mt-lg-2 mb-4 mb-lg-0 tooltips"
                                        type="button"
                                      >
                                        View CC Certificate
                                      </button>
                                    ))}
                                </div>
                                <div className="col-lg-12 mt-5">
                                  {values.courseFirstEnrol &&
                                    retakecourseData?.jointCourseFirstGenerated ==
                                      "yes" && (
                                      <div className=" input_wrap">
                                        <label className="label-title">
                                          CC Date
                                        </label>
                                        <input
                                          type="text"
                                          readOnly
                                          className="form-control"
                                          value={
                                            retakecourseData?.jointCourseFirstDate?.split(
                                              "T"
                                            )[0] ?? ""
                                          }
                                        />
                                        {errors.start && touched.start ? (
                                          <p className="text-danger">
                                            {errors.start}
                                          </p>
                                        ) : null}
                                      </div>
                                    )}
                                  {values.courseFirstEnrol && (
                                    <div className="col-lg-12">
                                      <div className=" input_wrap d-flex">
                                        <label className="label-title">
                                          CC Cert
                                        </label>
                                        <div className="file-uplaod form-control">
                                          <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => {
                                              setFirstPDFJointRetake(
                                                e.target.files[0]
                                              );
                                            }}
                                          />
                                          {firstPDFJointRetake ? (
                                            firstPDFJointRetake.name
                                          ) : (
                                            <>
                                              {" "}
                                              <p>No file selected...</p>
                                              <span> Brows...</span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-4 w-100">
                                        <div
                                          className="form-text"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <button
                                            className="btn_brnd"
                                            type="button"
                                            disabled={
                                              uploadingFirstJointRetake ||
                                              !firstPDFJointRetake
                                            }
                                            onClick={() => {
                                              setUploadingFirstJointRetake(
                                                true
                                              );
                                              uploadCertificateRetake(
                                                "first",
                                                {
                                                  courseId:
                                                    retakecourseData?.jointCourseFirstId,
                                                  course: "first",
                                                  email:
                                                    response.deliverymethod ==
                                                    "E-mail"
                                                      ? response.email
                                                      : "",
                                                  attyemail: response.attypref
                                                    ? response.attyemail
                                                    : "",
                                                  file: firstPDFJointRetake,
                                                },
                                                true
                                              );
                                            }}
                                          >
                                            {uploadingFirstJointRetake ? (
                                              <ThreeDots
                                                height="20"
                                                width="150"
                                                radius="9"
                                                color="#4fa94d"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{
                                                  display: "block",
                                                }}
                                                wrapperClassName="spinner"
                                                visible={true}
                                              />
                                            ) : (
                                              "Upload CC Certificate"
                                            )}
                                          </button>
                                          {values.courseFirstEnrol &&
                                            retakecourseData?.jointCourseFirstGenerated ==
                                              "yes" &&
                                            retakecourseData?.jointCourseFirstDownloaded &&
                                            retakecourseData?.jointCourseFirstDownloaded !==
                                              "yes" && (
                                              <button
                                                className="btn_brnd mb-4 mb-lg-0 ms-4"
                                                type="button"
                                                disabled={
                                                  resendingFirstJointRetake
                                                }
                                                onClick={() => {
                                                  setResendingFirstJointRetake(
                                                    true
                                                  );
                                                  resendCertificateRetake(
                                                    {
                                                      firstName:
                                                        values.joint_firstname,
                                                      lastName:
                                                        values.joint_lastname,
                                                      link: retakecourseData?.jointCourseFirstDownloaded,
                                                      email: values.email,
                                                    },
                                                    setResendingFirstJointRetake
                                                  );
                                                }}
                                              >
                                                {resendingFirstJointRetake ? (
                                                  <ThreeDots
                                                    height="20"
                                                    width="60"
                                                    radius="9"
                                                    color="#4fa94d"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{
                                                      display: "block",
                                                    }}
                                                    wrapperClassName="spinner"
                                                    visible={true}
                                                  />
                                                ) : (
                                                  "Resend"
                                                )}
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-lg-6">
                      <div className="row">
                        <div
                          className="client-head"
                          style={{
                            backgroundColor: "transparent",
                            marginBottom: "20px",
                            marginTop: "-30px",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div class="row">
                                <div class="col-lg-5">
                                  <h1>Debtor Education</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {values.course_second_retake !== "1" ? (
                          <div className="col-lg-6">
                            <button
                              className="btn_brnd"
                              type="button"
                              onClick={() => {
                                setReTakingDE(true);
                                reTakeDE();
                              }}
                              disabled={reTakingDE}
                            >
                              {reTakingDE ? (
                                <ThreeDots
                                  height="20"
                                  width="100"
                                  radius="9"
                                  color="#4fa94d"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{
                                    display: "block",
                                  }}
                                  wrapperClassName="spinner"
                                  visible={true}
                                />
                              ) : (
                                "Re-Take"
                              )}
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="iagree_radio mb-4">
                              {values.courseSecondEnrol ? (
                                <div className="mt-4 mb-4">
                                  <Link
                                    to={`/user-second-course?id=${userId}&retake=true`}
                                  >
                                    <button className="btn_brnd">
                                      Continue Course
                                    </button>
                                  </Link>
                                  <Link
                                    to={`/courseprogress?id=${userId}&course=second&retake=true`}
                                    className="ms-4"
                                  >
                                    <button type="button" className="btn_brnd">
                                      Course Progress
                                    </button>
                                  </Link>
                                </div>
                              ) : (
                                <div className="mt-4 mb-4 ">
                                  <Link
                                    to={`/enroll-user-second-course?id=${userId}`}
                                  >
                                    <button className="btn_brnd">
                                      Enroll Now
                                    </button>
                                  </Link>
                                </div>
                              )}
                            </div>

                            {values.courseSecondEnrol && (
                              <div className="col-lg-12 fw-bold">
                                <h5
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "17px",
                                  }}
                                >
                                  Main Filer{" "}
                                  <span style={{ fontSize: "14px" }}>
                                    {" "}
                                    (
                                    {retakecourseData?.perSecondCourseMain.toFixed(
                                      0
                                    )}
                                    % Completed)
                                  </span>
                                </h5>
                              </div>
                            )}
                            <div className="col-lg-6">
                              {values.courseSecondEnrol &&
                                retakecourseData?.generated == "" && (
                                  <button
                                    className="btn_brnd"
                                    type="button"
                                    onClick={() => {
                                      if (values.judicialdistrict_second) {
                                        setGeneratingSecondRetake(true);

                                        if (
                                          response.judicialdistrict_second !==
                                          values.judicialdistrict_second
                                        ) {
                                          setPostLoading(true);
                                          updateProfile(userId, values)
                                            .then((res) => {
                                              toast.success(
                                                "User updated successfully"
                                              );
                                              setPostLoading(false);
                                              generateCertificateSecondRetake({
                                                sendEmail:
                                                  retakecourseData?.sendEmail,
                                                courseId:
                                                  retakecourseData?.courseSecondId,
                                                jointCourseId:
                                                  retakecourseData?.jointCourseSecondId,
                                                course: "second",
                                                bankruptcycasenum:
                                                  values.bankruptcycasenum,
                                                email:
                                                  response.deliverymethod ==
                                                  "E-mail"
                                                    ? response.email
                                                    : "",
                                                attyemail: response.attypref
                                                  ? response.attyemail
                                                  : "",
                                              });
                                            })
                                            .catch((err) => {
                                              toast.error(
                                                err.response.data.error
                                              );
                                              setPostLoading(false);
                                            });
                                        } else {
                                          generateCertificateSecondRetake({
                                            sendEmail:
                                              retakecourseData?.sendEmail,
                                            courseId:
                                              retakecourseData?.courseSecondId,
                                            jointCourseId:
                                              retakecourseData?.jointCourseSecondId,
                                            course: "second",
                                            bankruptcycasenum:
                                              values.bankruptcycasenum,
                                            email:
                                              response.deliverymethod ==
                                              "E-mail"
                                                ? response.email
                                                : "",
                                            attyemail: response.attypref
                                              ? response.attyemail
                                              : "",
                                          });
                                        }
                                      } else {
                                        toast.error(
                                          "Judicial District is required"
                                        );
                                      }
                                    }}
                                    disabled={generatingSecondRetake}
                                  >
                                    {generatingSecondRetake ? (
                                      <ThreeDots
                                        height="20"
                                        width="160"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{
                                          display: "block",
                                        }}
                                        wrapperClassName="spinner"
                                        visible={true}
                                      />
                                    ) : (
                                      "Generate DE Certificate"
                                    )}
                                  </button>
                                )}
                              {values.courseSecondEnrol &&
                                retakecourseData?.generated == "yes" &&
                                (retakecourseData?.downloaded &&
                                retakecourseData?.downloaded !== "yes" ? (
                                  <a
                                    href={retakecourseData?.downloaded}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn_brnd mt-lg-2 mb-4 mb-lg-0"
                                      type="button"
                                    >
                                      View DE Certificate
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    className="btn_brnd mt-lg-2 mb-4 mb-lg-0 tooltips"
                                    type="button"
                                  >
                                    View DE Certificate
                                  </button>
                                ))}
                            </div>
                            <div className="col-lg-12 mt-5">
                              {values.courseSecondEnrol &&
                                retakecourseData?.generated && (
                                  <div className=" input_wrap">
                                    <label className="label-title">
                                      DE Date
                                    </label>
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      value={
                                        retakecourseData?.date?.split("T")[0] ??
                                        ""
                                      }
                                    />
                                    {errors.start && touched.start ? (
                                      <p className="text-danger">
                                        {errors.start}
                                      </p>
                                    ) : null}
                                  </div>
                                )}
                              {values.courseSecondEnrol && (
                                <div className="col-lg-12">
                                  <div className=" input_wrap d-flex">
                                    <label className="label-title">
                                      DE Cert
                                    </label>
                                    <div className="file-uplaod form-control">
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => {
                                          setSecondPDFRetake(e.target.files[0]);
                                        }}
                                      />
                                      {secondPDFRetake ? (
                                        secondPDFRetake.name
                                      ) : (
                                        <>
                                          {" "}
                                          <p>No file selected...</p>
                                          <span> Brows...</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-4 w-100">
                                    <div
                                      className="form-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <button
                                        className="btn_brnd"
                                        type="button"
                                        disabled={
                                          uploadingSecondRetake ||
                                          !secondPDFRetake
                                        }
                                        onClick={() => {
                                          setUploadingSecondRetake(true);
                                          uploadCertificateRetake(
                                            "second",
                                            {
                                              courseId:
                                                retakecourseData?.courseSecondId,
                                              course: "second",
                                              email:
                                                response.deliverymethod ==
                                                "E-mail"
                                                  ? response.email
                                                  : "",
                                              attyemail: response.attypref
                                                ? response.attyemail
                                                : "",
                                              file: secondPDFRetake,
                                            },
                                            false
                                          );
                                        }}
                                      >
                                        {uploadingSecondRetake ? (
                                          <ThreeDots
                                            height="20"
                                            width="150"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            wrapperClassName="spinner"
                                            visible={true}
                                          />
                                        ) : (
                                          "Upload DE Certificate"
                                        )}
                                      </button>

                                      {values.courseSecondEnrol &&
                                        retakecourseData?.generated == "yes" &&
                                        retakecourseData?.downloaded &&
                                        retakecourseData?.downloaded !==
                                          "yes" && (
                                          <button
                                            className="btn_brnd mb-4 mb-lg-0 ms-4"
                                            type="button"
                                            disabled={resendingSecondRetake}
                                            onClick={() => {
                                              setResendingSecondRetake(true);
                                              resendCertificateRetake(
                                                {
                                                  firstName: values.firstname,
                                                  lastName: values.lastname,
                                                  link: retakecourseData?.downloaded,
                                                  email: values.email,
                                                },
                                                setResendingSecondRetake
                                              );
                                            }}
                                          >
                                            {resendingSecondRetake ? (
                                              <ThreeDots
                                                height="20"
                                                width="60"
                                                radius="9"
                                                color="#4fa94d"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{
                                                  display: "block",
                                                }}
                                                wrapperClassName="spinner"
                                                visible={true}
                                              />
                                            ) : (
                                              "Resend"
                                            )}
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            {values.joint_username && (
                              <>
                                {values.courseSecondEnrol && (
                                  <div className="col-lg-12 fw-bold">
                                    <h5
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "17px",
                                      }}
                                    >
                                      Joint Filer{" "}
                                      <span style={{ fontSize: "14px" }}>
                                        {" "}
                                        (
                                        {retakecourseData?.perSecondCourseJoint &&
                                          retakecourseData?.perSecondCourseJoint.toFixed(
                                            0
                                          )}
                                        % Completed)
                                      </span>
                                    </h5>
                                  </div>
                                )}
                                <div className="col-lg-6">
                                  {values.courseSecondEnrol &&
                                    retakecourseData?.jointCourseSecondGenerated ==
                                      "" && (
                                      <button
                                        className="btn_brnd"
                                        type="button"
                                        onClick={() => {
                                          if (values.judicialdistrict_second) {
                                            setGeneratingSecondJointRetake(
                                              true
                                            );

                                            if (
                                              response.judicialdistrict_second !==
                                              values.judicialdistrict_second
                                            ) {
                                              setPostLoading(true);
                                              updateProfile(userId, values)
                                                .then((res) => {
                                                  toast.success(
                                                    "User updated successfully"
                                                  );
                                                  setPostLoading(false);
                                                  generateCertificateSecondRetake(
                                                    {
                                                      sendEmail:
                                                        retakecourseData?.sendEmail,
                                                      courseId:
                                                        retakecourseData?.courseSecondId,
                                                      jointCourseId:
                                                        retakecourseData?.jointCourseSecondId,
                                                      course: "second",
                                                      bankruptcycasenum:
                                                        values.bankruptcycasenum,
                                                      email:
                                                        response.deliverymethod ==
                                                        "E-mail"
                                                          ? response.email
                                                          : "",
                                                      attyemail:
                                                        response.attypref
                                                          ? response.attyemail
                                                          : "",
                                                    }
                                                  );
                                                })
                                                .catch((err) => {
                                                  toast.error(
                                                    err.response.data.error
                                                  );
                                                  setPostLoading(false);
                                                });
                                            } else {
                                              generateCertificateSecondRetake({
                                                sendEmail:
                                                  retakecourseData?.sendEmail,
                                                courseId:
                                                  retakecourseData?.courseSecondId,
                                                jointCourseId:
                                                  retakecourseData?.jointCourseSecondId,
                                                course: "second",
                                                bankruptcycasenum:
                                                  values.bankruptcycasenum,
                                                email:
                                                  response.deliverymethod ==
                                                  "E-mail"
                                                    ? response.email
                                                    : "",
                                                attyemail: response.attypref
                                                  ? response.attyemail
                                                  : "",
                                              });
                                            }
                                          } else {
                                            toast.error(
                                              "Judicial District is required"
                                            );
                                          }
                                        }}
                                        disabled={generatingSecondJointRetake}
                                      >
                                        {generatingSecondJointRetake ? (
                                          <ThreeDots
                                            height="20"
                                            width="160"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            wrapperClassName="spinner"
                                            visible={true}
                                          />
                                        ) : (
                                          "Generate DE Certificate"
                                        )}
                                      </button>
                                    )}
                                  {values.courseSecondEnrol &&
                                    retakecourseData?.jointCourseSecondGenerated ==
                                      "yes" &&
                                    (retakecourseData?.jointCourseSecondDownloaded &&
                                    retakecourseData?.jointCourseSecondDownloaded !==
                                      "yes" ? (
                                      <a
                                        href={
                                          retakecourseData?.jointCourseSecondDownloaded
                                        }
                                        target="_blank"
                                      >
                                        <button
                                          className="btn_brnd mt-lg-2 mb-4 mb-lg-0"
                                          type="button"
                                        >
                                          View DE Certificate
                                        </button>
                                      </a>
                                    ) : (
                                      <button
                                        className="btn_brnd mt-lg-2 mb-4 mb-lg-0 tooltips"
                                        type="button"
                                      >
                                        View DE Certificate
                                      </button>
                                    ))}
                                </div>
                                <div className="col-lg-12 mt-5">
                                  {values.courseSecondEnrol &&
                                    retakecourseData?.jointCourseSecondGenerated && (
                                      <div className=" input_wrap">
                                        <label className="label-title">
                                          DE Date
                                        </label>
                                        <input
                                          type="text"
                                          readOnly
                                          className="form-control"
                                          value={
                                            retakecourseData?.jointCourseSecondDate?.split(
                                              "T"
                                            )[0] ?? ""
                                          }
                                        />
                                        {errors.start && touched.start ? (
                                          <p className="text-danger">
                                            {errors.start}
                                          </p>
                                        ) : null}
                                      </div>
                                    )}
                                  {values.courseSecondEnrol && (
                                    <div className="col-lg-12">
                                      <div className=" input_wrap d-flex">
                                        <label className="label-title">
                                          DE Cert
                                        </label>
                                        <div className="file-uplaod form-control">
                                          <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => {
                                              setSecondPDFJointRetake(
                                                e.target.files[0]
                                              );
                                            }}
                                          />
                                          {secondPDFJointRetake ? (
                                            secondPDFJointRetake.name
                                          ) : (
                                            <>
                                              {" "}
                                              <p>No file selected...</p>
                                              <span> Brows...</span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-4 w-100">
                                        <div
                                          className="form-text"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <button
                                            className="btn_brnd"
                                            type="button"
                                            disabled={
                                              uploadingSecondJointRetake ||
                                              !secondPDFJointRetake
                                            }
                                            onClick={() => {
                                              setUploadingSecondJointRetake(
                                                true
                                              );
                                              uploadCertificateRetake(
                                                "second",
                                                {
                                                  courseId:
                                                    retakecourseData?.jointCourseSecondId,
                                                  course: "second",
                                                  email:
                                                    response.deliverymethod ==
                                                    "E-mail"
                                                      ? response.email
                                                      : "",
                                                  attyemail: response.attypref
                                                    ? response.attyemail
                                                    : "",
                                                  file: secondPDFJointRetake,
                                                },
                                                true
                                              );
                                            }}
                                          >
                                            {uploadingSecondJointRetake ? (
                                              <ThreeDots
                                                height="20"
                                                width="150"
                                                radius="9"
                                                color="#4fa94d"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{
                                                  display: "block",
                                                }}
                                                wrapperClassName="spinner"
                                                visible={true}
                                              />
                                            ) : (
                                              "Upload DE Certificate"
                                            )}
                                          </button>

                                          {values.courseSecondEnrol &&
                                            retakecourseData?.jointCourseSecondGenerated ==
                                              "yes" &&
                                            retakecourseData?.jointCourseSecondDownloaded &&
                                            retakecourseData?.jointCourseSecondDownloaded !==
                                              "yes" && (
                                              <button
                                                className="btn_brnd mb-4 mb-lg-0 ms-4"
                                                type="button"
                                                disabled={
                                                  resendingSecondJointRetake
                                                }
                                                onClick={() => {
                                                  setResendingSecondJointRetake(
                                                    true
                                                  );
                                                  resendCertificate(
                                                    {
                                                      firstName:
                                                        values.joint_firstname,
                                                      lastName:
                                                        values.joint_lastname,
                                                      link: retakecourseData?.jointCourseSecondDownloaded,
                                                      email: values.email,
                                                    },
                                                    setResendingSecondJointRetake
                                                  );
                                                }}
                                              >
                                                {resendingSecondJointRetake ? (
                                                  <ThreeDots
                                                    height="20"
                                                    width="60"
                                                    radius="9"
                                                    color="#4fa94d"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{
                                                      display: "block",
                                                    }}
                                                    wrapperClassName="spinner"
                                                    visible={true}
                                                  />
                                                ) : (
                                                  "Resend"
                                                )}
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className="client-head">
                    <h1>Address</h1>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className=" input_wrap">
                        <label className="label-title">Address Line 1</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="addr1"
                          value={values.addr1}
                        />
                        {errors.addr1 && touched.addr1 ? (
                          <p className="text-danger">{errors.addr1}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className=" input_wrap">
                        <label className="label-title">Address Line 2</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="addr2"
                          value={values.addr2}
                        />
                        {errors.addr2 && touched.addr2 ? (
                          <p className="text-danger">{errors.addr2}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className=" input_wrap">
                        <label className="label-title">City</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="city"
                          value={values.city}
                        />
                        {errors.city && touched.city ? (
                          <p className="text-danger">{errors.city}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className=" input_wrap">
                        <label className="label-title">State</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="state"
                          value={values.state}
                        />
                        {errors.state && touched.state ? (
                          <p className="text-danger">{errors.state}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className=" input_wrap">
                        <label className="label-title">Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="zip"
                          value={values.zip}
                        />
                        {errors.zip && touched.zip ? (
                          <p className="text-danger">{errors.zip}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="client-head">
                    <h1>Certificate Delivery Method</h1>
                  </div>
                  <div className="row ">
                    <div className="col-lg-6 mb-4">
                      <label className="from-label">
                        Certificate delivery method:
                      </label>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <div className="iagree_radio  form-check-inline">
                        <div>
                          <input
                            type="checkbox"
                            name="tickbox"
                            checked={values.deliverymethod === "E-mail"}
                            onChange={() => {
                              setValues({
                                ...values,
                                deliverymethod: "E-mail",
                              });
                            }}
                            id="email"
                            className=" "
                          />
                          <label for="email">Email</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row ">
                    <div className="col-lg-6 mb-4">
                      <label className="from-label">Is This Urgent?</label>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <div className="iagree_radio  form-check-inline">
                        <div>
                          <input
                            type="checkbox"
                            name="tickbox"
                            checked={values.urgent === "checked"}
                            onChange={() => {
                              setValues({
                                ...values,
                                urgent: "checked",
                              });
                            }}
                            id="urgentYes"
                            className=" "
                          />
                          <label for="urgentYes">Yes</label>
                        </div>
                      </div>
                      <div className="iagree_radio form-check-inline">
                        <div>
                          <input
                            type="checkbox"
                            name="tickbox"
                            checked={values.urgent !== "checked"}
                            onChange={() => {
                              setValues({ ...values, urgent: "" });
                            }}
                            id="urgentNo"
                            className=" "
                          />
                          <label for="urgentNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* ------------ verify button ---------- */}
                  <div className="row ">
                    <div className="col-lg-6 mb-4">
                      <label className="from-label">
                        Want us to e-mail certificate to your attorney?
                      </label>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <div className="iagree_radio  form-check-inline">
                        <div>
                          <input
                            type="checkbox"
                            name="tickbox"
                            checked={values.attypref === "checked"}
                            onChange={() => {
                              setValues({ ...values, attypref: "checked" });
                            }}
                            id="attyprefYes"
                            className=" "
                          />
                          <label for="attyprefYes">Yes</label>
                        </div>
                      </div>
                      <div className="iagree_radio form-check-inline">
                        <div>
                          <input
                            type="checkbox"
                            name="tickbox"
                            id="attyprefNo"
                            checked={values.attypref !== "checked"}
                            onChange={() => {
                              setValues({ ...values, attypref: "" });
                            }}
                            className=" "
                          />
                          <label for="attyprefNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

{/* ------------------------------------- attorney code row ------------------------------------- */}
                  <div className="row mt-5">
                    {/* ==============attorney email address */}
                    <div className="col-lg-4">
                      <div className=" input_wrap">
                        <label className="label-title">
                          Attorney's E-mail Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly={
                            values.attyemail &&
                            values.attorneyCodeExist &&
                            values.couponcode_first !== ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="attyemail"
                          value={values.attyemail}
                        />
                        {errors.attyemail && touched.attyemail ? (
                          <p className="text-danger">{errors.attyemail}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                  {/* ------------ Attorney Code [CC] ---------- */}
                    <div className="col-lg-4">
                      <div className=" input_wrap">
                        <label className="label-title">CC Code {values?.activeCode && values.activeCode==="CC" && "(Active)"}</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            handleChange(e);
                            setattCodeValidation(false);
                            setAllowAssociate((prev)=>({...prev, CC:false}))
                          }}
                          onBlur={handleBlur}
                          name="couponcode_first"
                          value={values.couponcode_first}
                        />
                        {/* {values.couponcode_first &&
                        values.attyemail &&
                        !values.attorneyCodeExist ? (
                          <p className="text-danger">
                            Attorney code no longer supported
                          </p>
                        ) : null} */}
                        {attCodeValidation==="CC" && (
                          <p className="text-danger">Invalid Attorney Code </p>
                        )}
                        {errors.couponcode_first && touched.couponcode_first ? (
                          <p className="text-danger">
                            {errors.couponcode_first}
                          </p>
                        ) : null}
                      </div>
                      <div  className="row flex gap-4 px-3">
                      {/* ------------ verify button CC---------- */}
                        <VerifyButton 
                          onClick={() => {
                            handleVerifyAttorney(
                              values.couponcode_first,
                              setValues,
                              "CC"
                            );
                          }}
                          loading={loadingStates.isCCVerifying} 
                          nonLoadingContent={"Verify"}
                        />
                        {/* ------------ associate Button [CC] ------------- */}
                        <VerifyButton 
                          onClick={() => {
                            handleAssociateAttorney("CC")
                          }}
                          disabled={allowAssociate.CC}
                          loading={loadingStates.CCAssociating} 
                          nonLoadingContent={"Associate CC"}
                        />
                      </div>
                    </div>
                     {/* ------------ Attorney Code [DE] ---------- */}
                     <div className="col-lg-4">
                      <div className=" input_wrap">
                        <label className="label-title">DE Code {values.activeCode && values.activeCode==="DE" && "(Active)"}</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            handleChange(e);
                            setattCodeValidation(false);
                            setAllowAssociate((prev)=>({...prev, DE:false}))
                          }}
                          onBlur={handleBlur}
                          name="couponcode_second"
                          value={values.couponcode_second}
                        />
                        {/* {values.couponcode_first &&
                        values.attyemail &&
                        !values.attorneyCodeExist ? (
                          <p className="text-danger">
                            Attorney code no longer supported
                          </p>
                        ) : null} */}
                        {attCodeValidation==="DE" && (
                          <p className="text-danger">Invalid Attorney Code </p>
                        )}
                        {errors.couponcode_second && touched.couponcode_second ? (
                          <p className="text-danger">
                            {errors.couponcode_second}
                          </p>
                        ) : null}
                      </div>
                      {/* ------------ verify & associate button [DE] ---------- */}
                      <div  className="row flex gap-4 px-3">
                          <VerifyButton 
                            onClick={() => {
                              handleVerifyAttorney(
                                values.couponcode_second,
                                setValues,
                                "DE"
                              );
                            }}
                            loading={loadingStates.isDEVerifying} 
                            nonLoadingContent={"Verify"}
                          />
                          {/* ---------------- associate DE button */}
                          <VerifyButton 
                            onClick={() => {
                              handleAssociateAttorney("DE")
                            }}
                            disabled={allowAssociate.DE}
                            loading={loadingStates.DEAssociating} 
                            nonLoadingContent={"Associate DE"}
                          />
                      </div>
                    </div>
                  </div>
{/* ---------------------------------------------------------------------------------------------- */}

                  {secEmails && secEmails.length > 0 && (
                    <div className="client-head">
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <h1 className="f18">Attorney Secondary Emails</h1>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {secEmails &&
                      secEmails.length > 0 &&
                      secEmails.map((item, index) => (
                        <div
                          className="col-lg-4 d-flex align-items-start secondary-emails"
                          key={index}
                        >
                          <div className="input_wrap w-100">
                            <label className="label-title">
                              Email {index + 1}
                            </label>
                            <input
                              type="text"
                              readOnly
                              value={item.email}
                              className="form-control"
                            />
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="text-center">
                    {/* <button className="btn_brnd-outline my-2 me-3">
                    Login as This User
                  </button> */}
                    <button
                      className="btn_brnd my-3"
                      type="submit"
                      disabled={
                        postLoading ||
                        generatingFirst ||
                        generatingFirstJointRetake ||
                        generatingSecond ||
                        generatingSecondJoint
                      }
                      onClick={() => {
                        // if (Object.keys(errors).length > 0) {
                        //   toast.error("Form validation failed");
                        // }
                      }}
                    >
                      {postLoading ? (
                        <ThreeDots
                          height="20"
                          width="60"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </form>
              </section>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default EditingClient;
