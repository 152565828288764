import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getStates, userLogin, userSignupWithShortDet, VerifyUser } from "../../api/server";
import { login, signup } from "../../store/userSlice";
import { Link, useNavigate } from "react-router-dom";
import updateInstance from "../../api/server";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AttorneyInfoModal from "./AttorneyInfoModal";

const Login = () => {
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postLoading, setPostLoading] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [showModal , setShowModal] = useState(false)
  const [noCode, setNoCode] = useState(false);
   const [readOnly, setReadOnly] = useState(false);
   const [atternyName, setAtternyName] = useState("");
   const [atternyEmail, setAtternyEmail] = useState("");
   const [atternyNumber, setAtternyNumber] = useState("");
   const [attCodeValidation, setattCodeValidation] = useState(false); 
  const [userAttorney, setUserAttorney] = useState("");
   const [loading, setLoading] = useState(false);
   const [attorneyInfo , setAttorneyInfo] = useState()
   const [attorneyCodeVerified , setAttorneyCodeVerified] = useState(false)

   function valuesSetter(data) {
    setAtternyEmail(data.email);
    setAtternyName(data?.firstname);
    setAtternyNumber(data?.phone);
  }

 const handleVerifyAttorney = async (e) => {
     setattCodeValidation(false);
     // e.preventDefault();
     const body = {
       code: userAttorney,
     };
     try {
       setLoading(true);
       const response = await VerifyUser(body);
       if (response.status === 200) {
         setReadOnly(true);
         const data = response.data;
         valuesSetter(response?.data?.data);
         const attdata = {
          attorneyCode:{
            atty_name:response?.data?.data?.firstname,
            
            atty_email:response?.data?.data?.email,
            code:userAttorney
          },
          data:{
            phone:response?.data?.data?.phone,
          }
         }
         setAttorneyInfo(attdata)
         setAttorneyCodeVerified(true)
          toast.success("Attorney information verified successfully!");
         
         setLoading(false);
       } else {
         setattCodeValidation(true);
       }
     } catch (error) {
       setattCodeValidation(true);
       setLoading(false);
     }
   };
   const checkSignup = () =>{
    if(signupLoading){
      return true
    }
    if(userAttorney == ""){
      return false
    }
    if(userAttorney && attorneyCodeVerified){
       return false
    }
    if(userAttorney && !attorneyCodeVerified){
      return true
   }
   }

 useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      dispatch(login(storedUser));
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchStatesData = async () => {
      try {
        const response = await getStates();
        console.log("responseresponseresponse", response)
        setStatesData(response.data);
      } catch (error) {
        console.error("Error fetching states data:", error);
      }
    };
    fetchStatesData();
  }, []);

  const loginFormik = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: Yup.object({
      username: Yup.string().required("User Name is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      setPostLoading(true);
      userLogin(values)
        .then((res) => {
          setPostLoading(false);
          if (res.status === 200) {
            updateInstance(res.data.token);
            dispatch(login(res.data));
            localStorage.setItem("isJoint", res.data.isJoint);

            if (res.data.data.type === "Admin") {
              localStorage.setItem("adminCode", values.password);
              navigate("/admin-dashboard");
            } else if (res.data.data.type === "Atty") {
              navigate("/manage-clients");
            } else {
              navigate("/dashboard");
            }
          }
        })
        .catch((err) => {
          setPostLoading(false);
          toast.error(err.response?.data?.error || "An unexpected error occurred.");
        });
    },
  });

  useEffect(() => {
    if (token) {
      if (user?.user?.data?.type === "Admin") {
        navigate("/admin-dashboard");
      } else if (user?.user?.data?.type === "Atty") {
        navigate("/manage-clients");
      } else {
        navigate("/dashboard");
      }
    }
  }, [token, user, navigate]);

  return (
    <div>
      <section className="login_wrap">
      <div className="login_form py-4">
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <div className="login_logo">
            <Link to={`/`}>
              <img src={logo} alt="logo" />
            </Link>
            {/* <p className="f18">Please enter your username/email and password</p> */}
          </div>
              <h1 style={{ fontSize: "40px", color: "green" }}>
                Not a member yet?
              </h1>
              <h2 className="f18 mb-5" style={{ color: "green" }}>
                Join us today by signing up!
              </h2>

              <Formik
                initialValues={{
                  email: "",
                  state: "",
                  attycode: "",
                  password: "",
                  confirm_password: "",
                  phone:"",
                  lastName:"",
                  middleInitial:"",
                  firstName:""
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Email is required"),
                  state: Yup.string().required("Filing State is required"),
                  password: Yup.string()
                    .min(6, "Password must be at least 6 characters")
                    .required("Password is required"),
                  confirm_password: Yup.string()
                    .oneOf([Yup.ref("password"), null], "Passwords must match")
                    .required("Confirm Password is required"),
                    phone:Yup.string().required("Phone Number is required"),
                    lastName:Yup.string().required("Last Name is required"),
                    middleInitial:Yup.string(),
                    firstName:Yup.string().required("First Name is required")
                })}
                onSubmit={(values, { resetForm }) => {
                  setSignupLoading(true);
                  if(attorneyInfo){

                    values = {...values , 
                      attycode:attorneyInfo?.attorneyCode?.code,
                      attypref:"checked",
                      attyemail:attorneyInfo?.attorneyCode?.atty_email,
                      attyphone:attorneyInfo?.data?.phone,
                      attorneyname:attorneyInfo?.attorneyCode?.atty_name
                    }
                  }
                  console.log("values<>>>>>>>",values)
                  userSignupWithShortDet(values)
                    .then((res) => {
                      setSignupLoading(false);
                      if (res.data.message === "Success!") {
                        localStorage.setItem("authToken", res.data.token);
                        const authToken = res.data.token;
                        updateInstance(res.data.token);
                      
                        localStorage.setItem("authToken", authToken);
                        dispatch(signup(res.data));

                        toast.success("Signup successful!");
                        resetForm();
                        navigate("/dashboard");
                      }
                    })
                    .catch((error) => {
                      setSignupLoading(false);
                      toast.error( error?.response?.data?.error || "Signup failed.");
                    });
                }}
              >
                {({ handleSubmit,setFieldValue }) => (
                  <>
                  
                  <AttorneyInfoModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  setFieldValue={setFieldValue}
                  setUserAttorney={setUserAttorney}
                  setAttorneyInfo={setAttorneyInfo}
                  setAttorneyCodeVerified={setAttorneyCodeVerified}
                  attorneyInfo={attorneyInfo}
                />
                <Form onSubmit={handleSubmit} className="form-container signup-form-container" style={{width:'70%'}} autoComplete="off">
                    <div style={{ display: "flex", gap: "20px" }}>
                      <div className="input_wrap" style={{ width: "50%" }}>
                        <label className="label-title">Email</label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email"   
                          style={{height:'55px'}}     
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message text-danger"
                          style={{fontSize:'14px',position:'absolute'}}
                        />
                      </div>

                      <div className="input_wrap" style={{ width: "50%" }}>
                        <label className="label-title">Filing State</label>
                        <Field as="select" name="state" className="form-control"   style={{height:'55px'}}>
                          <option value="">Select a State</option>
                          {statesData.length > 0
                            ? statesData.map((state, index) => (
                              <option key={index} value={state.state}>
                                {state.state}
                              </option>
                            ))
                            : null}
                        </Field>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error-message text-danger"
                          style={{fontSize:'14px',position:'absolute'}}
                        />
                      </div>
                    </div>

                    <div style={{ display: "flex", gap: "20px" }}>
                      <div className="input_wrap" style={{ width: "50%" }}>
                        <label className="label-title">Password</label>
                        <Field
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Enter Password"
                          style={{height:'55px'}}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message text-danger"
                          style={{fontSize:'14px',position:'absolute'}}
                        />
                      </div>

                      <div className="input_wrap" style={{ width: "50%" }}>
                        <label className="label-title">Confirm Password</label>
                        <Field
                          type="password"
                          className="form-control"
                          name="confirm_password"
                          placeholder="Confirm Password"
                          style={{height:'55px'}}
                        />
                        <ErrorMessage
                          name="confirm_password"
                          component="div"
                          className="error-message text-danger"
                          style={{fontSize:'14px',position:'absolute'}}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "20px" }}>
                    <div className="col-lg-6 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">First Name</label>
                        <Field
                          type="text"
                          name="firstName"
                          className="form-control"
                          placeholder="Enter your first name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Middle Initial</label>
                        <Field
                          type="text"
                          name="middleInitial"
                          className="form-control"
                          placeholder="Enter your middle name"
                        />
                        <ErrorMessage
                          name="middleInitial"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>

                    </div>
                    <div style={{ display: "flex", gap: "20px" }}>
                    <div className="col-lg-6 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Last Name</label>
                        <Field
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="Enter your last name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                                          <div className="input_wrap">
                                            <label className="label-title">Phone Number</label>
                                            <Field
                                              type="text"
                                              className="form-control"
                                              name="phone"
                                              placeholder="Enter your phone no."
                                            />
                                            <ErrorMessage
                                              name="phone"
                                              component="div"
                                              className="error-message text-danger"
                                            />
                                          </div>
                      </div>
                    </div>
                     
               
                    

                    <div className="row ">
                    {!noCode && (
                      <div className="col-lg-7 col-md-7 verify-code-container-field">
                        <div className="input_wrap" style={{marginBottom:'15px'}}>
                          <label className="label-title">
                            Enter Attorney Code
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            value={userAttorney}
                            onChange={(e) => {
                              setUserAttorney(e?.target?.value);
                              setattCodeValidation(false);
                            }}
                            name="attycode"
                            placeholder="Enter Attorney code"
                            style={{height:'55px'}}
                            readOnly={attorneyCodeVerified}
                          />
                          {attCodeValidation && (
                            <p className="text-danger">
                              Invalid Attorney Code{" "}
                            </p>
                          )}
                         
                        </div>
                      </div>
                    )}
                    
                    <div className="col-lg-5 col-md-5 verify-code-container-button" >
                      {!noCode && (
                        <button
                          className="btn_brnd-outline mr-22"
                          // disabled={postLoading}
                          type="button"
                          onClick={handleVerifyAttorney}
                          disabled={attorneyCodeVerified}
                        >
                          {loading ? (
                            <ThreeDots
                              height="20"
                              width="52"
                              radius="9"
                              color="#ed1717"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{
                                display: "block",
                              }}
                              wrapperClassName="spinner"
                              visible={true}
                            />
                          ) : (
                            <>{attorneyCodeVerified ?"Verified" :"Verify"}</>
                            
                          )}
                        </button>
                      )}
                      {/* <button
                        className="btn_brnd my-3"
                        type="button"
                        onClick={() => {
                          setNoCode(!noCode);
                          setReadOnly(false);
                          setUserAttorney("");
                          setAtternyName("");
                          setAtternyEmail("");
                          setAtternyNumber("");
                        }}
                      >
                        {noCode ? "Add Code" : " No Code ?"}
                      </button> */}
                    </div>
                  </div>
                  <p style={{fontSize:'14px'}}>
                          If you don’t have an attorney, leave this field blank. If you're unsure whether you have an attorney code, 
                          <span style={{cursor:'pointer', textDecoration:'underline',margin:'0 5px'}} 
                           type="button"
                           data-bs-toggle="modal"
                           data-bs-target="#AttorneyInfoModal"
                          > click here </span>
                         
                           and we will assist you in verifying it.
                          </p>

                  <button type="submit" className="signup-link mt-2 me-3 d-flex " style={{ fontSize: "18px",justifySelf:'center' }} disabled={checkSignup()}>
                    {signupLoading ? <ThreeDots height="20" width="60" radius="9" color="#4fa94d" /> : "Sign up"}
                  </button>
                  </Form>
                  
                  </>
                  
                )}
              </Formik>
            </div>
          </div>

       
        <div className="info_login_content">
          <div className="content_login_form">
            <div className="login_logo">
            <h1 style={{ fontSize: "34px", color: "white",padding:'0px 0px 25px 0px' }}>
            {/* Already a Member? Login here. */}
            Already have a username and password?
              </h1>
              
            <p className="f18" style={{color:'white'}}>
              {/* Please enter your username/email and password */}
              Login here.
              </p>

            </div>

            <div className="" style={{marginTop:'30px'}}>
              <form onSubmit={loginFormik.handleSubmit} autoComplete="off">
                <div className="input_wrap">
                  <label className="label-title" style={{background:'#4E9C4B',color:'white',border:'1px solid black',borderRadius:'20px'}} >Username or Email</label>
                  <input
                    {...loginFormik.getFieldProps("username")}
                    type="text"
                    className="form-control"
                    placeholder="Enter your username or email"
                    // style={{background:'#4E9C4B',border:"1px solid rgba(38, 50, 56, 0.2)"}}
                  />
                  {loginFormik.touched.username && loginFormik.errors.username && <p className="text-danger">{loginFormik.errors.username}</p>}
                </div>

                <div className="input_wrap mt-40">
                  <label className="label-title"  style={{background:'#4E9C4B',color:'white',border:'1px solid black',borderRadius:'20px'}}>Password</label>
                  <input
                    {...loginFormik.getFieldProps("password")}
                    type="password"
                    className="form-control pe-5"
                    placeholder="Enter password"
                    // style={{background:'#4E9C4B',border:"1px solid rgba(38, 50, 56, 0.2)"}}
                  />
                  {loginFormik.touched.password && loginFormik.errors.password && <p className="text-danger">{loginFormik.errors.password}</p>}
                </div>

                <Link to="/forgot-password" className="mt-4 d-flex" style={{width:'max-content',color:'white'}}>
                  <b>Forgot Password?</b>
                </Link>

                <button type="submit" className="signup-link mt-4 me-3 login_btn mb-4" disabled={postLoading}>
                  {postLoading ? <ThreeDots height="20" width="60" radius="9" color="#4fa94d" /> : "Login"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      
      
    </div>
  );
};

export default Login;