import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userinfo } from '../../api/server';
import { ThreeDots } from 'react-loader-spinner';


const UserProfile = () => {

    const user = useSelector((state) => state.user);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const [updatedUserData, setUpdatedUserData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        userinfo(user.user.data.id)
            .then((res) => {
                console.log("lkjaklsjf>>", res?.data?.data)
                setUpdatedUserData(res?.data?.data)
                setLoading(false)
            })
            .catch((err) => {
                setUpdatedUserData()
                setLoading(false)
            });
    }, []);

    return (
        <div>
            <Header />
            {
            
            isLoggedIn && user ? (
                <>
                    {
                        loading ?
                        <section className=" mt-250">
                        <div style={{ textAlign: "center" }}>
                            <ThreeDots
                                height="20"
                                width="110"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                    display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                            />

                        </div>
                    </section>
                    :
                            <div className="theme_container">

                                <h1 className="my-profile">My Profile</h1>

                                <div className="head mt-60">
                                    <h1 className="f18">Main Filer Info</h1>
                                </div>
                                <div className="row mt-40">
                                    <div className='user-data'>
                                        <b>Name</b>
                                        <p>{updatedUserData?.firstname} {updatedUserData?.middleinitial} {updatedUserData?.lastname}</p>
                                    </div>
                                    <div className='user-data'>
                                        <b>Last four digit of SSN</b>
                                        <p>{updatedUserData?.ssn} </p>
                                    </div>
                                    <div className='user-data'>
                                        <b>Phone Number</b>
                                        <p> {updatedUserData?.phone}</p>
                                    </div>
                                </div>

                                <div className="head mt-60">
                                    <h1 className="f18">Contact Info</h1>
                                </div>
                                <div className="row mt-40">
                                    <div className='user-data'>
                                        <b>Address</b>
                                        <p>{updatedUserData?.addr1}</p>
                                    </div>
                                    <div className='user-data'>
                                        <b>City</b>
                                        <p> {updatedUserData?.city} </p>
                                    </div>
                                    <div className='user-data'>
                                        <b>State</b>
                                        <p> {updatedUserData?.state}</p>
                                    </div>
                                    <div className='user-data'>
                                        <b>Zip Code</b>
                                        <p>{updatedUserData?.zip}</p>
                                    </div>
                                </div>

                                <div className="head mt-60">
                                    <h1 className="f18">Account Info...</h1>
                                </div>
                                <div className="row mt-40">
                                    <div className='user-data'>
                                        <b>Email</b>
                                        <p>{updatedUserData?.email}</p>
                                    </div>
                                    <div className='user-data'>
                                        <b>Username</b>
                                        <p>{updatedUserData?.username}</p>
                                    </div>
                                </div>

                                <div className="btn_wrap text-center mt-40">
                                    <Link className="btn_brnd-outline mr-22 mb-2" to="/dashboard">Go Back</Link>
                                    <Link type='button' className="btn_brnd" data-bs-target="#Serious" to={`/userprofileupdate?id=${updatedUserData?.id}`}  >Edit Profile</Link>
                                </div>


                            </div>

                    }


                </>
            ) : (

                <div>
                    <h1 style={{ marginTop: "200px" }}>Please Login First</h1>
                </div>
            )}

        </div>
    );
};

export default UserProfile;