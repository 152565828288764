import React, { useEffect, useState } from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";

import CollectInfoModal from "./CollectInfoModal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userinfo } from "../../api/server";
import { ThreeDots } from "react-loader-spinner";

const BeforeContactWithCounselor = () => {
    const userData = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false)
    const [updatedUserData, setUpdatedUserData] = useState()
    const [loading, setLoading] = useState(true)
    
    const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("id");
    console.log("userData<<<<<", userData?.user?.data?.id,id)

    useEffect(() => {
        userinfo(userData.user.data.id)
          .then((res) => {
            console.log("lkjaklsjf",res?.data?.data)
            setUpdatedUserData(res?.data?.data)
            setLoading(false)
          })
          .catch((err) => {
            setUpdatedUserData()
            setLoading(false)
          });
      }, []);

    return (
        <div>
            <Header />
            {
              userData &&  (userData?.user?.data?.id != id) ?
                    
                     <div className="theme_container">
                     <div className="my-profile">
                     
                     <div style={{textAlign:"center"}}>
                        <h1 className="f-32" >Please log in with the valid user account</h1>
                    </div>
                    </div>
                 </div>

                    :
                    <section className=" mt-250">
                        <div className="theme_container">
                            <div className="media_object" style={{ padding: '15px' }}>
                                <div className="congrationlaton_Wrap" style={{ maxWidth: 'unset' }}>


                                    <h1 className="f-26 text_success f-700 after_text">
                                        Congratulations!
                                    </h1>
                                    <p className="f-16" style={{ marginTop: '30px' }}>
                                        You’ve completed the online portion of the credit counseling class. To generate your certificate, which is required if you plan to file for bankruptcy, we will need to collect a little more information.
                                    </p>
                                    <p className="" style={{ marginTop: '30px', fontSize: '14px', fontStyle: 'italic' }}>
                                        Even if you're still exploring your options and aren’t sure whether bankruptcy is the right option for your specific situation, having this certificate ensures you’re ready if you decide to file for bankruptcy.
                                    </p>
                                </div>

                            </div>
                            {/* <div style={{ marginBottom: '60px' }}>

                        <p style={{ marginBottom: '3px' }}>

                            Even if you're undecided about filing for bankruptcy, you may still need a certificate of completion. This certificate is required if you decide to move forward with a bankruptcy filing.
                        </p>
                        <p style={{ marginBottom: '3px' }}>
                            Getting it now ensures you don’t have to retake the course later.
                        </p>
                        <p style={{ marginBottom: '3px' }}>
                            If you’re on the fence, it's a good idea to get your certificate just in case.
                        </p>

                    </div> */}
                            {/* <div style={{ textAlign: "center" }}>
                        <button className="btn_brnd" type="button"
                            onClick={() => setShowModal(true)}
                        >

                            Generate my Certificate

                        </button>
                    </div> */}
                    {
                        loading   ? 

                        <div style={{textAlign:"center"}}>
                       <ThreeDots
                        height="20"
                        width="110"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    </div>
                      
                     

                        :

                        <CollectInfoModal userData={updatedUserData} />

                    }


                        </div>
                    </section>
            }

            <Footer />
           

        </div>
    );
};

export default BeforeContactWithCounselor;
