import React, { useState } from "react";
import img_1 from "../../assets/images/SiteImg/img_1.png";
import img_2 from "../../assets/images/SiteImg/img_2.png";
import { Card, Button } from "react-bootstrap";
import plusIcon from "../../assets/images/SiteImg/plus.png";
import minusIcon from "../../assets/images/SiteImg/white-minus-icon.png";
import Uvuru from "../../assets/images/Uvuru.png";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import Uvuru_Mobile from "../../assets/images/Uvuru-mobile.png";

const About = () => {
  const [isExpanded1, setIsExpanded1] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(true);

  const toggleContent1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };
  return (
    <div>
      <Header />
      <section className="about_us_page mt-250">
        <div className="theme_container">
          <h1 className="f-52 text-center">
            Who is <span>Evergreen?</span>
          </h1>
          <div className="row   mt-40 mt_one align-items-center">
            <div className="col-lg-12">
              <div className="about_content">
                <h5 className="f-26">EVERGREEN'S MISSION</h5>
                <p className="mt-20 f-16">
                  <b>Evergreen Financial Counseling</b> seeks to empower and
                  uplift individuals through quality financial counseling and
                  education.
                </p>
              </div>
            </div>
          </div>
          <div className="row   mt-40  align-items-center row_rev">
            <div className="col-lg-12">
              <div className="about_content">
                <h5 className="f-26">OUR VISION</h5>
                <p className="mt-20 f-16">
                  <b>At times it appears as if everything has a price tag.</b>
                  <br />
                  However, we believe each person is priceless, regardless of
                  how much money they have or owe. By teaching true worth, in
                  addition to providing non-judgemental counseling and financial
                  education, we can support individuals in their journey toward
                  personal growth and financial empowerment.
                </p>
              </div>
            </div>
          </div>
          <div className="row   mt-40  align-items-center">
            <div className="col-lg-12">
              <div className="about_content">
                <h5 className="f-26">U.S. TRUSTEE APPROVED*</h5>
                <p className="mt-20 f-16">
                  Approved by the U.S. Trustees Office in all jurisdictions
                  except for North Carolina and Alabama. Approved to issue
                  certificates evidencing completion of credit counseling and
                  personal financial management courses in compliance with the
                  Bankruptcy Code. Approval does not endorse or assure the
                  quality of a Provider's services.
                </p>
              </div>
            </div>
          </div>
          <div className="row  align-items-center row_rev">
            <div className="col-lg-12">
              <div className="about_content">
                <p className="mt-20 f-16">
                  Evergreen Financial Counseling
                  <br />
                  7137 E. Rancho Vista Drive, Suite B21
                  <br />
                  Scottsdale, AZ 85251
                  <br />
                  800-581-3513*
                  <br />
                  info@evergreenclass.com
                  <br />* HI and AK please email a Counselor
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="global-impact-wrap-about">
        <div className="theme_container">
          <div
            className="heading_Wrap text-center"
            style={{ maxWidth: "930px" }}
          >
            <h1 className="f-52">Making a Global Impact</h1>
            <h1 className="f-52">
              Through <span>Education</span>
            </h1>
            <p className="f-16  mt-36 global-impact-wrap-first-para">
              At Evergreen Financial Counseling, we believe education is the
              foundation of opportunity. That’s why we are proud to donate a
              portion of our proceeds to{" "}
              <a
                style={{ color: "#4e9c4b", fontWeight:"500" }}
                href="http://www.sunriserd.com"
                target="_blank"
              >
                Sunrise Rural Dwellers
              </a>
              , an organization dedicated to empowering communities in Uvuru, a
              community in Uzo Uwani Local Government Area of Enugu State,
              Nigeria. One of its key initiatives is helping children access
              education by covering school fees that many families cannot
              afford.
            </p>
          </div>
          <div className="row mt-36 global-impact-mobile">
            <div className="col-lg-7">
              <p className="f-16 global-impact-paragraph ">
                As part of our mission of empowering people through education,
                Evergreen Financial Counseling is helping send 400 children in
                Uvuru attend school, providing them with opportunities to learn,
                grow, and build brighter futures.
              </p>
              <Card style={{ margin: "20px auto", border: "unset" }}>
                <Card.Body>
                  {/* First Section */}
                  <div className="mb-4">
                    <Card.Title as="h4" className="mb-3">
                      <Button
                        variant={isExpanded1 ? "#4E9C4B " : ""}
                        size="sm"
                        onClick={toggleContent1}
                        style={{
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                          border: "1px solid green",
                          boxShadow: "unset",
                          backgroundColor: isExpanded1 ? "#4E9C4B" : "",
                        }}
                      >
                        {isExpanded1 ? (
                          <img src={minusIcon} alt="minus-icon" />
                        ) : (
                          <img src={plusIcon} alt="plus-icon" />
                        )}
                      </Button>
                      <span
                        style={{
                          fontSize: "22px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          color: "#0c1922",
                        }}
                      >
                        Here’s why this matters:
                      </span>
                    </Card.Title>
                    {isExpanded1 && (
                      <Card.Text>
                        <p className="pl-40">
                          Nigeria has no public education systems. Instead,
                          families have to pay monthly to send their children to
                          school—a cost that is out of reach for many families
                        </p>
                        <p className="pl-40">
                          If a husband dies, his property often passes to his
                          family—usually his parents or male relatives—leaving
                          the widow without resources. Education becomes an
                          impossible dream for many of these children.
                        </p>
                      </Card.Text>
                    )}
                  </div>

                  <div>
                    <Card.Title as="h4" className="mb-3 d-flex">
                      <Button
                        variant={isExpanded2 ? "#4E9C4B" : ""}
                        size="sm"
                        onClick={toggleContent2}
                        style={{
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                          border: "1px solid green",
                          boxShadow: "unset",
                          backgroundColor: isExpanded2 ? "#4E9C4B" : "",
                        }}
                      >
                        {isExpanded2 ? (
                          <img src={minusIcon} alt="minus-icon" />
                        ) : (
                          <img src={plusIcon} alt="plus-icon" />
                        )}
                      </Button>
                      <span
                        style={{
                          fontSize: "22px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          color: "#0c1922",
                        }}
                      >
                        When you choose Evergreen, you help change that.{" "}
                      </span>
                    </Card.Title>
                    {isExpanded2 && (
                      <Card.Text>
                        <p className="pl-40">
                          Evergreen starts by helping you complete your
                          bankruptcy requirements, but we don’t stop there. As
                          part of our commitment to education, we enroll you
                          in 7 Steps to a 720 Credit Score—for free—so you can
                          rebuild your financial future with confidence.
                        </p>
                        <p className="pl-40">
                          And we take it even further, reaching across the globe
                          to help educate children in need. Your decision to
                          work with us is making a lasting impact, not just in
                          your life but in the lives of hundreds of children
                          gaining access to education and opportunity
                        </p>
                      </Card.Text>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-5">
              <img
                src={Uvuru}
                alt="global-impact"
                className="global-impact-desktop-img"
              />
              <img
                src={Uvuru_Mobile}
                alt="global-impact"
                className="global-impact-mobile-img"
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
