import React from "react";
import Header from "../../layouts/header";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { downloadBudget, getBudgetanalysisData } from "../../api/server";
import { Link, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

const BudgetAnalysisIncome = () => {
  const user = useSelector((state) => state.user);
  const [getloading, setGetLoading] = useState(true);
  const [showData, setshowData] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );

  const [updateLoading, setUpdateLoading] = useState(false);

  const handleDownload = async (values) => {
    setUpdateLoading(true);
    try {
      const response = await downloadBudget(
        values.budgetAnalysis.userid,
        values
      );
      if (response) {
        window.open(response.data.documentLink);
        setUpdateLoading(false);
      }
    } catch (error) {
      setUpdateLoading(false);
      toast.error(error.response.data.error);
      console.error("Error updating user data:", error);
    }
  };

  useEffect(() => {
    getBudgetanalysisData(user.user.data.id)
      .then((res) => {
        setGetLoading(false);
        setshowData(res.data);
      })
      .catch((err) => {
        setGetLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="theme_container mt-250">
        <Header />
        <h1 className="card-title mb-4">Budget Analysis</h1>
        <section className="breadcrumb-section d-lg-flex justify-content-between align-items-center">
          <nav
            style={{ "--bs-breadcrumb-divider": " '>';" }}
            aria-label="breadcrumb"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Clients Budget Analysis</a>
              </li>
              <li className="breadcrumb-item">Income</li>
              <li className="breadcrumb-item">Expenses</li>
              <li className="breadcrumb-item " aria-current="page">
                Debts
              </li>
            </ol>
          </nav>
          {!getloading && (
            <button
              className="btn_brnd"
              type="submit"
              disabled={updateLoading}
              onClick={() => {
                handleDownload(showData);
              }}
            >
              {updateLoading ? (
                <ThreeDots
                  height="20"
                  width={"217"}
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    display: "block",
                  }}
                  wrapperClassName="spinner"
                  visible={true}
                />
              ) : (
                "Download Budget Analysis"
              )}
            </button>
          )}
        </section>

        <section className="budget-analysis">
          {getloading ? (
            <div className="d-flex justify-content-center">
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </div>
          ) : (
            <>
              {user && showData && (
                <div className="card main-card">
                  <div className="card-body ">
                    <div className="row align-items-center">
                      <div className="col-lg-9">
                        <h6>Earning more than you spend.</h6>
                        <p>
                          While money can't buy happiness, it can provide you
                          with more options and flexibility. Despite what many
                          believe, your income level is not cast in stone. When
                          looking at your financial health, determining your
                          income needs is just as important as scrutinizing your
                          spending.
                        </p>
                        <p>{showData.Tab2.AnnualDescription}</p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: showData.Tab2.povertyDescription,
                          }}
                        ></p>
                      </div>
                      <div className="col-lg-3 text-lg-end">
                        <button className="grade-btn btn_brnd mb-2">
                          <span>${showData.Tab2.Gross}</span>
                          Your monthly gross income
                        </button>
                        <button className="grade-btn btn_brnd mb-2">
                          <span>-${showData.Tab2.Deductions}</span>
                          Amount withheld
                        </button>
                        <button className="grade-btn btn_brnd mb-2">
                          <span>${showData.Tab2.Netincome}</span>
                          Your monthly net income
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {
                user && showData && (showData.budgetAnalysis.main_work_status && showData.budgetAnalysis.main_work_status !== "disabled") &&
                <div className="card main-card">
                <div className="card-body p-0">
                  <div className="row ">
                    {user &&
                      showData &&
                      (showData.budgetAnalysis.main_work_status ===
                        "full_time" &&
                      showData.budgetAnalysis.sp1_grossincome > 0 ? (
                        <>
                          <div className="col-lg-6 bordr-right">
                            <div className="p-lg-4 p-2">
                              <h6 className="text_danger">
                                {" "}
                                {user.user.data.firstname}{" "}
                                {user.user.data.lastname} Income
                              </h6>
                              <div className="results">
                                It looks like you are working part time and
                                making about{" "}
                                <b>{showData.budgetAnalysis.sp1_grossincome}</b>{" "}
                                each month. If working full-time is a desire of
                                yours, you could possibly double this income as
                                well as provide additional benefits by going
                                that route. Your other option would be to get
                                another part-time job or additional contract
                                jobs.
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="p-lg-4 p-2">
                              <h6 className="text_success">
                                Evergreen Suggestions
                              </h6>
                              <div className="results">
                                Go from working part time, to full time.
                              </div>
                              <div className="results">
                                Or, consider working a second job.{" "}
                                <p>+$main_part_time</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : showData.budgetAnalysis.main_work_status ===
                          "variable" &&
                        showData.budgetAnalysis.sp1_grossincome > 0 ? (
                        <>
                          <div className="results_heading">
                            firstname's Income
                          </div>
                          <div className="results">
                            You are bringing in an average of{" "}
                            <b>{showData.budgetAnalysis.sp1_grossincome}</b>{" "}
                            each month, but sometimes it can be more and
                            sometimes less. It can be hard to plan your monthly
                            spending with an income that is up in the air. Look
                            for additional work options you can jump into when
                            work slows down. This could be a second part time
                            job, or additional contract jobs.
                          </div>
                          <div className="results">
                            <font color="green">
                              <b>Evergreen Suggestions</b>
                            </font>
                          </div>
                          <div className="results">
                            Look for a second part time job.
                          </div>
                          <div className="results">
                            And/or additional work to fill in the gaps.{" "}
                            <p>+$main_variable</p>
                          </div>
                          <div className="results">
                            If your income goes from 'variable' to 'sporatic',
                            consider looking for work elsewhere.
                          </div>
                        </>
                      ) : showData.budgetAnalysis.main_work_status ===
                        "seeking" ? (
                        <>
                          <div className="results_heading">
                            firstname's Income
                          </div>
                          <div className="results">
                            You are currently looking for employment. It's
                            stressful to plan your monthly spending with an
                            income that's up in the air. Pick up work where you
                            can, look for part time options or volunteer until a
                            permanent position becomes available.
                          </div>
                          <div className="results">
                            <font color="green">
                              <b>Evergreen Suggestions</b>
                            </font>
                          </div>
                          <div className="results">
                            Hang in there friend! While you are searching for
                            employment, keep your options open. Work for friends
                            and family, do odd jobs and whatever comes your way.{" "}
                            <p>+$320</p>
                          </div>
                        </>
                      ) : (
                        showData.budgetAnalysis.main_work_status ===
                          "retired" &&
                        showData.budgetAnalysis.sp1_grossincome > 0 && (
                          <div>
                            <div className="results_heading">
                              firstname's Income
                            </div>
                            <div className="results">
                              Your retirement income is{" "}
                              <b>${showData.budgetAnalysis.sp1_grossincome}</b>{" "}
                              each month. Options to increase your income, you
                              could pick up a few hours at a part time job, or
                              use your experience to do contract work or start a
                              small business.
                            </div>
                            <div className="results">
                              <font color="green">
                                <b>Evergreen Suggestions</b>
                              </font>
                            </div>
                            <div className="results">
                              Work at a part time job that you enjoy, but will
                              provide additional income.
                            </div>
                            <div className="results">
                              Or, do contract work or start a small business.{" "}
                              <p>+$750</p>
                            </div>
                          </div>
                        )
                      ))}
                  </div>
                </div>
              </div>
              }
              {
                user && showData && (showData.budgetAnalysis.joint_work_status && showData.budgetAnalysis.joint_work_status !== "disabled") &&
                <div className="card main-card">
                <div className="card-body p-0">
                  <div className="row ">
                    {user &&
                      showData &&
                      ((showData.budgetAnalysis.joint_work_status ===
                        "full_time" ||
                        showData.budgetAnalysis.joint_work_status === "") &&
                      showData.budgetAnalysis.sp2_grossincome > 0 ? (
                        <>
                          <div className="col-lg-6 bordr-right">
                            <div className="p-lg-4 p-2">
                              <h6 class="text_danger">
                                {" "}
                                {user.user.data.firstname}{" "}
                                {user.user.data.lastname}
                                's Income
                              </h6>
                              <div class="results">
                                It looks like you are working full time and
                                making about {showData.Tab2.join_wage}. To
                                increase your income, you could work more hours
                                at your existing job,or pick up additional hours
                                at a second job, or do contract work or start a
                                small business for extra income.
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="p-lg-4 p-2">
                              <div class="results">
                                <font color="green">
                                  <b>Evergreen Suggestions</b>
                                </font>
                              </div>
                              <div class="results">
                                Work an additional 4 hours a week of overtime if
                                it's available.
                              </div>
                              <div class="results">
                                Or, put in an additional 8 hours at a second
                                job. <p>{showData.Tab2.join_wage_overtime}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : showData.budgetAnalysis.joint_work_status ===
                          "part_time" &&
                        showData.budgetAnalysis.sp2_grossincome > 0 ? (
                        <>
                          <div className="col-lg-6 bordr-right">
                            <div className="p-lg-4 p-2">
                              <div class="text_danger">
                                {" "}
                                {user.user.data.firstname}{" "}
                                {user.user.data.lastname}
                                's Income
                              </div>
                              <div class="results">
                                It looks like you are working part time and
                                making about
                                <b>{showData.budgetAnalysis.sp2_grossincome}</b>
                                each month. If working full-time is a desire of
                                yours, you could possibly double this income as
                                well as provide additional benefits by going
                                that route. Your other option would be to get
                                another part-time job or additional contract
                                jobs.
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="p-lg-4 p-2">
                              <div class="results">
                                <font color="green">
                                  <b>Evergreen Suggestions</b>
                                </font>
                              </div>
                              <div class="results">
                                Go from working part time, to full time.
                              </div>
                              <div class="results">
                                Or, consider working a second job.{" "}
                                <p>{showData.Tab2.joint_part_time}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : showData.budgetAnalysis.joint_work_status ===
                          "variable" &&
                        showData.budgetAnalysis.sp2_grossincome > 0 ? (
                        <>
                          <div className="col-lg-6 bordr-right">
                            <div className="p-lg-4 p-2">
                              <div class="text_danger">
                                {" "}
                                {user.user.data.firstname}{" "}
                                {user.user.data.lastname}
                                's Income
                              </div>
                              <div class="results">
                                Your bringing in an average of{" "}
                                <b>
                                  ${showData.budgetAnalysis.sp2_grossincome}
                                </b>{" "}
                                each month, but sometimes it can be more and
                                sometimes less. It can be hard to plan your
                                monthly spending with an income that sometimes
                                up in the air. Look for additional work options
                                that you can jump into when work slows down.
                                This could be a second part time job, or
                                additional contract jobs.
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="p-lg-4 p-2">
                              <div class="results">
                                <font color="green">
                                  <b>Evergreen Suggestions</b>
                                </font>
                              </div>
                              <div class="results">
                                Look for a second part time job.
                              </div>
                              <div class="results">
                                And/or additional work to fill in the gaps.{" "}
                                <p>+${showData.Tab2.joint_variable}</p>
                              </div>
                              <div class="results">
                                If your income goes from 'variable' to
                                'sporatic', consider looking for work elsewhere.
                              </div>
                            </div>
                          </div>
                        </>
                      ) : showData.budgetAnalysis.joint_work_status ===
                        "seeking" ? (
                        <>
                          <div className="col-lg-6 bordr-right">
                            <div className="p-lg-4 p-2">
                              <h6 class="text_danger">
                                {" "}
                                {user.user.data.firstname}{" "}
                                {user.user.data.lastname}
                                's Income
                              </h6>
                              <div class="results">
                                You are currently looking for employment. It's
                                stressful to plan your monthly spending with an
                                income that's up in the air. Pick up work where
                                you can, look for part time options and
                                volunteer until a permanant position becomes
                                available.
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="p-lg-4 p-2">
                              <div class="results">
                                <font color="green">
                                  <b>Evergreen Suggestions</b>
                                </font>
                              </div>
                              <div class="results">
                                Hang in there friend! While you are searching
                                for employment, keep your options open. Work for
                                friends and family, do odd jobs and whatever
                                comes your way. <p>+$320</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        showData.budgetAnalysis.joint_work_status ===
                          "retired" && (
                          <>
                            <div className="col-lg-6 bordr-right">
                              <div className="p-lg-4 p-2">
                                <div class="results_heading">
                                  earner_name's Income
                                </div>
                                <div class="results">
                                  Your retirement income is{" "}
                                  <b>
                                    $ {showData.budgetAnalysis.sp2_grossincome}
                                  </b>{" "}
                                  each month.
                                </div>
                                <div class="results">
                                  Options to increase your income, you could
                                  pick up a few hours at a part time job, or use
                                  your experience to do contract work or start a
                                  small business.{" "}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="p-lg-4 p-2">
                                <div class="results">
                                  <font color="green">
                                    <b>Evergreen Suggestions</b>
                                  </font>
                                </div>
                                <div class="results">
                                  Work at a part time job that you enjoy, but
                                  will provide additional income.{" "}
                                </div>
                                <div class="results">
                                  Or, do contract work or start a small
                                  business. <p>+$750</p>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      ))}
                  </div>
                </div>
              </div>
              }
              
              <div className="card main-card">
                <div className="card-body p-0">
                  <div className="row ">
                    {user && showData && (
                      <>
                        <div className="col-lg-6 bordr-right">
                          <div className="p-lg-4 p-2">
                            <h6 class="text_danger">Your Location</h6>
                            <div class="results">
                              State and county where you live can determine a
                              lot about your income and your expenses. An ideal
                              place to live is one where jobs in your field are
                              readily available and the cost of living is low.
                              Your location helps to define your financial
                              outlook. For example, a household income of
                              $40,000/year in the Mid-West and South may grant
                              more freedom than $80,000/year in Los Angeles.
                              Additionally, individual state laws may allow you
                              to keep more of what you earn or even give you
                              money, as they do in Alaska.
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="p-lg-4 p-2">
                            <div class="results">
                              <font color="green">
                                <b>Evergreen Suggestions</b>
                              </font>
                            </div>
                            <div class="results">
                              Evaluate why you are living in your current
                              location. If your location is adding a financial
                              hardship possibly remedied by relocating for
                              better opportunities elsewhere, decide if it's
                              worth it to move.
                            </div>
                            {showData.budgetAnalysis.joint_work_status ==
                              "retired" ||
                            showData.budgetAnalysis.joint_work_status ==
                              "Retired" ? (
                              <div class="results">
                                Retirement is a great time to go to work for the
                                best employer there is: yourself.
                              </div>
                            ) : (
                              (showData.budgetAnalysis.joint_work_status =
                                "seeking" ||
                                (showData.budgetAnalysis.joint_work_status ==
                                  "Seeking" && (
                                  <div class="results">
                                    If you are looking for employment, starting
                                    a small business can keep some money coming
                                    in while keeping your longterm employment
                                    options open.
                                  </div>
                                )))
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center my-4">
                <Link to={`/budget-analysis?retake=${retake}`}>
                  {" "}
                  <a href="#" className="text_success mt-4 me-3">
                    <u>Previous</u>
                  </a>
                </Link>
                <Link to={`/budget-analysis-expenses?retake=${retake}`}>
                  <button className="btn_brnd-outline mt-4 me-3">Next</button>
                </Link>
                <Link to={`/add-info-multistep?retake=${retake}`}>
                  {" "}
                  <button className="btn_brnd mt-4 me-3">
                    Add More Info to My Budget
                  </button>
                </Link>
              </div>
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default BudgetAnalysisIncome;
