import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/userSlice";
import { changePasswordByAdmin, updateProfile } from "../../api/server";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name is required"),
  middleinitial: Yup.string(),
  lastname: Yup.string().required("Last Name is required"),
  phone: Yup.string().required("Phone Number is required"),
  addr1: Yup.string().required("Address Line 1 is required"),
  email: Yup.string().required("Your Email is required"),
  password: Yup.string().required("Password is required"),
  addr2: Yup.string(),
  city: Yup.string().required("city is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string().required("Zip Code is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Your Email is required"),
  username: Yup.string().required("Username is required"),
});

const EditMyAccount = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [passwordUpdating, setPasswordUpdating] = useState(false)
  const [isInputClicked, setIsInputClicked] = useState(false);

  const handleSubmit = async (data) => {
    const values = JSON.parse(JSON.stringify(data));
    delete values.code;
    setUpdateLoading(true);
    try {
      const response = await updateProfile(user.user.data.id, values);
      if (response) {
        toast.success("Profile updated successfully");
        setUpdateLoading(false);
      }

      dispatch(login(response.data));
    } catch (error) {
      setUpdateLoading(false);
      toast.error(error.response.data.error);
      console.error("Error updating user data:", error);
    }
  };


const changePasswordHandler = async(values) =>{
  if (passwordUpdating ) return;
  if (!values?.password || values?.password?.length<1) return toast.error("Password cannot be empty!") 
  setPasswordUpdating(true)
  try{
    const body ={
      id:user.user.data.id,
      password:values.password,
      isJoint:false
    }
    const response = await changePasswordByAdmin(body)
    if (response.status===200){
      localStorage.setItem("adminCode", values?.password);
      toast.success("Passowrd updated successfully!")
    }
  }
  catch (error){
    toast.error(error.response.data.error)
    setPasswordUpdating(false)
  }

  finally{
    setPasswordUpdating(false)
  }
}

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <Formik
            initialValues={
              {...user.user.data,password: localStorage.getItem("adminCode")}
    }
              // Set the initial values from the fetched user data
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleChange,
              handleBlur,
              errors,
              touched,
              values,
              setValues,
            }) => (
              <Form>
                {/* Main Filer Info */}
                <section className="client-editing">
                  <div className="client-head">
                    <h1 className="f18">Personal Info</h1>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="input_wrap">
                        <label className="label-title">First Name</label>
                        <Field
                          type="text"
                          name="firstname"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="firstname"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input_wrap">
                        <label className="label-title">Middle Initial</label>
                        <Field
                          type="text"
                          name="middleinitial"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="middleinitial"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input_wrap">
                        <label className="label-title">Last Name</label>
                        <Field
                          type="text"
                          name="lastname"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input_wrap">
                        <label className="label-title">Phone Number</label>
                        <Field
                          type="text"
                          name="phone"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Contact Info */}
                  <div className="client-head">
                    <h1 className="f18">Contact Info</h1>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input_wrap">
                        <label className="label-title">Address Line 1</label>
                        <Field
                          type="text"
                          name="addr1"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="addr1"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input_wrap">
                        <label className="label-title">Address Line 2</label>
                        <Field
                          type="text"
                          name="addr2"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="addr2"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input_wrap">
                        <label className="label-title">City</label>
                        <Field
                          type="text"
                          name="city"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input_wrap">
                        <label className="label-title">State</label>
                        <Field
                          type="text"
                          name="state"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input_wrap">
                        <label className="label-title">Zip Code</label>
                        <Field
                          type="text"
                          name="zip"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="zip"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Account Info */}
                  <div className="client-head">
                    <h1 className="f18">Account Info...</h1>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input_wrap">
                        <label className="label-title">Your Email</label>
                        <Field
                          type="text"
                          name="email"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6" style={{display:'flex',gap:'10px'}}>
                      <div className="input_wrap" style={{width:'100%'}}>
                        <label className="label-title">Password</label>
                        <input
                          type={showPass ? "text" : "password"}
                          className="form-control"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          onFocus={() => setIsInputClicked(true)}
                        />
                           {showPass ? (
                          <i
                            className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                            style={{ top: "20px", right: "10px" }}
                            onClick={() => {
                              setShowPass(false);
                            }}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                            style={{ top: "20px", right: "10px" }}
                            onClick={() => {
                              setShowPass(true);
                            }}
                            aria-hidden="true"
                          ></i>
                        )}
                          
                        {errors.password && touched.password ? (
                          <p className="text-danger">{errors.password}</p>
                        ) : null}
                       
                       
                      </div>
                      <button
                            type="button"
                            disabled={!values?.password || !isInputClicked || passwordUpdating } 
                            onClick={()=>changePasswordHandler(values)}
                            style={{alignItems:"center",height:'62px',width:'264px',fontSize:'14px'}}
                            className="btn_brnd">
                               {passwordUpdating ? (
                                  <ThreeDots
                                    height="20"
                                    width="118"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Change Password"
                                )}
                              
                          </button>
                    </div>
                    <div className="col-lg-6">
                      <div className="input_wrap">
                        <label className="label-title">Choose a Username</label>
                        <Field
                          type="text"
                          name="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      className="btn_brnd my-3"
                      type="submit"
                      disabled={updateLoading}
                    >
                      {updateLoading ? (
                        <ThreeDots
                          height="20"
                          width="60"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </section>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditMyAccount;
