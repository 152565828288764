
import Modal from 'react-bootstrap/Modal';

const DisclosureModal = ({ showModal, setShowModal }) => {
    return (
        <>
            <Modal centered size='lg' show={showModal} onHide={() => setShowModal(false)}>
                {/* <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                <h1 className="" style={{ fontSize: '20px', padding: '10px 4px', textAlign:'center' }}>
                Disclosure Policy.
                    </h1>
                    <p style={{padding:'20px',fontSize: '14px'}}>
                        By submitting this request, I understand that my name and contact information will be shared with a third party solely for the purpose of assessing my situation and determining whether a referral to a bankruptcy attorney may be appropriate. Evergreen Financial Corp (kumbo@rendorse) does not endorse or guarantee the quality of an attorney, nor do we ensure a good fit. We also do not provide legal advice or make recommendations about whether bankruptcy is the right choice for you—this is a personal decision based on your individual circumstances.
                    </p>

                </Modal.Body>

            </Modal>





        </>
    );

}
export default DisclosureModal