import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import navbar from "../assets/images/SiteImg/navbar.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { logout } from "../store/userSlice";
// import languageIcon from '../assets/images/languageicon.png'
const countries = [
  { code: "en", countryCode: "us", name: "United States (English)" },
  { code: "es", countryCode: "es", name: "Spain (Spanish)" },
  { code: "fr", countryCode: "fr", name: "France (French)" },
  { code: "de", countryCode: "de", name: "Germany (German)" },
  { code: "it", countryCode: "it", name: "Italy (Italian)" },
  { code: "ja", countryCode: "jp", name: "Japan (Japanese)" },
  { code: "zh-CN", countryCode: "cn", name: "China (Chinese Simplified)" },
  { code: "zh-TW", countryCode: "tw", name: "Taiwan (Chinese Traditional)" },
  { code: "ru", countryCode: "ru", name: "Russia (Russian)" },
  { code: "pt", countryCode: "pt", name: "Portugal (Portuguese)" },
  { code: "ar", countryCode: "sa", name: "Saudi Arabia (Arabic)" },
  { code: "ko", countryCode: "kr", name: "South Korea (Korean)" },
  { code: "hi", countryCode: "in", name: "India (Hindi)" },
  { code: "nl", countryCode: "nl", name: "Netherlands (Dutch)" },
  { code: "sv", countryCode: "se", name: "Sweden (Swedish)" },
  { code: "no", countryCode: "no", name: "Norway (Norwegian)" },
  { code: "da", countryCode: "dk", name: "Denmark (Danish)" },
  { code: "fi", countryCode: "fi", name: "Finland (Finnish)" },
  { code: "pl", countryCode: "pl", name: "Poland (Polish)" },
  { code: "tr", countryCode: "tr", name: "Turkey (Turkish)" },
  { code: "vi", countryCode: "vn", name: "Vietnam (Vietnamese)" },
  { code: "th", countryCode: "th", name: "Thailand (Thai)" },
  { code: "el", countryCode: "gr", name: "Greece (Greek)" },
  { code: "hu", countryCode: "hu", name: "Hungary (Hungarian)" },
  { code: "cs", countryCode: "cz", name: "Czech Republic (Czech)" },
  { code: "ro", countryCode: "ro", name: "Romania (Romanian)" },
  { code: "sk", countryCode: "sk", name: "Slovakia (Slovak)" },
  { code: "bg", countryCode: "bg", name: "Bulgaria (Bulgarian)" },
  { code: "uk", countryCode: "ua", name: "Ukraine (Ukrainian)" },
  { code: "lt", countryCode: "lt", name: "Lithuania (Lithuanian)" },
  { code: "lv", countryCode: "lv", name: "Latvia (Latvian)" },
  { code: "sl", countryCode: "si", name: "Slovenia (Slovenian)" },
  { code: "et", countryCode: "ee", name: "Estonia (Estonian)" },
  { code: "ms", countryCode: "my", name: "Malaysia (Malay)" },
  { code: "tl", countryCode: "ph", name: "Philippines (Filipino)" },
  { code: "sw", countryCode: "tz", name: "Swahili (Kiswahili)" },
];
const Header = ({ handleAttorneyClick = () => { } }) => {
  const [searchParams] = useSearchParams();
  console.log("🚀 ~ Header ~ searchParams:", searchParams)
  const location = useLocation();
  const navigate = useNavigate();
  const codeFromParams = searchParams.get("code");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedLanguagename, setSelectedLanguagename] = useState("United States (English)")
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };



  const [selectedLanguage, setSelectedLanguage] = useState(countries[0].code);
  console.log("🚀 ~ Header ~ selectedLanguage:", selectedLanguage)

  useEffect(() => {


    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: "en" },
        "google_translate_element"
      );
    };

    const addGoogleTranslateScript = () => {
      const addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
      const style = document.createElement("style");
      style.innerHTML = `
        body > .skiptranslate,
        #goog-gt-tt,
        .goog-te-spinner-pos,
        iframe.goog-te-banner-frame,
        .goog-te-balloon-frame {
          display: none !important;
        }
        body {
          top: 0px !important;
        }
      `;
      document.head.appendChild(style);
    };

    const applyStoredLanguage = () => {
      const selectedLang = localStorage.getItem("selectedLanguage");
      if (selectedLang) {
        setSelectedLanguage(selectedLang);
        const name = countries.find((country) => country.code === selectedLang)?.name;
        setSelectedLanguagename(name || "Select Language");
      }
    };
    addGoogleTranslateScript();
    const interval = setInterval(() => {
      applyStoredLanguage();
      clearInterval(interval);
    }, 100);
    return () => clearInterval(interval);
  }, []);


  const changeLanguage = (langCode) => {
    console.log(1);
    const select = document.querySelector(".goog-te-combo");
    console.log(101);
    if (select) {
      console.log(2);
      const timeout = setTimeout(() => {
        if (select) {
          console.log(3);
          select.value = langCode;
          select.dispatchEvent(new Event("change"));
          console.log(4);
          if (select.value && select.value == langCode) {
            console.log(5);
            setSelectedLanguage(langCode);
            const name = countries.find((country) => country.code === langCode)?.name;
            setSelectedLanguagename(name || "Select Language");
            console.log(6);
            setIsHovered(false);
            if (langCode) {
              localStorage.setItem("selectedLanguage", langCode);
            }
          }
        }
        console.log(9);
        clearTimeout(timeout);
      }, 100);

      setTimeout(() => {
        if (!select.value || select.value !== langCode) {
          console.log("Google Translate didn't load in time or couldn't change language.");
          setIsHovered(false);
        }
      }, 150);
    } else {
      console.log("Google Translate dropdown not found.");
    }
  };
  useEffect(() => {
    if (codeFromParams && codeFromParams === "EFC323") {
      setTimeout(() => {
        setSelectedLanguage("es")
        setSelectedLanguagename("Spain (Spanish)")
        localStorage.setItem("selectedLanguage", "es");
        changeLanguage("es")
        setTimeout(() => {
          if (searchParams.has("code")) {
            searchParams.delete("code");
            navigate(`${location.pathname}?${searchParams.toString()}`, {
              replace: true,
            });
          }
        }, 2500)
      }, 2000)

    }
  }, [codeFromParams, location, navigate])





  return (
    <div>
      {isLoggedIn && user ? (
        <>
          <header className="header header_wrap header_wrap_two d-flex">
            <div className="theme_container">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to={"/"}>
                  {" "}
                  <img src={logo} alt="logo" />{" "}
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img src={navbar} alt="navbar" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto justify-content-center mx-auto mb-2 mb-lg-0">
                    <div class="dropdown">
                      <Link
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className=" att-link nav-link"
                      >
                        <li className=" nav-item mr-80">
                          <Link></Link>
                          Attorneys
                        </li>
                      </Link>

                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            class="dropdown-item"
                            to={`/attorneys`}
                            onClick={handleAttorneyClick}
                          >
                            Why Evergreen?
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to={"/setup-attorney-account"}
                          >
                            Set-up Attorney Account
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to={"/locate-certificates"}
                          >
                            Locate Certificates
                          </Link>
                        </li>

                        <li>
                          <Link class="dropdown-item" to={"/login"}>
                            Attorney Dashboard
                          </Link>
                        </li>

                        {/* <li>
                          <Link
                            class="dropdown-item"
                            to={"/request-client-cards"}
                          >
                            Request Client Cards
                          </Link>
                        </li>
                        <li>
                          <Link class="dropdown-item" to={"/report-an-issue"}>
                            Report an Issue
                          </Link>
                        </li> */}
                      </ul>
                    </div>

                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link mr-80" to={`/about`}>
                        About Us
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to={`/faq`}>
                        FAQ
                      </Link>{" "}
                    </li>
                  </ul>

                  <div class="dropdown">
                    <a
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      class=" dropdown-toggle"
                    >
                      {localStorage.getItem("isJoint") === "true"
                        ? user?.user?.data?.joint_firstname === "" &&
                          user?.user?.data?.joint_lastname === ""
                          ? user?.user?.data?.firstname +
                          " " +
                          user?.user?.data?.lastname +
                          "'s Joint"
                          : user?.user?.data?.joint_firstname +
                          " " +
                          user?.user?.data?.joint_lastname +
                          " (Joint)"
                        : user?.user?.data?.firstname +
                        " " +
                        user?.user?.data?.lastname}
                    </a>

                    <ul class="dropdown-menu">
                      {user?.user?.data?.type === "User" && (
                        <>
                          <li>
                            <Link class="dropdown-item" to={`/userprofile`}>
                              Profile
                            </Link>
                          </li>
                          <Link to={"/dashboard"}>
                            <li>
                              <a class="dropdown-item">My Courses</a>
                            </li>
                          </Link>
                          <li>
                            <Link
                              class="dropdown-item"
                              to={"/request-language"}
                            >
                              Language Request
                            </Link>
                          </li>
                        </>
                      )}
                      {user?.user?.data?.type === "Atty" && (
                        <Link to={"/manage-clients"}>
                          <li>
                            <a class="dropdown-item">Dashboard</a>
                          </li>
                        </Link>
                      )}
                      {user?.user?.data?.type === "Admin" && (
                        <Link to={"/admin-dashboard"}>
                          <li>
                            <a class="dropdown-item">Dashboard</a>
                          </li>
                        </Link>
                      )}
                      {/* <li>
                        <a class="dropdown-item" href="#">
                          Accomplishment
                        </a>
                      </li> */}
                      <li>
                        <Link class="dropdown-item" to={"/contactUs"}>
                          Help Center
                        </Link>
                      </li>

                      <li>
                        <span
                          style={{ cursor: "pointer" }}
                          class="dropdown-item"
                          onClick={handleLogout}
                        >
                          Logout
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

              </nav>

            </div>
            <div id="google_translate_element" style={{ display: 'none' }}></div>
            <div className="dropdown" style={{ fontSize: '12px', alignSelf: 'center' }}>
              <button className="dropdown-button" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <span className={`flag-icon flag-icon-${countries.find((country) => country.code === selectedLanguage)?.countryCode || "un"}`} style={{ marginRight: "8px" }}>
                </span>
                {selectedLanguagename}
              </button>
              <div className="dropdown-content" style={{ display: isHovered ? 'block' : 'none' }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {countries.map((country) => (
                  <div key={country.code} onClick={() => changeLanguage(country.code)} className="dropdown-item">
                    <img src={`https://flagcdn.com/w20/${country.countryCode.toLowerCase()}.png`} alt={`${country.name} flag`} style={{ width: "20px", marginRight: "8px" }} />
                    {country.name}
                  </div>
                ))}
              </div>
            </div>
          </header>
        </>
      ) : (
        <div>
          <header className="header header_wrap header_wrap_two d-flex align-items-center">
            <div className="theme_container">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to={"/"}>
                  {" "}
                  <img src={logo} alt="logo" />{" "}
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img src={navbar} alt="navbar" />{" "}
                </button>
                <div
                  className="collapse navbar-collapse "
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto justify-content-center mx-auto mb-2 mb-lg-0 ">
                    <div class="dropdown">
                      <Link
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className=" att-link nav-link"
                      >
                        <li className=" nav-item mr-80">
                          <Link></Link>
                          Attorneys
                        </li>
                      </Link>

                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            class="dropdown-item"
                            to={`/attorneys`}
                            onClick={handleAttorneyClick}
                          >
                            Why Evergreen?
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to={"/setup-attorney-account"}
                          >
                            Set-up Attorney Account
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to={"/locate-certificates"}
                          >
                            Locate Certificates
                          </Link>
                        </li>
                        <li>
                          <Link class="dropdown-item" to={"/login"}>
                            Attorney Dashboard
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link mr-80" to={`/about`}>
                        About Us
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link mr-80 " to={`/faq`}>
                        FAQ
                      </Link>
                    </li>
                  </ul>
                  <form className="d-flex" >
                    <Link to={`/registration`}>
                      <button
                        className="btn btn-outline-light mr-22"
                        type="submit"
                      >
                        Sign Up
                      </button>
                    </Link>
                    <Link to={`/login`}>
                      <button className="btn btn-outline-light" type="submit">
                        Login
                      </button>
                    </Link>
                  </form>
                </div>
              </nav>
            </div>
            <div id="google_translate_element" style={{ display: 'none' }}></div>
            <div className="dropdown" style={{ fontSize: '12px' }}>
              <button className="dropdown-button" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <span className={`flag-icon flag-icon-${countries.find((country) => country.code === selectedLanguage)?.countryCode || "un"}`} style={{ marginRight: "8px" }}></span>
                {selectedLanguagename}
              </button>
              <div className="dropdown-content" style={{ display: isHovered ? 'block' : 'none' }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {countries.map((country) => (
                  <div key={country.code} onClick={() => changeLanguage(country.code)} className="dropdown-item" >
                    <img src={`https://flagcdn.com/w20/${country.countryCode.toLowerCase()}.png`} alt={`${country.name} flag`} style={{ width: "20px", marginRight: "8px" }} />
                    {country.name}
                  </div>
                ))}
              </div>
            </div>
          </header>
        </div>
      )}
      <style jsx>{`
        .dropdown {
          position: relative;
          display: inline-block;
          width: 220px;
          padding-right: 5px;
        }
        .dropdown-button {
       
         background-color: #66bb6a; 
          color: #ffffff;
          border: 1px solid #4caf50;
          // background-color: #f0f0f0;
          padding: 4px 10px;
          cursor: pointer;
          width: 100%;
          // border: 1px solid #ccc;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .dropdown-content {
         background-color: #e8f5e9;
        color: #2e7d32;
        height:80vh;
        overflow:auto;
          display: none;
          position: absolute;
          // background-color: #fff;
          border: 1px solid #ccc;
          width: 100%;
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
          z-index: 1;
        }
        // .dropdown:hover .dropdown-content {
        //   display: block;
        // }
        .dropdown-item {
          padding: 8px 3px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .dropdown-item:hover {
          // background-color: #f0f0f0;
           background-color: #4caf50;
  color: #ffffff;
        }
      `}</style>
    </div>
  );
};

export default Header;
