import React from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { useSelector } from "react-redux";

const ContactWithCounselor = () => {
  const user = useSelector((state) => state.user.user.data);
  return (
    <div>
      <Header />
      <section className=" mt-250">
        <div className="theme_container">
          <div className="media_object">
            <div className="congrationlaton_Wrap">
              <div className="congrationlaton-img"></div>
              <h1 className="f-26 text_success f-700 after_text">
                COURSE CONCLUSION
              </h1>
              <p className="f-16 mt-60">
                {/* Your class is not complete until you follow up with a counselor
                by call at <b>1 800 581-3513</b> or text at <b>602-932-7342</b>. */}
                 Your course is not complete until you follow up with a Credit Counselor to discuss your financial strategy and receive your certificate. Call us at <b>800-581-3513</b> or text us at <b>602-932-7342</b>.
              </p>
              <p className="f-16">
                <b>IMPORTANT!</b> Before you can receive your certificate,
                you'll need to discuss any additional questions or concerns
                about your financial strategy with a certified counselor.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactWithCounselor;
