import React, { useState } from "react";
import "./signupForm.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SignupForm = ({ setView }) => {
  // const [isChecked, setIsChecked] = useState(false);

  // Validation Schema
  const googleFormSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    terms: yup
      .bool()
      .oneOf([true], 'You need to accept the terms & conditions'),

  });

  const handleSubmit = async (values, { setSubmitting }) => {
    // if (!isChecked) {
    //   alert("Please agree to the terms.");
    //   setSubmitting(false);
    //   return;
    // }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}user/new-register`,
        values
      );

      if (res.status === 201) {
        setView(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.error("Error submitting form:", error?.response?.data?.error);
      // alert("An error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="signup-form">
      <h2 className="signup-form__title">
        SIGN UP FOR OUR FINANCIAL LITERACY COURSE NOW!
      </h2>
      <p className="signup-form__description">
        This free course is designed to help you explore your options and take
        the first step toward regaining control of your financial future.
      </p>
      <Formik
        initialValues={{ email: "", terms: false }}
        validationSchema={googleFormSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <label className="signup-form__label" htmlFor="email" style={{ float: 'left' }}>
              Email Address *
            </label>
            <Field
              type="email"
              name="email"
              id="email"
              className="signup-form__input"
              placeholder="Email"
            />
            <ErrorMessage
              name="email"
              component="p"
              className="errormessage"
            />
            <div className="signup-form__checkbox-group">
              <Field
                type="checkbox"
                id="terms"
                name="terms"
                className="signup-form__checkbox"
              // checked={isChecked}
              // onChange={() => setIsChecked(!isChecked)}
              />

              <label htmlFor="terms" className="signup-form__checkbox-label">
                I agree to the{" "}
                <Link to="/terms-and-condition" target="_blank" className="signup-form__link">
                  Terms & Conditions
                </Link>
                . My data will be handled according to our{" "}
                <Link to="/privacy-policy" target="_blank" className="signup-form__link">
                  Privacy Policy
                </Link>
                .
              </label>

            </div>
            <ErrorMessage
              name="terms"
              component="p"
              className="errormessage"
            />
            <button
              type="submit"
              className="signup-form__button"
              disabled={isSubmitting}
            // style={{ color: isSubmitting && "white", backgroundColor: 'green' }}
            >
              {isSubmitting ? "Submitting..." : "Sign Up"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupForm;
